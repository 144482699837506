<div class="pad-L" style="height: 100%; width: 435px; overflow: hidden;">
	<div class="disp-f j-c-s a-i-c">
		<div>
			<div class="disp-f a-i-c j-c-c col bkg-light-grey-2 radius-10"
				 style="height: 45px; width: 45px;">
				<img alt="" src="assets/icons/data.svg" class="svg-24 filter-grey">
			</div>
		</div>
		<div class="ml-s flex-column gap-XS">
			<div class="font-L fw-700">{{ 'page.data.addNewData' | translate }}</div>
			<div class="font-M fw-300">{{ 'page.data.addNewDataSubtitle' | translate }}</div>
		</div>
		<app-icon-button (click)="closeDialog(false)" class="ml-auto" [img]="'assets/icons/close.svg'" type="stroked"
						 background [panelClass]="'img-size-30 darken-icon'"></app-icon-button>
	</div>

	<form [formGroup]="formGroup">
		<div class="flex-column gap-XS mt-l">
			<div class="font-M fw-500 grey">{{ 'page.data.name' | translate }}*</div>
			<app-text-input [input]="nameFormControl"
							[placeholder]="'page.data.namePlaceholder' | translate"></app-text-input>
		</div>
		<div class="flex-column gap-XS mt-l">
			<div class="font-M fw-500 grey">{{ 'page.data.category' | translate }}</div>
			<app-multi-select class="w-100" [inputControl]="categoryFormControl"
							  [disabled]="_saving">
				<app-select-search [searchControl]="categorySearchFormControl"></app-select-search>
				<app-select-insert [titlePath]="'global.adds.category'" (create)="createCategory($event)"></app-select-insert>
				<app-select-trigger appearance="field">
					{{ categoryFormControl.value?.name || '-' }}
				</app-select-trigger>
				<app-select-option *ngFor="let category of filteredCategoryList" [value]="category">
					{{ category.name }}
				</app-select-option>
			</app-multi-select>
		</div>
		<div class="flex gap-M w-100 mt-l">
			<div class="flex-column gap-XS w-100">
				<div class="font-M fw-500 grey">{{ 'page.data.criticality' | translate }}</div>
				<app-multi-select class="w-100" [inputControl]="criticalityFormControl"
								  [disabled]="_saving">
					<app-select-trigger appearance="field">
						{{ criticalityFormControl.value ? ('page.data.criticalityValues.' + criticalityFormControl.value.toLowerCase() | translate) : '-' }}
					</app-select-trigger>
					<app-select-option *ngFor="let criticality of criticalityList" [value]="criticality">
						{{ 'page.data.criticalityValues.' + criticality.toLowerCase() | translate }}
					</app-select-option>
				</app-multi-select>
			</div>
			<div class="flex-column gap-XS w-100">
				<div class="font-M fw-500 grey">{{ 'page.data.privacy' | translate }}</div>
				<app-multi-select class="w-100" [inputControl]="privacyFormControl"
								  [disabled]="_saving">
					<app-select-trigger appearance="field">
						{{ privacyFormControl.value ? ('page.data.privacyValues.' + privacyFormControl.value | translate) : '-' }}
					</app-select-trigger>
					<app-select-option *ngFor="let privacy of dataPrivacyList" [value]="privacy">
						{{ 'page.data.privacyValues.' + privacy | translate }}
					</app-select-option>
				</app-multi-select>
			</div>
		</div>

		<div class="flex-column gap-XS mt-l">
			<div class="font-M fw-500 grey">{{ 'page.data.description' | translate }}</div>
			<app-text-area [placeholder]="'page.data.descriptionPlaceholder' | translate" [rows]="5"
						   [input]="descriptionFormControl"></app-text-area>
		</div>
		<div class="flex gap-M mt-l">
			<app-text-button type="stroked" class="w-50" [disabled]="_saving"
							 (click)="closeDialog(false)">
				{{ 'page.appDetails.finance.contracts.cancel'|translate }}
			</app-text-button>
			<app-text-button type="flat" class="w-50" [disabled]="formGroup.invalid" [loading]="_saving"
							 (click)="submit()">
				{{ 'page.appDetails.files.dialog.add' | translate }}
			</app-text-button>
		</div>
	</form>
</div>
