import {Component, EventEmitter, inject, OnDestroy, OnInit} from '@angular/core';
import {Data, DataForm, DataService} from "../../../../../services/back/data.service";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {finalize, mergeMap, Observable, of, Subscription, takeWhile} from "rxjs";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {DataDetailData, DataDetailService} from "../../../../../services/front/data-detail.service";
import {SnackbarService} from "../../../../../services/front/snackbar.service";
import {filter, first, map, tap} from "rxjs/operators";
import {DesignSystemModule} from "../../../../design-system/design-system.module";

@Component({
  selector: 'app-data-settings',
	imports: [
		DesignSystemModule,
		ReactiveFormsModule,
		TranslateModule
	],
  templateUrl: './data-settings.component.html',
  styleUrl: './data-settings.component.scss'
})
export class DataSettingsComponent implements OnInit, OnDestroy{
	tenantId: string;
	isEditor: boolean;
	data: Data;

	_initializing: boolean;
	_loading: boolean;
	initialized = false;

	formGroup: FormGroup;

	updateOnBlur = new EventEmitter<Form>();

	subscription: Subscription = new Subscription();

	private translate = inject(TranslateService);
	private dataService = inject(DataService);
	private dataDetailService = inject(DataDetailService);
	private snackbarService = inject(SnackbarService);

	ngOnInit() {
		this.buildForm();
		this.subscription.add(this.dataDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.dataDetailService.getDataDetailDataChanges()
			.pipe(tap(data => this.setDataDetailData(data)), filter(() => !this.initialized))
			.subscribe(() => this.initialize()));
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				tap(() => this.setDefaultData()),
				tap(() => this.fixMarkAsTouched()),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.initialized = true));
	}

	buildForm(): void {
		this.formGroup = new FormGroup({
			[Form.name]: new FormControl(undefined, [Validators.required])
		});

		this.formGroup.disable();

		this.subscription.add(this.updateOnBlur
			.pipe(filter(() => !this.isFormValid()))
			.subscribe(() => this.setDataFormDefaultData(this.data)));
		this.updateOnBlur
			.pipe(
				filter(formName => this.isFormValid() && this.checkFormChanged(formName)),
				map(() => this.buildDataUpdateForm()),
				mergeMap(form => this.dataService.update(this.tenantId, this.data.id, form)),
				tap(updateSucceed => this.displayUpdatedSucceed(updateSucceed)),
				tap(() => this.dataDetailService.refreshData()),
				takeWhile(() => !this.subscription.closed))
			.subscribe();
	}

	private fixMarkAsTouched(): void {
		Object.values(Form)
			.map(name => this.formGroup.get(name)!)
			.filter(control => !!control.validator)
			.forEach(control => control.valueChanges.pipe(first()).subscribe(() => control.markAsTouched()));
	}

	private setDataDetailData(data: DataDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.data = data.data;
	}

	private setDefaultData(): void {
		this.setDataFormDefaultData(this.data);
		this.checkFormDisabled();
	}

	private setDataFormDefaultData(data: Data): void {
		this.formGroup.get(Form.name)!.setValue(data.name);
	}

	checkFormDisabled(): void {
		if (!this.isEditor) {
			this.formGroup.disable();
		} else {
			this.formGroup.enable();
		}
	}

	isFormValid(): boolean {
		return this.formGroup.dirty && this.formGroup.valid && this.formGroup.enabled;
	}

	checkFormChanged(formName?: Form): boolean {
		return !formName
			? Object.values(Form).map(formName => this.checkForm(formName)).reduce((a, b) => a || b)
			: this.checkForm(formName);
	}

	private checkForm(formName: Form): boolean {
		switch (formName) {
			case Form.name:
				return this.formGroup.get(Form.name)!.value !== this.data.name;
		}
	}

	private buildDataUpdateForm(): DataForm {
		return {
			name: this.formGroup.get(Form.name)!.value,
			types: this.data.types,
			privacy: this.data.privacy || undefined,
			criticality: this.data.criticality || undefined,
			category: this.data.category || undefined,
			description: this.data.description || undefined
		};
	}

	displayUpdatedSucceed(updateSucceed: boolean): void {
		if (updateSucceed) {
			this.snackbarService.show(this.translate.instant('page.application.detail.update.success'));
		} else {
			this.snackbarService.show(this.translate.instant('page.application.detail.update.fail'), 'danger-snack');
		}
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	get nameFormControl(): FormControl {
		return this.formGroup.get(Form.name) as FormControl;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	protected readonly Form = Form;
}

enum Form {
	name = 'name',
}
