import {CriticalityLevel} from "../tenant.service";
import {ApplicationGeneric} from "./new-application.model";

export enum DataCollectionStatus {
	ACTIVE = "ACTIVE",
	OUTDATED = "OUTDATED",
	ERROR = "ERROR",
}

export interface Ethernet {
	ips: IpAddress[];
	name: string;
}

export interface IpAddress {
	value: string;
	type: string;
}

export interface ServerAgent {
	id: string,
	name: string | null,
	type: string,
	lastCheck: string | null,
	version: string,
	installDate: string,
	osType: string
}

export interface InfrastructureOverview {
	metrics: Metric;
	averageMetrics: MetricAverage;
}

export interface MetricAverage {
	load: number | null;
	storage: number | null;
}

export interface Metric {
	loadList: LoadMetric[];
	storageList: StorageMetric[];
}

export interface LoadMetric {
	timestamp: string,
	load: number | null
}

export interface StorageMetric {
	timestamp: string,
	disks: DiskMetric[]
}

export interface DiskMetric {
	name: string,
	load: number,
}

export interface ServerForm {
	name: string;
	os: string;
	ipAddress?: string;
	manufacturer?: string;
}


export interface ServerTableData {
	server: Server,
	criticality: CriticalityLevel | null,
	load: number | null,
	storage: number | null,
}

export interface Server {
	id: string,
	name: string,
	automatic: boolean,
	system: string,
	os: string,
	model: string | null,
	serialNumber: string | null,
	uptime: number | null,
	manufacturer: string | null,
	ipAddress: string | null,
	ethernetInterfaces: Ethernet[];
	type: string | null,
	location: string | null,
	dataCollectionStatus: DataCollectionStatus | null,
	lastCheckTime: string | null,
}

export enum ServerType {
	DATABASE = 'database',
	PRINT = 'print',
	DIRECTORY = 'directory',
	PROXY = 'proxy',
	SECURITY = 'security',
	APPLICATION = 'application',
	WEB = 'web',
	MONITORING = 'monitoring',
	MAIL = 'mail',
	BACKUP = 'backup',
	CONFIGURATION = 'configuration',
	FILE = 'file'
}

export enum ServerOS {
	WINDOWS = 'windows',
	SUSE = 'suse',
	REDHAT = 'redhat',
	UBUNTU = 'ubuntu',
	DEBIAN = 'debian',
	CENTOS = 'centos',
	FEDORA = 'fedora',
	IBMI = 'ibmi',
	OTHER = 'other'
}

export enum NetworkRole {
	LOCAL = 'local',
	WIRELESS = 'wireless',
	STORAGE = 'storage',
	VPN = 'vpn',
	DATA_CENTER = 'data_center',
	EDGE = 'edge',
	PUBLIC = 'public',
	MANAGEMENT = 'management',
	DMZ = 'dmz',
	IOT = 'iot',
	INTERCOMMUNICATION = 'intercommunication',
}

export enum SubnetMask {
	SUB_1 = '/1',
	SUB_2 = '/2',
	SUB_3 = '/3',
	SUB_4 = '/4',
	SUB_5 = '/5',
	SUB_6 = '/6',
	SUB_7 = '/7',
	SUB_8 = '/8',
	SUB_9 = '/9',
	SUB_10 = '/10',
	SUB_11 = '/11',
	SUB_12 = '/12',
	SUB_13 = '/13',
	SUB_14 = '/14',
	SUB_15 = '/15',
	SUB_16 = '/16',
	SUB_17 = '/17',
	SUB_18 = '/18',
	SUB_19 = '/19',
	SUB_20 = '/20',
	SUB_21 = '/21',
	SUB_22 = '/22',
	SUB_23 = '/23',
	SUB_24 = '/24',
	SUB_25 = '/25',
	SUB_26 = '/26',
	SUB_27 = '/27',
	SUB_28 = '/28',
	SUB_29 = '/29',
	SUB_30 = '/30'
}

export interface ServerInformationForm {
	name: string,
	ipAddress?: string,
	location?: string,
	type?: ServerType,
	system?: ServerOS,
	manufacturer?: string
}

export interface SchemaServer {
	server: Server,
	current: boolean,
	criticality: CriticalityLevel | null,
}

export interface ApplicationServerListForm {
	servers: string[];
}
