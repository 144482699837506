import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {finalize, forkJoin, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApplicationGeneric} from 'src/app/services/model/new-application.model';
import {NewApplicationService} from 'src/app/services/back/new-application.service';
import {PORTS_RANGE_REGEXP} from "../../../../../../utils/forms.utils";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {DesignSystemModule} from "../../../../../design-system/design-system.module";
import {CommonModule} from "@angular/common";
import {
	ExchangeFrequencyType,
	Flow, FlowForm,
	FlowService,
	FormatType,
	ProtocolType
} from "../../../../../../services/back/flow.service";
import {Data, DataForm, DataService} from "../../../../../../services/back/data.service";

@Component({
	selector: 'app-application-flow-form',
	templateUrl: './application-flow-form.component.html',
	imports: [
		TranslateModule,
		DesignSystemModule,
		ReactiveFormsModule,
		CommonModule
	],
	styleUrls: ['./application-flow-form.component.scss']
})
export class ApplicationFlowFormComponent implements OnInit, OnDestroy {

	@Input() data: ApplicationFlowFormData;

	@Output() cancel: EventEmitter<void> = new EventEmitter<void>();
	@Output() saved: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() hasApplication: EventEmitter<boolean> = new EventEmitter<boolean>();

	_initializing: boolean = false;
	_saving: boolean = false;
	_savingData: boolean = false;
	_uploading: boolean;

	formGroup: FormGroup;
	isSource: boolean;

	tabIndex: 0|1|2 = 0;

	applicationList: ApplicationGeneric[] = [];
	dataList: Data[] = [];
	dataFilteredList: Data[] = [];
	protocolTypeChoices: ProtocolTypeChoice[] = [];
	formatTypeChoices: FormatTypeChoice[] = [];
	exchangeFrequencyTypeChoices: ExchangeFrequencyTypeChoice[] = [];
	flowEncryptionTypeChoices: FlowEncryptionTypeChoice[] = [];
	portTypeChoices: PortTypeChoice[] = [];
	searchSourceControl: FormControl;
	sourceFilteredList: ApplicationGeneric[] = [];
	searchTargetControl: FormControl;
	targetFilteredList: ApplicationGeneric[] = [];
	searchDataControl: FormControl;

	searchProtocolControl: FormControl;
	protocolFilteredList: ProtocolTypeChoice[] = [];
	searchFormatControl: FormControl;
	formatFilteredList: FormatTypeChoice[] = [];

	iconReversed: boolean|undefined;
	formName: typeof Form = Form;

	subscription: Subscription = new Subscription();

	constructor(private flowService: FlowService,
							private dataService: DataService,
							private translate: TranslateService,
							private applicationService: NewApplicationService) {
	}

	ngOnInit(): void {
		this.createForm();
		this.buildSelectionLists();
		this.subscription.add(this.switchInitializing()
			.pipe(
				switchMap(() => !this.data.preloaded
					? forkJoin([
						this.applicationService.getAllApplication(this.data.tenantId),
						this.dataService.getAllTenantData(this.data.tenantId)])
						.pipe(map(([applicationList, dataList]) => ({applicationList, dataList})))
					: of(this.data.preloaded)),
				tap((formData) => this.hasApplication.emit(formData.applicationList.length > 1)),
				tap((formData) => this.setFlowFormData(formData.applicationList, formData.dataList)),
				finalize(() => this.switchInitializing()))
			.subscribe(() => this.setDefaultData()));
	}

	private createForm(): void {
		this.formGroup = new FormGroup({
			[Form.source]: new FormControl(undefined, [Validators.required]),
			[Form.target]: new FormControl(undefined, [Validators.required]),
			[Form.dataList]: new FormControl([], []),
			[Form.comment]: new FormControl(undefined, [Validators.maxLength(200)]),
			[Form.protocol]: new FormControl(undefined, []),
			[Form.format]: new FormControl(undefined, []),
			[Form.exchange_frequency]: new FormControl(undefined, []),
			[Form.port_type]: new FormControl(undefined, []),
			[Form.port]: new FormControl(undefined, [Validators.pattern(PORTS_RANGE_REGEXP)]),
			[Form.flow_encryption]: new FormControl(undefined, []),
			[Form.fileLinkIds]: new FormControl([], []),
		});
		this.searchSourceControl = new FormControl('');
		this.subscription.add(this.searchSourceControl.valueChanges
			.subscribe(text => this.filterSourceApplicationList(text)));
		this.searchTargetControl = new FormControl('');
		this.subscription.add(this.searchTargetControl.valueChanges
			.subscribe(text => this.filterTargetApplicationList(text)));
		this.searchDataControl = new FormControl('');
		this.subscription.add(this.searchDataControl.valueChanges
			.subscribe(text => this.filterDataList(text)));
		this.searchProtocolControl = new FormControl('');
		this.searchFormatControl = new FormControl('');
		this.subscription.add(this.searchProtocolControl.valueChanges
			.subscribe(text => this.filterProtocolList(text)));
		this.subscription.add(this.searchFormatControl.valueChanges
			.subscribe(text => this.filterFormatList(text)));
	}

	private buildSelectionLists(): void {
		this.protocolTypeChoices = [
			{id: ProtocolType.HTTP, name: 'HTTP'},
			{id: ProtocolType.FTP, name: 'FTP'},
			{id: ProtocolType.SFTP, name: 'SFTP'},
			{id: ProtocolType.MQTT, name: 'MQTT'},
			{id: ProtocolType.AMQP, name: 'AMQP'},
			{id: ProtocolType.gRPC, name: 'gRPC'},
			{id: ProtocolType.SMB, name: 'SMB'},
			{id: ProtocolType.NFS, name: 'NFS'},
			{id: ProtocolType.LDAP, name: 'LDAP'},
			{id: ProtocolType.CIFS, name: 'CIFS'},
			{id: ProtocolType.RTSP, name: 'RTSP'},
			{id: ProtocolType.DICOM, name: 'DICOM'},
			{id: ProtocolType.HL7, name: 'HL7'},
			{id: ProtocolType.FHIR, name: 'FHIR'},
			{id: ProtocolType.XDS, name: 'XDS'},
			{id: ProtocolType.IHE, name: 'IHE'},
			{id: ProtocolType.CDA, name: 'CDA'},
			{id: ProtocolType.NCPDP, name: 'NCPDP'},
			{id: ProtocolType.CCDA, name: 'CCDA'},
			{id: ProtocolType.AS2, name: 'AS2'},
			{id: ProtocolType.EDI, name: 'EDI'},
			{id: ProtocolType.SEPA, name: 'SEPA'},
			{id: ProtocolType.EBICS, name: 'EBICS'},
		];
		this.protocolFilteredList = this.protocolTypeChoices;

		this.formatTypeChoices = [
			{id: FormatType.JSON, name: 'JSON'},
			{id: FormatType.CSV, name: 'CSV'},
			{id: FormatType.XML, name: 'XML'},
			{id: FormatType.YAML, name: 'YAML'},
			{id: FormatType.PLAIN_TEXT, name: 'Plain text'},
			{id: FormatType.HTML, name: 'HTML'},
			{id: FormatType.BINARY, name: this.translate.instant('page.appDetails.architecture.dialog.binary')},
		];
		this.formatFilteredList = this.formatTypeChoices;

		this.exchangeFrequencyTypeChoices = [
			{id: ExchangeFrequencyType.REAL_TIME, name: this.translate.instant('page.appDetails.architecture.dialog.realTime')},
			{id: ExchangeFrequencyType.DAILY, name: this.translate.instant('page.appDetails.architecture.dialog.daily')},
			{id: ExchangeFrequencyType.WEEKLY, name: this.translate.instant('page.appDetails.architecture.dialog.weekly')},
			{id: ExchangeFrequencyType.MONTHLY, name: this.translate.instant('page.appDetails.architecture.dialog.monthly')},
		];

		this.portTypeChoices = [
			{id: 'tcp', name: 'TCP'},
			{id: 'udp', name: 'UDP'},
		];

		this.flowEncryptionTypeChoices = [
			{id: true, name: this.translate.instant('page.appDetails.architecture.dialog.yes')},
			{id: false, name: this.translate.instant('page.appDetails.architecture.dialog.no')},
		];
	}

	private setFlowFormData(applicationList: ApplicationGeneric[], dataList: Data[]): void {
		this.isSource = !this.data.defaultData || this.data.defaultData.source.id === this.data.applicationId;
		this.applicationList = applicationList;
		this.dataList = dataList;
	}

	private setDefaultData(): void {
		if (!!this.data.defaultData) {
			const source: ApplicationGeneric|undefined = this.applicationList.find(a => a.id === this.data.defaultData!.source.id);
			this.formGroup.get(Form.source)!.setValue(source);
			const target: ApplicationGeneric|undefined = this.applicationList.find(a => a.id === this.data.defaultData!.target.id);
			this.formGroup.get(Form.target)!.setValue(target);
			const dataList: Data[] = this.dataList.filter(m => this.data.defaultData!.dataList.map(d => d.id).includes(m.id));
			this.formGroup.get(Form.dataList)!.setValue(dataList);
			this.formGroup.get(Form.comment)!.setValue(this.data.defaultData.comment);
			this.formGroup.get(Form.protocol)!.setValue(this.protocolTypeChoices.find(p => p.id === this.data.defaultData?.protocol));
			this.formGroup.get(Form.format)!.setValue(this.formatTypeChoices.find(f => f.id === this.data.defaultData?.format));
			this.formGroup.get(Form.exchange_frequency)!.setValue(this.exchangeFrequencyTypeChoices.find(e => e.id === this.data.defaultData?.exchangeFrequency));
			this.formGroup.get(Form.port_type)!.setValue(this.portTypeChoices.find(p => p.id === this.data.defaultData?.portType));
			this.formGroup.get(Form.port)!.setValue(this.data.defaultData.port);
			this.formGroup.get(Form.flow_encryption)!.setValue(this.flowEncryptionTypeChoices.find(f => f.id === this.data.defaultData?.flowEncryption));
			this.formGroup.get(Form.fileLinkIds)!.setValue(this.data.defaultData.documents.map(f => f.fileLinkId));
		} else {
			this.formGroup.get(Form.source)!.setValue(this.applicationList.find(a => a.id === this.data.applicationId));
		}
		this.initializeFilteredList();
	}

	toggleDirection(): void {
		this.isSource = !this.isSource;
		this.iconReversed = !this.iconReversed;
		const source: ApplicationGeneric|undefined = this.sourceFormValue;
		const target: ApplicationGeneric|undefined = this.targetFormValue;
		this.formGroup.get(Form.source)!.setValue(target);
		this.formGroup.get(Form.target)!.setValue(source);
		this.initializeFilteredList();
	}

	private initializeFilteredList(): void {
		this.filterSourceApplicationList();
		this.filterTargetApplicationList();
		this.filterDataList()
	}

 	private filterSourceApplicationList(filter?: string): void {
		this.sourceFilteredList = this.getFilteredApplicationList(true, filter);
	}

	private filterTargetApplicationList(filter?: string): void {
		this.targetFilteredList = this.getFilteredApplicationList(false, filter);
	}

	private getFilteredApplicationList(filterSource: boolean, filter?: string): ApplicationGeneric[] {
		return this.applicationList
			.filter(app => (((filterSource && this.isSource) || (!filterSource && !this.isSource))
					&& app.id === this.data.applicationId)
				|| (((filterSource && !this.isSource) || (!filterSource && this.isSource))
					&& app.id !== this.data.applicationId
					&& (!filter || app.name.toLowerCase().includes(filter.toLowerCase()))))
			.sort((a, b) => a.name.localeCompare(b.name))
	}

	private filterDataList(filter?: string): void {
		this.dataFilteredList = this.dataList
			.filter(data => !filter || data.name.toLowerCase().includes(filter.toLowerCase()))
			.sort((a, b) => a.name.localeCompare(b.name));
	}

	private filterProtocolList(filter?: string): void {
		this.protocolFilteredList = this.protocolTypeChoices
			.filter(p => !filter || p.name.toLowerCase().includes(filter.toLowerCase()));
	}

	private filterFormatList(filter?: string): void {
		this.formatFilteredList = this.formatTypeChoices
			.filter(f => !filter || f.name.toLowerCase().includes(filter.toLowerCase()));
	}

	createData(newDataFlow: string): void {
		const form: DataForm = {
			name: newDataFlow,
			types: []
		};
		this.switchSavingData()
			.pipe(
				switchMap(() => this.dataService.create(this.data.tenantId, form)),
				finalize(() => this.switchSavingData()))
			.subscribe(dataId => {
				const newData: Data = {
					id: dataId,
					name: newDataFlow,
					category: null,
					updatedAt: null,
					types: [],
					privacy: null,
					criticality: null,
					description: null
				};
				this.dataList.push(newData);
				const values: Data[] = [...this.formGroup.get(Form.dataList)!.value, newData];
				this.formGroup.get(Form.dataList)!.setValue(values);
				this.searchDataControl.reset('');
			});
	}

	save(): void {
		this.switchSaving()
			.pipe(
				map(() => this.buildFlowForm()),
				switchMap(form => !this.data.defaultData
					? this.flowService.createFlow(this.data.tenantId, form).pipe(map(() => true))
					: this.flowService.updateFlow(this.data.tenantId, this.data.defaultData.id, form)),
				finalize(() => this.switchSaving()))
			.subscribe(updated => this.saved.emit(updated));
	}

	private buildFlowForm(): FlowForm {
		return {
			sourceId: this.sourceFormValue!.id,
			targetId: this.targetFormValue!.id,
			dataList: this.dataFormValue.map(d => d.id),
			comment: this.formGroup.get(Form.comment)!.value,
			protocol: (this.protocolFormControl.value as ProtocolTypeChoice)?.id,
			format: (this.formatFormControl.value as FormatTypeChoice)?.id,
			exchangeFrequency: (this.exchangeFrequencyFormControl.value as ExchangeFrequencyTypeChoice)?.id,
			portType: (this.portTypeFormControl.value as PortTypeChoice)?.id,
			port: this.portFormControl.value,
			flowEncryption: (this.flowEncryptionFormControl.value as FlowEncryptionTypeChoice)?.id,
			documents: this.formGroup.get(Form.fileLinkIds)!.value,
		}
	}

	get sourceFormControl(): FormControl {
		return this.formGroup.get(Form.source)! as FormControl;
	}

	get targetFormControl(): FormControl {
		return this.formGroup.get(Form.target)! as FormControl;
	}

	get dataFormControl(): FormControl {
		return this.formGroup.get(Form.dataList)! as FormControl;
	}

	get sourceFormValue(): ApplicationGeneric|undefined {
		return this.formGroup.get(Form.source)!.value;
	}

	get targetFormValue(): ApplicationGeneric|undefined {
		return this.formGroup.get(Form.target)!.value;
	}

	get dataFormValue(): Data[] {
		return this.formGroup.get(Form.dataList)!.value;
	}

	get protocolFormControl(): FormControl {
		return this.formGroup.get(Form.protocol)! as FormControl;
	}

	get formatFormControl(): FormControl {
		return this.formGroup.get(Form.format)! as FormControl;
	}

	get exchangeFrequencyFormControl(): FormControl {
		return this.formGroup.get(Form.exchange_frequency)! as FormControl;
	}

	get portTypeFormControl(): FormControl {
		return this.formGroup.get(Form.port_type)! as FormControl;
	}

	get portFormControl(): FormControl {
		return this.formGroup.get(Form.port)! as FormControl;
	}

	get flowEncryptionFormControl(): FormControl {
		return this.formGroup.get(Form.flow_encryption)! as FormControl;
	}

	get dataSelectorName(): string {
		return this.dataFormValue.length > 0
			? (this.dataFormValue[0]!.name
				+ (this.dataFormValue.length == 1 ? '' : ' (+' + (this.dataFormValue.length - 1) + ')'))
			: '-';
	}

	private switchInitializing(): Observable<{}> {
		this._initializing = !this._initializing;
		return of({});
	}

	private switchSaving(): Observable<{}> {
		this._saving = !this._saving;
		return of({});
	}

	private switchSavingData(): Observable<{}> {
		this._savingData = !this._savingData;
		return of({});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}

export interface ApplicationFlowFormData {
	tenantId: string;
	applicationId: string;
	defaultData?: Flow;
	preloaded?: {
		applicationList: ApplicationGeneric[];
		dataList: Data[];
	}
}

enum Form {
	source = 'source',
	target = 'target',
	dataList = 'dataList',
	comment = 'comment',
	protocol = 'protocol',
	format = 'format',
	exchange_frequency = 'exchange_frequency',
	port_type = 'port_type',
	port = 'port',
	flow_encryption = 'flow_encryption',
	fileLinkIds = 'fileLinkIds',
}

interface ProtocolTypeChoice {
	id: ProtocolType;
	name: string;
}

interface FormatTypeChoice {
	id: FormatType;
	name: string;
}

interface ExchangeFrequencyTypeChoice {
	id: ExchangeFrequencyType;
	name: string;
}

interface PortTypeChoice {
	id: 'tcp'|'udp';
	name: string;
}

interface FlowEncryptionTypeChoice {
	id: boolean;
	name: string;
}
