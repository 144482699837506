<div class="flex-column gap-S pad-M" *ngIf="initialized">
	<div class="flex-align-center flex-space-between">
		<div class="font-MM fw-700">{{ 'page.data.appsLinked' | translate }}</div>
		<app-button-widget (onClick)="openDialog()">
			<img width="12" src="assets/icons/add.svg" class="filter-primary" alt="">
		</app-button-widget>
	</div>
	@for (app of dataApplications; track app.application.application.applicationId) {
		<div class="server-card greyed-hover-2">
			<div (click)="goToApp(app.application.application.applicationId)"
				 class="pl-s pr-m pt-s pb-s flex-space-between flex-align-center gap-S c-p">
				<div class="flex-align-center c-p">
					<div style="user-select: none" class="flex-center gap-S">
						<app-display-logo [size]="38" [logo]="app.application.application.logo"></app-display-logo>
						<div class="flex-justify-center flex-column gap-XS" style="width: 130px">
							<div class="text-overflow-ellipsis font-M fw-700">{{ app.application.application.name }}</div>
							<div *ngIf="app.application.category"
								 class="text-overflow-ellipsis font-S">{{ app.application.category.name }}
							</div>
						</div>
						<app-chips-criticality short [criticality]="app.application.application.criticality"></app-chips-criticality>
					</div>
				</div>
				<div class="flex-align-center gap-XL">
					<div>
						<app-chips-count [color]="app.application.application.usageActivated ? 'accent' : 'grey'" type="user" bold [value]="app.application.application.usageActivated ? app.usage.value || 0 : null"></app-chips-count>
					</div>
					<div style="margin-left: auto">
						<div class="disp-f justify-center a-i-c">
							<img src="assets/icons/arrow-right.svg">
						</div>
					</div>
				</div>
			</div>
		</div>
	} @empty {
		<div class="w-100 flex-center flex-column" style="gap: 30px; height: 280px;">
			<img src="assets/illustrations/empty-app.svg" width="150">
			<span class="grey">{{ 'page.data.noApps' | translate }}</span>
		</div>
	}
</div>
<div class="pad-M" *ngIf="!initialized">
	<app-dashboard-page-loader></app-dashboard-page-loader>
</div>
