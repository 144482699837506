import {BezierCurve, DrawableObject, HoverableObject, HoverableObjectStacked, Point} from "../diagram";
import {Layers} from "../layer";

type CurvedLineState = {};

export class StackedCurvedLine implements DrawableObject<CurvedLineState>, HoverableObjectStacked {
	public type = 'curved';
	protected isActiveHover: boolean = false;
	protected animationStartedAt: number = new Date().getTime() + Math.floor(Math.random() * 5000);
	protected lastAnimationUpdate= 0;
	protected animationPosition: number = 0;
	protected bubbles: DataBubble[] = [];

	constructor(
		protected ctx: any,
		protected from: Point,
		protected to: Point,
		protected flowDirection: FlowDirection,
		protected data: StackedCurvedLineData[] = []) {}

	protected getLeadingCoefficient(): number {
		if (this.to.x === this.from.x) {
			return 0;
		}
		return (this.to.y - this.from.y) / (this.to.x - this.from.x);
	}

	getSourceId(): string {
		return this.data[0].sourceId;
	}

	getTargetId(): string {
		return this.data[0].targetId;
	}

	getData(): StackedCurvedLineData[] {
		return this.data;
	}

	private active: boolean = true;

	isActive(): boolean {
		return this.active;
	}

	setFrom(from: Point) {
		this.from = from;
	}

	setTo(to: Point) {
		this.to = to;
	}

	setFlowDirection(flowDirection: FlowDirection) {
		this.flowDirection = flowDirection;
	}

	addData(data: StackedCurvedLineData) {
		this.data.push(data);
	}

	activate() : void {
		this.active = true;
		this.isActiveHover = true;
	}

	deactivate() : void {
		this.active = false;
		this.isActiveHover = false;
	}

	protected bezierCurveParameters(): { p1: Point, p2: Point } {
		const dx = this.to.x - this.from.x;
		const dy = this.to.y - this.from.y;
		const isHorizontal = Math.abs(dy) < 1;

		if (isHorizontal) {
			const offsetX = Math.min(Math.abs(dx) * 0.25, 80);
			const sign = dx >= 0 ? 1 : -1;
			return {
				p1: { x: this.from.x + sign * offsetX, y: this.from.y },
				p2: { x: this.to.x - sign * offsetX, y: this.to.y }
			};
		} else {
			const leadingCoefficient = this.getLeadingCoefficient();
			const a = (this.to.x - this.from.x) < 0 ? -160 : 160;

			if (leadingCoefficient !== 0) {
				return { p1: { x: this.from.x + a, y: this.from.y }, p2: { x: this.to.x - a, y: this.to.y } };
			} else {
				return { p1: { x: this.from.x + 100, y: this.from.y }, p2: { x: this.to.x + 100, y: this.to.y  } };
			}
		}
	}

	noDataImage?: HTMLImageElement;

	update(state: CurvedLineState = {}) {
		// Update bubble animation
		if (this.lastAnimationUpdate + 10 < new Date().getTime()) {
			this.animationPosition = this.animationPosition + 1;
			this.lastAnimationUpdate = new Date().getTime();
		}

		if (!this.noDataImage) {
			this.noDataImage = new Image();
			this.noDataImage.src = 'assets/icons/flow-no-data.svg';
		}
	}

	draw(layers: Layers): void {
		const {p1, p2} = this.bezierCurveParameters();
		layers.getLayer(1).addObject(this.drawDeactivated(p1, p2));
		layers.getLayer(3).addObject(this.drawActivated(p1, p2));
		layers.getLayer(6).addObject(this.drawDataBubble(p1, p2));
	}

	protected drawDeactivated(p1: Point, p2: Point) : ()=>void {
		if (!this.isActiveHover) {
			return () => {
				this.ctx.beginPath();
				this.ctx.strokeStyle = 'rgba(0, 0, 0, 0.05)';
				this.ctx.lineWidth = 4;
				this.ctx.moveTo(this.from.x, this.from.y);
				this.ctx.bezierCurveTo(p1.x, p1.y, p2.x, p2.y, this.to.x, this.to.y);
				this.ctx.stroke();
				this.ctx.lineWidth = 1;
			}
		} else {
			return () => {};
		}
	}

	protected drawActivated(p1: Point, p2: Point): ()=>void {
		if (this.isActiveHover) {
			return () => {
				this.ctx.beginPath();
				this.ctx.strokeStyle = 'rgba(54, 176, 235, 0.3)';
				this.ctx.lineWidth = 8;
				this.ctx.moveTo(this.from.x, this.from.y);
				this.ctx.bezierCurveTo(p1.x, p1.y, p2.x, p2.y, this.to.x, this.to.y);
				this.ctx.stroke();

				this.ctx.beginPath();
				this.ctx.strokeStyle = 'rgba(54, 176, 235, 1)';
				this.ctx.lineWidth = 1;
				this.ctx.moveTo(this.from.x, this.from.y);
				this.ctx.bezierCurveTo(p1.x, p1.y, p2.x, p2.y, this.to.x, this.to.y);
				this.ctx.stroke();

				if (this.animationStartedAt < new Date().getTime()) {
					if (this.flowDirection === FlowDirection.SOURCE_TO_TARGET) {
						const t = ((this.animationPosition) % 1000) / 1000;
						const bubble = this.getBezierXY(t, this.from.x, this.from.y, p1.x, p1.y, p2.x, p2.y, this.to.x, this.to.y);
						this.ctx.beginPath();
						this.ctx.arc(bubble.x, bubble.y, 4, 0, 2 * Math.PI);
						this.ctx.fillStyle = 'rgba(54, 176, 235, 1)';
						this.ctx.fill();
					} else if (this.flowDirection === FlowDirection.TARGET_TO_SOURCE) {
						const t = ((this.animationPosition) % 1000) / 1000;
						const bubble = this.getBezierXY(t, this.to.x, this.to.y, p2.x, p2.y, p1.x, p1.y, this.from.x, this.from.y);
						this.ctx.beginPath();
						this.ctx.arc(bubble.x, bubble.y, 4, 0, 2 * Math.PI);
						this.ctx.fillStyle = 'rgba(54, 176, 235, 1)';
						this.ctx.fill();
					} else if (this.flowDirection === FlowDirection.BIDIRECTIONAL) {
						const t1 = ((this.animationPosition) % 1000) / 1000;
						const bubble1 = this.getBezierXY(t1, this.from.x, this.from.y, p1.x, p1.y, p2.x, p2.y, this.to.x, this.to.y);
						this.ctx.beginPath();
						this.ctx.arc(bubble1.x, bubble1.y, 4, 0, 2 * Math.PI);
						this.ctx.fillStyle = 'rgba(54, 176, 235, 1)';
						this.ctx.fill();

						const t2 = ((this.animationPosition) % 1000) / 1000;
						const bubble2 = this.getBezierXY(t2, this.to.x, this.to.y, p2.x, p2.y, p1.x, p1.y, this.from.x, this.from.y);
						this.ctx.beginPath();
						this.ctx.arc(bubble2.x, bubble2.y, 4, 0, 2 * Math.PI);
						this.ctx.fillStyle = 'rgba(54, 176, 235, 1)';
						this.ctx.fill();
					}
				}
			}
		} else {
			return () => {};
		}
	}

	protected drawDataBubble(p1: Point, p2: Point) : ()=>void {
		if (this.isActiveHover) {
			return () => {
				let center = this.getBezierXY(0.5, this.from.x, this.from.y, p1.x, p1.y, p2.x, p2.y, this.to.x, this.to.y);

				const bubbleHeight = 40; // Height of each bubble including spacing
				const totalHeight = this.data.length * bubbleHeight;

				let startY = center.y - (totalHeight / 2) + (bubbleHeight / 2);

				this.bubbles = [];

				this.data.forEach((dataPoint, index) => {
					const bubbleY = startY + (index * bubbleHeight);
					if (dataPoint.data.length > 0) {
						const dataBubbleContent = dataPoint.data.length > 1 ? dataPoint.data[0] + ' (+' + (dataPoint.data.length - 1) + ')' : dataPoint.data.length === 0 ? 'Info' : dataPoint.data[0];
						this.drawDataTooltip(dataBubbleContent, { x: center.x, y: bubbleY }, this.isActiveHover ? "rgba(54, 176, 235, 1)" : "rgba(0, 0, 0, 0.1)", dataPoint);
					} else {
						this.drawNoDataTooltip({ x: center.x, y: bubbleY }, this.isActiveHover ? "rgba(54, 176, 235, 1)" : "rgba(0, 0, 0, 0.1)", dataPoint);
					}
				});
			}
		} else {
			return () => {};
		}
	}

	protected drawDataTooltip(content: string, coordinate: Point, color: string, dataPoint: StackedCurvedLineData): void {
		let fontSize = Math.max(Math.min(24, 24 * 1/this.ctx.getTransform().d), 14);
		this.ctx.font = "bold "+ (fontSize > 14 ? fontSize : 14) + "px proxima-nova";

		const metrics = this.ctx.measureText(content);

		let fontHeight;
		if (metrics.fontBoundingBoxDescent && metrics.fontBoundingBoxAscent) {
			fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
		} else {
			fontHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
		}

		// Tooltip background
		this.ctx.beginPath()
		this.ctx.fillStyle = color;
		const width = metrics.width + 40;
		const height = 30;
		this.ctx.roundRect(coordinate.x - width / 2, coordinate.y - height / 2, width, height, 15);
		this.ctx.fill()

		this.ctx.beginPath();
		this.ctx.textAlign = "center";
		this.ctx.fillStyle = "white";
		this.ctx.fillText(content, coordinate.x, coordinate.y + fontHeight / 5);

		// Reset text
		this.ctx.textAlign = "left";

		this.bubbles.push({
			flowId: dataPoint.id,
			dataBubbleCoordinates: {
				x: coordinate.x - width / 2,
				y: coordinate.y - height / 2
			},
			dataBubbleWidth: width,
			dataBubbleHeight: height
		});
	}

	protected drawNoDataTooltip(coordinate: Point, color: string, dataPoint: StackedCurvedLineData): void {
		// Tooltip background
		this.ctx.beginPath()
		this.ctx.fillStyle = color;
		const width = 32;
		const height = 32;
		this.ctx.roundRect(coordinate.x - width / 2, coordinate.y - height / 2, width, height, 30);
		this.ctx.fill()

		this.ctx.beginPath();
		if (this.noDataImage) {
			this.ctx.drawImage(this.noDataImage, coordinate.x - 10, coordinate.y - 10, 20, 20);
		}

		this.bubbles.push({
			flowId: dataPoint.id,
			dataBubbleCoordinates: {
				x: coordinate.x - width / 2,
				y: coordinate.y - height / 2
			},
			dataBubbleWidth: width,
			dataBubbleHeight: height
		});
	}

	protected getBezierXY(t: number, sx: number, sy: number, cp1x: number, cp1y: number, cp2x: number, cp2y: number, ex: number, ey: number): Point {
		return {
			x: Math.pow(1-t,3) * sx + 3 * t * Math.pow(1 - t, 2) * cp1x
				+ 3 * t * t * (1 - t) * cp2x + t * t * t * ex,
			y: Math.pow(1-t,3) * sy + 3 * t * Math.pow(1 - t, 2) * cp1y
				+ 3 * t * t * (1 - t) * cp2y + t * t * t * ey
		};
	}

	currentBubble: DataBubble | undefined;

	isAtCoordinates(mouseCoordinates: Point): { isAt: boolean, id: string } {
		if (this.bubbles.length === 0)
			return { isAt: false, id: '' };
		for (let bubble of this.bubbles) {
			if (!bubble.dataBubbleCoordinates || !bubble.dataBubbleWidth || !bubble.dataBubbleHeight)
				continue;
			if (mouseCoordinates.x >= bubble.dataBubbleCoordinates.x && mouseCoordinates.x <= bubble.dataBubbleCoordinates.x + bubble.dataBubbleWidth &&
				mouseCoordinates.y >= bubble.dataBubbleCoordinates.y && mouseCoordinates.y <= bubble.dataBubbleCoordinates.y + bubble.dataBubbleHeight) {
				this.currentBubble = bubble;
				return { isAt: true, id: bubble.flowId };
			}
		}
		return { isAt: false, id: '' };
	}

	getBottomMiddleBubblePosition(): Point {
		if (this.currentBubble) {
			if (!this.currentBubble.dataBubbleCoordinates || !this.currentBubble.dataBubbleWidth || !this.currentBubble.dataBubbleHeight)
				return { x: 0, y: 0 };
			return { x: this.currentBubble.dataBubbleCoordinates.x + this.currentBubble.dataBubbleWidth / 2, y: this.currentBubble.dataBubbleCoordinates.y + this.currentBubble.dataBubbleHeight };
		} else {
			if (this.bubbles.length === 0)
				return { x: 0, y: 0 };
			const bubble = this.bubbles[this.bubbles.length - 1];
			if (!bubble.dataBubbleCoordinates || !bubble.dataBubbleWidth || !bubble.dataBubbleHeight)
				return { x: 0, y: 0 };
			return { x: bubble.dataBubbleCoordinates.x + bubble.dataBubbleWidth / 2, y: bubble.dataBubbleCoordinates.y + bubble.dataBubbleHeight };
		}
	}
}

export interface StackedCurvedLineData {
	id: string,
	sourceId: string,
	targetId: string,
	data: string[]
}

export enum FlowDirection {
	SOURCE_TO_TARGET = 'sourceToTarget',
	TARGET_TO_SOURCE = 'targetToSource',
	BIDIRECTIONAL = 'bidirectional'
}

export interface DataBubble {
	flowId: string;
	dataBubbleCoordinates: Point;
	dataBubbleWidth?: number;
	dataBubbleHeight?: number
}
