import {Component} from '@angular/core';
import {DesignSystemModule} from "../../../../design-system/design-system.module";
import {TranslateModule} from "@ngx-translate/core";
import {ApplicationFlowComponent} from "./application-flow/application-flow.component";
import {ApplicationDataComponent} from "./application-data/application-data.component";
@Component({
  selector: 'app-application-data-tab',
	imports: [
		DesignSystemModule,
		TranslateModule,
		ApplicationFlowComponent,
		ApplicationDataComponent
	],
  templateUrl: './application-data-tab.component.html',
  styleUrl: './application-data-tab.component.scss'
})
export class ApplicationDataTabComponent {
}
