import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {finalize, forkJoin, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {ApplicationDetailData, ApplicationDetailService} from 'src/app/services/front/application-detail.service';
import {MatDialog} from '@angular/material/dialog';
import {filter, map} from 'rxjs/operators';
import {ConfirmComponent, ConfirmModel} from 'src/app/modules/global/dialog/confirm/confirm.component';
import {SnackbarService} from 'src/app/services/front/snackbar.service';
import {ApplicationGeneric} from 'src/app/services/model/new-application.model';
import {NewApplicationService} from 'src/app/services/back/new-application.service';
import {FileLink, FileType} from "src/app/services/model/tenant-file.model";
import {TenantFileService} from "src/app/services/back/tenant-file.service";
import {
	ApplicationFlowFormDialogComponent
} from "../application-flow-form/dialog/application-flow-form-dialog.component";
import {ApplicationFlowFormData} from "../application-flow-form/application-flow-form.component";
import {MiniButtonModule} from "../../../../../global/button/mini-button/mini-button.module";
import {DesignSystemModule} from "../../../../../design-system/design-system.module";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {DropdownTriggerDirective} from "../../../../../design-system/dropdown/dropdown-trigger.directive";
import {DirectivesModule} from "../../../../../../directives/directives.module";
import {Flow, FlowService} from "../../../../../../services/back/flow.service";
import {Data, DataService} from "../../../../../../services/back/data.service";
import {ButtonWidgetComponent} from "../../../../../design-system/button-widget/button-widget.component";
import {DeckContentLoaderModule} from "../../../../../global/title-content-loader/deck-content-loader.module";
import {
	FlowTooltipPositionDirective
} from "../../../../../../directives/flow-tooltip-position/flow-tooltip-position.directive";

@Component({
	selector: 'app-application-flow',
	templateUrl: './application-flow.component.html',
	styleUrls: ['./application-flow.component.scss'],
	imports: [
		MiniButtonModule,
		TranslateModule,
		DesignSystemModule,
		CommonModule,
		DropdownTriggerDirective,
		DirectivesModule,
		NgOptimizedImage,
		ButtonWidgetComponent,
		DeckContentLoaderModule,
		FlowTooltipPositionDirective
	]
})
export class ApplicationFlowComponent implements OnInit, OnDestroy {

	tenantId: string;
	applicationId: string;
	isEditor: boolean = false;

	_initializing: boolean;
	_loading: boolean;
	_loadingDialog: boolean;
	_deleting: string|undefined;
	initialized: boolean = false;

	displayedTooltip: Flow | undefined;

	flowList: Flow[] = [];
	formData?: {
		applicationList: ApplicationGeneric[],
		dataList: Data[]
	};
	subscriptions: Subscription = new Subscription();

	constructor(private applicationDetailService: ApplicationDetailService,
							private applicationService: NewApplicationService,
							private tenantFileService: TenantFileService,
							private translate: TranslateService,
							private snackBar: SnackbarService,
							private flowService: FlowService,
							private dataService: DataService,
							private dialog: MatDialog) {
	}

	ngOnInit(): void {
		this.subscriptions.add(this.applicationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscriptions.add(this.applicationDetailService.getApplicationDetailDataChanges()
			.pipe(tap(data => this.setApplicationDetailData(data)), filter(() => !this.initialized))
			.subscribe(() => this.initialize()));
	}

	displayTooltip(flow: Flow): void {
		this.displayedTooltip = flow;
	}

	hideTooltip(): void {
		this.displayedTooltip = undefined;
	}

	private setApplicationDetailData(data: ApplicationDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.applicationId = data.instance.applicationId;
	}

	initialize(): void {
		this.subscriptions.add(this.switchLoading()
			.pipe(
				switchMap(() => this.flowService.getAllFlowByApplicationId(this.tenantId, this.applicationId)),
				tap(flowList => this.setFlowList(flowList)),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.initialized = true));
	}

	private setFlowList(flowList: Flow[]): void {
		this.flowList = flowList.sort((a, b) => {
			const otherAName = a.source.id === this.applicationId ? a.target.name : a.source.name;
			const otherBName = b.source.id === this.applicationId ? b.target.name : b.source.name;
			return otherAName.localeCompare(otherBName);
		});
	}

	sizePrettily(size: number | undefined): string {
		if (!size) return '';
		if (size < 1024) {
			return `${size} B`;
		} else if (size < 1024 * 1024) {
			return `${Math.round(size / 1024)} KB`;
		} else if (size < 1024 * 1024 * 1024) {
			return `${Math.round(size / (1024 * 1024))} MB`;
		} else {
			return `${Math.round(size / (1024 * 1024 * 1024))} GB`;
		}
	}

	downloadFile(upload: FileLink) {
		this.tenantFileService.downloadTenantFile(this.tenantId, upload.fileLinkId)
			.subscribe((blob) => {
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.download = upload.name;
				link.click();
			});
	}

	openLink(link: string) {
		window.open(link, '_blank');
	}

	openFlowFormDialog(defaultData?: Flow): void {
		this.switchLoadingDialog()
			.pipe(
				switchMap(() => this.getFlowFormData()),
				map(formData => this.buildFlowFormData(formData, defaultData)),
				switchMap(data => this.dialog.open(ApplicationFlowFormDialogComponent, { position: { right: '162.5px' }, width: '475px', data: data }).afterClosed()),
				filter(updated => !!updated),
				tap(() => this.snackBar.show(this.translate.instant('page.application.detail.update.success'))),
				tap(() => this.applicationDetailService.refreshApplicationInstance()),
				finalize(() => this.switchLoadingDialog()))
			.subscribe(() => this.initialize());
	}

	private getFlowFormData(): Observable<{ applicationList: ApplicationGeneric[], dataList: Data[] }> {
		return of(this.formData).pipe(
			switchMap(formData => !formData
				? forkJoin([
					this.applicationService.getAllApplication(this.tenantId),
					this.dataService.getAllTenantData(this.tenantId)])
					.pipe(map(([applicationList, dataList]) => ({ applicationList, dataList })))
				: of(formData)),
			tap(formData => this.formData = formData));
	}

	private buildFlowFormData(formData: { applicationList: ApplicationGeneric[], dataList: Data[] }, defaultData?: Flow): ApplicationFlowFormData {
		return {
			tenantId: this.tenantId,
			applicationId: this.applicationId,
			defaultData: defaultData,
			preloaded: {
				applicationList: formData.applicationList,
				dataList: formData.dataList
			}
		}
	}

	deleteApplicationFlow(flowId: string): void {
		const data: ConfirmModel = {
			message: this.translate.instant('confirmModal.deleteFlow'),
			closeButton: this.translate.instant('button.cancel'),
			confirmButton: this.translate.instant('button.delete')
		};
		this.dialog.open<ConfirmComponent, any, boolean>(ConfirmComponent, { data: data })
			.afterClosed()
			.pipe(
				filter(result => !!result),
				tap(() => this.switchDeleting(flowId)),
				switchMap(() => this.flowService.deleteFlow(this.tenantId, flowId)),
				tap(() => this.switchDeleting(flowId)),
				filter(deleted => !!deleted),
				tap(() => this.snackBar.show(this.translate.instant('page.application.detail.update.success'))),
				tap(() => this.applicationDetailService.refreshApplicationInstance()))
			.subscribe(() => this.initialize());
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	private switchLoadingDialog(): Observable<{}> {
		this._loadingDialog = !this._loadingDialog;
		return of({});
	}

	private switchDeleting(flowId: string): void {
		this._deleting = !this._deleting ? flowId : undefined;
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	protected readonly fileType = FileType;
}
