import {Component, DestroyRef, OnInit, ViewChild} from "@angular/core";
import {map} from "rxjs/operators";
import {NavigationExtras, Router} from "@angular/router";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {CurrentTenantService} from '../../../services/front/current-tenant.service';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {MenuStateService} from "../../../services/front/menu-state.service";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {MatDividerModule} from "@angular/material/divider";
import {MatRippleModule} from "@angular/material/core";
import {
	APPLICATIONS_URL,
	ARCHITECTURE_URL, DATA_URL, DIAGRAMS_URL, EXTENSION_URL,
	FINANCE_URL,
	HOME_URL, INFRASTRUCTURE_URL,
	INTERCOM_URL,
	OPERATION_URL, ORGANIZATION_URL, SETTINGS_URL, USAGE_URL
} from "../../../models/home/navigation.model";
import * as AppUrl from "../../../models/home/navigation.model";
import {IntercomService} from "../../../services/front/intercom.service";
import {DropdownComponent} from "../../design-system/dropdown/dropdown.component";
import {DropdownTriggerDirective} from "../../design-system/dropdown/dropdown-trigger.directive";
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';

@Component({
	selector: 'app-main-menu',
	templateUrl: './main-menu.component.html',
	styleUrls: ['./main-menu.component.scss'],
	standalone: true,
  imports: [CommonModule, TranslateModule, MatDividerModule, MatRippleModule, DropdownTriggerDirective, NgOptimizedImage, DesignSystemModule]
})
export class MainMenuComponent implements OnInit {

	@ViewChild('dropdown', {static: false}) dropdown: DropdownComponent | undefined;

	isDropdownOpen: boolean = false;

	appsCount?: number = undefined;

	// Used in HTML, not unused.
	menuState$ = this.menuStateService.onMenuStateChange().pipe(takeUntilDestroyed(this.destroyRef));

	constructor(public translateService: TranslateService,
				public currentTenantService: CurrentTenantService,
				public destroyRef: DestroyRef,
				public menuStateService: MenuStateService,
				public intercomService: IntercomService,
				public router: Router) {
	}

	ngOnInit() {
		this.currentTenantService.getApplicationCountChanges()
			.subscribe((count) => this.appsCount = count);
	}

	isCurrentPage(url: string): boolean {
		return this.router.url.startsWith(url);
	}

	goTo(url: AppUrl.AppRouteUrl|`https://${string}`|`http://${string}`|undefined): void {
		if (url) {
			if (url.trim().startsWith('https') || url.trim().startsWith('http')) {
				window.open(url, '_blank');
			} else if (url.trim().startsWith(INTERCOM_URL)) {
				this.intercomService.toggle();
			} else {
				this.intercomService.hide();
				this.currentTenantService.getCurrentTenantId()
					.pipe(map(tenantId => ({queryParamsHandling: 'preserve', queryParams: { tenant: tenantId }} as NavigationExtras)))
					.subscribe(extras => this.router.navigate([url], extras));
			}
		}
	}

	closeDropdown() {
		if (this.dropdown) {
			this.dropdown.close()
		}
	}

	protected readonly HOME_URL = HOME_URL;
	protected readonly OPERATION_URL = OPERATION_URL;
	protected readonly FINANCE_URL = FINANCE_URL;
	protected readonly USAGE_URL = USAGE_URL;
	protected readonly ARCHITECTURE_URL = ARCHITECTURE_URL;
	protected readonly ORGANIZATION_URL = ORGANIZATION_URL;
	protected readonly APPLICATIONS_URL = APPLICATIONS_URL;
	protected readonly INFRASTRUCTURE_URL = INFRASTRUCTURE_URL;
	protected readonly DIAGRAMS_URL = DIAGRAMS_URL;
	protected readonly EXTENSION_URL = EXTENSION_URL;
	protected readonly INTERCOM_URL = INTERCOM_URL;
	protected readonly SETTINGS_URL = SETTINGS_URL;
	protected readonly DATA_URL = DATA_URL;
}
