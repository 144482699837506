import {Component, Input} from '@angular/core';
import {Color} from '../chips/chips/chips.component';
import {InfrastructureUtils} from '../../home/infrastructure/infrastructure.utils';
import {DataCollectionStatus, Server} from "../../../services/model/server.model";
import {map} from "rxjs/operators";

@Component({
	selector: 'app-application-server-card',
	standalone: false,
	templateUrl: './application-server-card.component.html',
	styleUrls: ['./application-server-card.component.scss']
})
export class ApplicationServerCardComponent {
	@Input() server: Server;
	@Input() disabled: boolean = false;
	@Input() delete?: (serverId: string) => void;

	get statusColor(): Color {
		switch (this.server.dataCollectionStatus) {
			case DataCollectionStatus.ACTIVE: return 'success';
			case DataCollectionStatus.OUTDATED: return 'warning';
			case DataCollectionStatus.ERROR: return 'danger';
			default: return 'danger';
		}
	}

	protected readonly map = map;
	protected readonly DataCollectionStatus = DataCollectionStatus;
	protected readonly InfrastructureUtils = InfrastructureUtils;
}
