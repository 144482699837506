import { Directive, ElementRef, AfterViewInit, Input, OnDestroy } from '@angular/core';

@Directive({
	selector: '[flowTooltipPosition]'
})
export class FlowTooltipPositionDirective implements AfterViewInit, OnDestroy {
	@Input() tooltipOffset = 8;
	private resizeObserver: ResizeObserver;

	constructor(private el: ElementRef) {
		this.resizeObserver = new ResizeObserver(() => {
			this.adjustPosition();
		});
	}

	ngAfterViewInit() {
		this.el.nativeElement.style.opacity = '0';
		this.resizeObserver.observe(this.el.nativeElement);
		requestAnimationFrame(() => {
			this.adjustPosition();
			this.el.nativeElement.style.opacity = '1';
		});
	}

	ngOnDestroy() {
		this.resizeObserver.disconnect();
	}

	private adjustPosition() {
		const tooltip = this.el.nativeElement;
		const rect = tooltip.getBoundingClientRect();
		const viewportHeight = window.innerHeight;

		if (rect.bottom > viewportHeight - this.tooltipOffset) {
			tooltip.style.transform = `translateY(-${rect.height + 50}px)`;
		}
	}
}
