<div class="pad-L">
	<div class="bg-rounded pad-M">
		<span class="font-M fw-700">{{ 'page.data.general' | translate }}</span>

		<form [formGroup]="formGroup">
			<div class="flex-column gap-XS mt-l">
				<div class="font-M fw-500 grey">{{ 'page.data.namePlaceholder' | translate }}*</div>
				<app-text-input (enter)="updateOnBlur.emit(Form.name)" (blur)="updateOnBlur.emit(Form.name)" [input]="nameFormControl" [placeholder]="'page.data.namePlaceholder' | translate"></app-text-input>
			</div>
		</form>
	</div>
</div>
