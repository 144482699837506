<dialog [@inOutAnimation] #newsDialog class="news-dialog overflow-hidden flex-space-between flex-column">
	<div class="flex-justify-end pad-L">
		<app-icon-button (click)="onClose()" img='assets/icons/close.svg' size='s' color='accent' type='stroked'
						 shape='default' [disabled]='false' [focused]='false'
						 [background]='true' panelClass='img-size-30'></app-icon-button>
	</div>
	<div class="flex content-container gap-M" style="overflow-y: auto;" *ngIf="initialized">
		<div class="content-text flex-column gap-XL" style="padding-left: 40px; align-self: center;">
			<div class="flex-center news-icon" [style.background]="currentNews.iconBackgroundColor">
				<img [src]="currentNews.icon" width="24" height="24">
			</div>
			<div class="flex-column gap-XS">
				<span class="font-XL fw-700">{{ currentNews.title | translate }}</span>
				<span class="fw-700 font-MM accent-secondary">{{ 'page.home.news.news' | translate }}</span>
			</div>
			<p class="font-L" [innerHTML]="currentNews.content | translate" style="line-height: 27px;"></p>
		</div>
		<img class="illustration-img" [src]="currentNews.image" width="511">
	</div>
	<div *ngIf="initialized">
		<div class="hr-document"></div>
		<div class="flex-space-between flex-align-center" style="padding: 15px 20px">
			<span class="grey">{{getCurrentPage() + '/' + unseenNews.length }}</span>
			<app-text-button *ngIf="!currentNews.buttonLabel || !currentNews.buttonAction" style="height: 40px; width: 120px;" type="flat" color="accent" panelClass="h-100-imp b-radius-9"
							 (click)="onNext()">
				<span class="font-M fw-500">{{ getCurrentPage() === news.length ? ('page.home.news.close' | translate) : ('page.home.news.next' | translate) }}</span>
			</app-text-button>
			<app-text-button *ngIf="currentNews.buttonLabel && currentNews.buttonAction" style="height: 40px;" type="flat" color="accent" panelClass="h-100-imp b-radius-9"
							 (click)="currentNews.buttonAction()">
				<span class="font-M fw-500">{{ currentNews.buttonLabel | translate }}</span>
			</app-text-button>
		</div>
	</div>
</dialog>
