import {Component, Input} from '@angular/core';
import {TenantAccount} from 'src/app/services/model/account.model';

@Component({
  selector: 'app-display-user',
  standalone: false,
  templateUrl: './display-user.component.html',
  styleUrls: ['./display-user.component.scss']
})
export class DisplayUserComponent {
  @Input() user: TenantAccount|undefined|null;

  private _width?: number = undefined;
  @Input()
  get width(): string|undefined {
    if (this._width) {
      if (this.user?.account.provider !== 'local') {
        return this._width - 16 + 'px';
      } else {
        return this._width + 'px';
      }
    } else {
      return undefined;
    }
  }
  set width(value: number|undefined) {
    this._width = value;
  }

  private _size: 38|30|25 = 30;
  @Input()
  get size(): 38|30|25 {
    return this._size
  }
  set size(value: 'S'|'M'|'L') {
    this._size = value === 'L' ? 38 : (value === 'M' ? 30 : 25);
  }

  private _bold: boolean;
  @Input()
  get bold() {
    return this._bold;
  }
  set bold(value: any) {
    this._bold = value != null && `${value}` !== 'false';
  }

  private _email: boolean;
  @Input()
  get email() {
    return this._email;
  }
  set email(value: any) {
    this._email = value != null && `${value}` !== 'false';
  }

  private _provider: boolean;
  @Input()
  get provider() {
    return this._email;
  }
  set provider(value: any) {
    this._email = value != null && `${value}` !== 'false';
  }
}
