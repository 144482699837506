import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
//import * as Sentry from "@sentry/angular-ivy";

if (environment.production) {
  enableProdMode();
}

const APP_ID = environment.intercomAppId;

/**
 * Sentry integration
 */

if (environment.sentryConfiguration.enabled) {
	/*Sentry.init({
		dsn: environment.sentryConfiguration.dsn,
		tracePropagationTargets: environment.sentryConfiguration.tracePropagationTargets,
		integrations: [
			new Sentry.BrowserTracing()
		],
		tracesSampleRate: environment.sentryConfiguration.tracesSampleRate,
		replaysSessionSampleRate: environment.sentryConfiguration.replaysSessionSampleRate,
		replaysOnErrorSampleRate: environment.sentryConfiguration.replaysOnErrorSampleRate,
	});*/
}


/**
 * Hotjar integration
 */
if (environment.production) {
	// @ts-ignore
	(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:2819262,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
}

/**
 * Intercom integration
 */
// @ts-ignore
(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();


setTimeout(() => {
	platformBrowserDynamic()
		.bootstrapModule(AppModule)
		.catch(err => console.error(err));
}, 2000)

// attach reCaptcha to head
const head = document.getElementsByTagName('head')[0];
const script = document.createElement('script');
script.type = 'text/javascript';
script.src = `https://www.google.com/recaptcha/api.js?render=explicit`;
head.appendChild(script);
