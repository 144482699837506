import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {
	Data,
	DataForm,
	DataPrivacy,
	DataService,
	DataType
} from "../../../../../../services/back/data.service";
import {FormControl, FormGroup} from "@angular/forms";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {SnackbarService} from "../../../../../../services/front/snackbar.service";
import {DataDetailService} from "../../../../../../services/front/data-detail.service";
import {distinctUntilChanged, finalize, Observable, of, Subscription, switchMap, takeWhile, tap} from "rxjs";
import {CriticalityLevel} from "../../../../../../services/tenant.service";
import {filter, map} from "rxjs/operators";
import {DesignSystemModule} from "../../../../../design-system/design-system.module";
import {CommonModule} from "@angular/common";
import {ApplicationCategoryService} from "../../../../../../services/back/application-category.service";
import {ApplicationCategoryForm, Category} from "../../../../../../services/model/application-category.model";
import {InfrastructureSchemaService} from "../../../../../../services/back/infrastructure-schema.service";

@Component({
  selector: 'app-data-information-form',
	imports: [
		DesignSystemModule,
		CommonModule,
		TranslateModule
	],
  templateUrl: './data-information-form.component.html',
  styleUrl: './data-information-form.component.scss'
})
export class DataInformationFormComponent implements OnInit, OnDestroy{
	@Input() data: Data;

	tenantId: string;
	_saving: boolean;

	initialized: boolean = false;
	advancementPercent: number = 0;

	dataTypeList: DataType[] = Object.values(DataType);
	dataPrivacyList: DataPrivacy[] = Object.values(DataPrivacy);
	dataCriticalityList: CriticalityLevel[] = Object.values(CriticalityLevel);
	formGroup: FormGroup;

	categorySearchFormControl: FormControl<string | null> = new FormControl<string | null>('');
	dataCategoryList: Category[] = [];
	filteredDataCategoryList: Category[] = [];

	private dataService = inject(DataService);
	private translateService = inject(TranslateService);
	private snackbarService = inject(SnackbarService);
	private dataDetailService = inject(DataDetailService);
	private applicationCategoryService = inject(ApplicationCategoryService)
	private infrastructureSchemaService = inject(InfrastructureSchemaService);

	subscription: Subscription = new Subscription();

	ngOnInit() {
		this.subscription.add(this.dataDetailService.getDataDetailDataChanges()
			.pipe(tap(data => {
				this.tenantId = data.tenantId
				const values: any[] = [data.data.types.length, data.data.privacy, data.data.criticality, data.data.category];
				this.advancementPercent = (values.filter(v => !!v).length / values.length) * 100;
			}))
			.subscribe(() => this.initialize()));
		this.buildForm();

	}

	initialize(): void {
		this.subscription.add(this.applicationCategoryService.getAllApplicationCategoryByTenantId(this.tenantId)
				.pipe(
					tap(categories => {
						this.dataCategoryList = categories;
						this.filteredDataCategoryList = this.dataCategoryList;
					})
				).subscribe(() => this.initialized = true));
	}

	buildForm(): void {
		this.formGroup = new FormGroup({
			type: new FormControl<DataType[]>(this.data.types),
			privacy: new FormControl<DataPrivacy | null>(this.data.privacy),
			criticality: new FormControl<CriticalityLevel | null>(this.data.criticality),
			category: new FormControl<Category | null>(this.data.category),
		});

		this.subscription.add(this.categorySearchFormControl.valueChanges.pipe(distinctUntilChanged())
			.subscribe(text => this.filterCategoryList(text)));

		this.subscription.add(this.formGroup.valueChanges.pipe(distinctUntilChanged())
			.subscribe(() => this.updateData()));
	}

	filterCategoryList(text?: string | null) {
		this.filteredDataCategoryList = this.dataCategoryList
			.filter(value => !text || value.name.toLowerCase().includes(text.toLowerCase()))
			.sort((a, b) => a.name.localeCompare(b.name));
	}

	createCategory(name: string): void {
		if (this.dataCategoryList.some(category => category.name === name)) {
			this.formGroup.controls.category.setValue(this.dataCategoryList.find(category => category.name === name));
			return;
		}
		const form: ApplicationCategoryForm = {
			name: name
		}
		this.applicationCategoryService.createApplicationCategoryForTenant(this.tenantId, form)
			.subscribe(categoryId => {
				const newData: Category = {
					categoryId: categoryId,
					name: name
				}
				this.dataCategoryList.push(newData);
				this.filteredDataCategoryList = this.dataCategoryList;
				this.formGroup.controls.category.setValue(newData);
			});
	}

	updateData(): void {
		this.switchSaving()
			.pipe(
				filter(() => this.hasInformationChanged() && this.formGroup.valid),
				map(() => this.buildDataForm()),
				switchMap(form => this.dataService.update(this.tenantId, this.data.id, form)),
				tap((updateSucceeded) => this.displayUpdatedSucceed(updateSucceeded)),
				tap(() => this.dataDetailService.refreshData()),
				takeWhile(() => !this.subscription.closed),
				finalize(() => this.switchSaving()))
			.subscribe();
	}

	hasInformationChanged(): boolean {
		return this.data.privacy !== (this.privacyFormControl.value || null)
			|| this.data.criticality !== (this.criticalityFormControl.value || null)
			|| this.data.category !== (this.categoryFormControl.value || null)
			|| this.data.types !== (this.typeFormControl.value);
	}

	buildDataForm(): DataForm {
		return {
			name: this.data.name,
			description: this.data.description || undefined,
			privacy: this.privacyFormControl.value || null,
			criticality: this.criticalityFormControl.value || null,
			category: this.categoryFormControl.value || null,
			types: this.typeFormControl.value
		};
	}

	displayUpdatedSucceed(updateSucceed: boolean): void {
		if (updateSucceed) {
			this.snackbarService.show(this.translateService.instant('page.application.detail.update.success'));
		} else {
			this.snackbarService.show(this.translateService.instant('page.application.detail.update.fail'), 'danger-snack');
		}
	}

	switchSaving(): Observable<{}> {
		this._saving = !this._saving;
		return of({});
	}

	get typeFormControl(): FormControl {
		return this.formGroup.get('type') as FormControl;
	}

	get privacyFormControl(): FormControl {
		return this.formGroup.get('privacy') as FormControl;
	}

	get criticalityFormControl(): FormControl {
		return this.formGroup.get('criticality') as FormControl;
	}

	get categoryFormControl(): FormControl {
		return this.formGroup.get('category') as FormControl;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
