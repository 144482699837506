import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {ChipsButtonComponent} from 'src/app/modules/design-system/chips-button/chips-button.component';
import {ChipsCountComponent} from 'src/app/modules/design-system/chips-count/chips-count.component';
import {
  DisplayApplicationComponent
} from 'src/app/modules/design-system/display-application/display-application.component';
import {DisplayLogoComponent} from 'src/app/modules/design-system/display-logo/display-logo.component';
import {SpinnerComponent} from 'src/app/modules/design-system/spinner/spinner.component';
import {DisplayUserComponent} from 'src/app/modules/design-system/display-user/display-user.component';
import {DisplayUserLogoComponent} from 'src/app/modules/design-system/display-user-logo/display-user-logo.component';
import {DropdownComponent} from 'src/app/modules/design-system/dropdown/dropdown.component';
import {IconButtonComponent} from 'src/app/modules/design-system/icon-button/icon-button.component';
import {MultiSelectComponent} from 'src/app/modules/design-system/multi-select/multi-select.component';
import {
  SelectTriggerComponent
} from 'src/app/modules/design-system/multi-select/select-trigger/select-trigger.component';
import {SelectOptionComponent} from 'src/app/modules/design-system/multi-select/select-option/select-option.component';
import {SelectSearchComponent} from 'src/app/modules/design-system/multi-select/select-search/select-search.component';
import {SelectInsertComponent} from 'src/app/modules/design-system/multi-select/select-insert/select-insert.component';
import {
  ProgressBarIndicatorComponent
} from 'src/app/modules/design-system/progress-bar-indicator/progress-bar-indicator.component';
import {
  ProgressBarLoaderComponent
} from 'src/app/modules/design-system/progress-bar-loader/progress-bar-loader.component';
import {
  SelectOrInviteUserInputComponent
} from 'src/app/modules/design-system/select-or-invite-user-input/select-or-invite-user-input.component';
import {TextAreaComponent} from 'src/app/modules/design-system/text-area/text-area.component';
import {TextButtonComponent} from 'src/app/modules/design-system/text-button/text-button.component';
import {TextInputComponent} from 'src/app/modules/design-system/text-input/text-input.component';
import {ToggleButtonComponent} from 'src/app/modules/design-system/toggle-button/toggle-button.component';
import {UploadFileComponent} from 'src/app/modules/design-system/upload-file/upload-file.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatBadgeModule} from '@angular/material/badge';
import {MatRippleModule} from '@angular/material/core';
import {ChipsCriticalityComponent} from 'src/app/modules/design-system/chips-criticality/chips-criticality.component';
import {ChipsHealthComponent} from 'src/app/modules/design-system/chips-health/chips-health.component';
import {ChipsTextComponent} from "./chips-text/chips-text.component";
import {
  SelectOptionGroupComponent
} from 'src/app/modules/design-system/multi-select/select-option-group/select-option-group.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {TooltipComponent, TooltipDirective} from 'src/app/modules/design-system/tooltip/tooltip.component';
import {
  MiniDrawerComponent,
  MiniDrawerDirective
} from 'src/app/modules/design-system/mini-drawer/mini-drawer.component';
import {PopupTextInputComponent} from "./popup-text-input/popup-text-input.component";
import {PopupTriggerComponent} from "./popup-text-input/popup-trigger/popup-trigger.component";
import {ServerIconComponent} from "./server-icon/server-icon.component";
import {CdkTrapFocus} from "@angular/cdk/a11y";

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    TranslateModule,
    ReactiveFormsModule,
    MatIconModule,
    MatBadgeModule,
    MatRippleModule,
    NgOptimizedImage,
    FormsModule,
    MatCheckboxModule,
    CdkTrapFocus,
  ],
  declarations: [
    ChipsButtonComponent,
    ChipsCountComponent,
    ChipsCriticalityComponent,
    ChipsHealthComponent,
	  ChipsTextComponent,
    DisplayApplicationComponent,
    DisplayLogoComponent,
    SpinnerComponent,
    DisplayUserComponent,
    DisplayUserLogoComponent,
    DropdownComponent,
    IconButtonComponent,
    MultiSelectComponent,
	  PopupTextInputComponent,
    SelectTriggerComponent,
	  PopupTriggerComponent,
    SelectOptionComponent,
    SelectOptionGroupComponent,
    SelectSearchComponent,
    SelectInsertComponent,
    ProgressBarIndicatorComponent,
    ProgressBarLoaderComponent,
    SelectOrInviteUserInputComponent,
    TextAreaComponent,
    TextButtonComponent,
    TextInputComponent,
    ToggleButtonComponent,
    UploadFileComponent,
    TooltipDirective,
    TooltipComponent,
    MiniDrawerDirective,
    MiniDrawerComponent,
	  ServerIconComponent
  ],
  exports: [
    ChipsButtonComponent,
    ChipsCountComponent,
    ChipsCriticalityComponent,
    ChipsHealthComponent,
    DisplayApplicationComponent,
    DisplayLogoComponent,
    SpinnerComponent,
    DisplayUserComponent,
    DisplayUserLogoComponent,
    DropdownComponent,
    IconButtonComponent,
    MultiSelectComponent,
	  PopupTextInputComponent,
    SelectTriggerComponent,
	  PopupTriggerComponent,
    SelectOptionComponent,
    SelectOptionGroupComponent,
    SelectSearchComponent,
    SelectInsertComponent,
    ProgressBarIndicatorComponent,
    ProgressBarLoaderComponent,
    SelectOrInviteUserInputComponent,
    TextAreaComponent,
    TextButtonComponent,
    TextInputComponent,
    ToggleButtonComponent,
    UploadFileComponent,
    ChipsTextComponent,
    TooltipDirective,
    TooltipComponent,
    MiniDrawerDirective,
    MiniDrawerComponent,
	  PopupTextInputComponent,
	  ServerIconComponent
  ]
})
export class DesignSystemModule {}
