<div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="disabled ? null : displayListMenu">
	<ng-content></ng-content>
	<mat-menu class="mat-menu-select-user" (click)="$event.stopPropagation()" xPosition="after" yPosition="below" #displayListMenu (closed)="onClose()">
		<div (click)="$event.stopPropagation()" class="pr-m pl-m pt-m pb-m">
			<div (click)="$event.stopPropagation()">
				<div (click)="showUserList = !showUserList" class="disp-f a-i-c j-c-sb c-p mb-s">
					<span class="fw-700">{{ 'global.tenantUsers' | translate }}</span>
					<div class="arrow-down-select">
						<img class="filter-grey" ngSrc="assets/icons/arrow-right-black.svg" [style.transform]="showUserList ? 'rotate(90deg)' : ''" height="12" width="8" alt="">
					</div>
				</div>
				<div *ngIf="showUserList" class="mt-m">
					<app-text-input [input]="searchFormControl" class="w-100" [placeholder]="searchPlaceholder"></app-text-input>
					<div *ngIf="!multiselect && filteredItems() && filteredItems().length" style="max-height: 140px; overflow-y: auto; overflow-x: hidden;" class="mt-m">
						<div *ngFor="let user of filteredItems(); let index = index;" class="disp-f a-i-c j-c-sb pt-xs pb-xs mt-xs c-p single-user"
                             [class.mb-xs]="index < filteredItems().length - 1"
                             (click)="onSelectUser(user)">
							<app-display-user [user]="user.tenantAccount"></app-display-user>
							<img style="margin-right: 5px;" *ngIf="user.selected" ngSrc="assets/icons/blue-check.svg" alt="" height="16" width="16">
						</div>
					</div>
					<div *ngIf="multiselect && filteredItems() && filteredItems().length" style="max-height: 140px; overflow-y: auto; overflow-x: hidden;" class="mt-m">
						<div *ngFor="let user of filteredItems(); let index = index;" class="disp-f a-i-c gap-XS pt-xs pb-xs mt-xs single-user"
							 [class.c-p]="limit > filteredItemsSelected().length || user.selected"
							 [class.c-na]="limit === filteredItemsSelected().length && !user.selected"
							 [class.mb-xs]="index < filteredItems().length - 1"
							 (click)="update(user, !user.selected)">
							<app-display-user [width]="150" [user]="user.tenantAccount"></app-display-user>
							<mat-checkbox [disabled]="limit === filteredItemsSelected().length && !user.selected" [checked]="user.selected" class="custom-checkbox"></mat-checkbox>
						</div>
					</div>
					<div *ngIf="filteredItems().length <= 0" style="height: 100px;" class="disp-f a-i-c j-c-c">
						<span class="font-S fw-700 grey text-center">{{ 'global.noOtherUsersOnTenant' | translate }}</span>
					</div>
				</div>
			</div>
			<div class="hr-text"></div>
			<div (click)="$event.stopPropagation()" class="mt-s">
				<div (click)="showInviteUser = !showInviteUser; emailFormControl.reset()" class="disp-f a-i-c j-c-sb c-p">
					<span class="fw-700">{{ 'global.emailInvitation' | translate }}</span>
					<div class="arrow-down-select">
						<img class="filter-grey" ngSrc="assets/icons/arrow-right-black.svg" [style.transform]="showInviteUser ? 'rotate(90deg)' : ''" height="12" width="8" alt="">
					</div>
				</div>
				<div class="mt-m" *ngIf="showInviteUser">
					<div class="mb-m">
						<app-text-input [input]="emailFormControl" class="w-100" [placeholder]="'john.doe@kabeen.io'"></app-text-input>
					</div>
                    <app-text-button type="flat" class="mt-m"
                                     [disabled]="emailFormControl.invalid"
                                     (click)="onInviteUser()">Inviter</app-text-button>
				</div>
			</div>
		</div>
	</mat-menu>
</div>
