<div class="flex delete-hover">
	<div class="flex-column pad-M gap-S border-light-grey radius-M" style="width: 193px;">
		<div class="flex gap-S">
			<div class="flex-center infrastructure-logo radius-S">
				<app-server-icon [server]="server" [size]="38"></app-server-icon>
			</div>
			<div class="flex-column flex-justify-center" style="width: calc(100% - 48px);">
				<div class="font-M fw-700 text-overflow-ellipsis">{{server.name}}</div>
				<div class="font-S fw-500 text-overflow-ellipsis" *ngIf="!server.automatic">{{InfrastructureUtils.buildSystemName(server.system)}}</div>
				<div class="font-S fw-500 text-overflow-ellipsis" *ngIf="server.automatic">{{server.system}}</div>

			</div>
		</div>
		<div class="w-100 border-light-grey-bottom"></div>
		<div class="flex-space-between flex-align-center" *ngIf="server.dataCollectionStatus">
			<span class="font-S fw-500">{{'Status'}}</span>
			<app-chips size="s" [color]="statusColor">
				<span *ngIf="server.dataCollectionStatus === DataCollectionStatus.OUTDATED">{{ 'page.infrastructure.overview.outdated' | translate }}</span>
				<span *ngIf="server.dataCollectionStatus === DataCollectionStatus.ACTIVE">{{ 'page.infrastructure.overview.active' | translate }}</span>
				<span *ngIf="server.dataCollectionStatus === DataCollectionStatus.ERROR">{{ 'page.infrastructure.overview.errorText' | translate }}</span>
			</app-chips>
		</div>
		<div class="flex-space-between flex-align-center" *ngIf="!server.dataCollectionStatus">
			<span class="font-S fw-500">{{'Status'}}</span>
			<app-chips size="s" [color]="'grey'">{{'-'}}</app-chips>
		</div>
	</div>
	<app-icon-button *ngIf="!!delete" color="danger" shape="round" class="delete-btn h-100" size="xs" [panelClass]="'border-grey bkg-white'"
					 [img]="'assets/icons/trash.svg'" [disabled]="disabled"
					 (click)="delete(server.id)">
	</app-icon-button>
</div>
