import {Component} from '@angular/core';
import {DataPrivacy, DataService, DataTableData, DataType} from "../../../../services/back/data.service";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {ApplicationGeneric} from "../../../../services/model/new-application.model";
import {CriticalityLevel} from "../../../../services/tenant.service";
import {CurrentTenantService} from "../../../../services/front/current-tenant.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {RightSliderService} from "../../../../services/front/right-slider.service";
import {MatDialog} from "@angular/material/dialog";
import {ExportService, ExportType} from "../../../../services/front/export.service";
import {ActivatedRoute, Router} from "@angular/router";
import {distinctUntilChanged, finalize, from, merge, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {DesignSystemModule} from "../../../design-system/design-system.module";
import {MatMenu, MatMenuTrigger} from "@angular/material/menu";
import {CommonModule} from "@angular/common";
import {DropdownTriggerDirective} from "../../../design-system/dropdown/dropdown-trigger.directive";
import {ChipsModule} from "../../../global/chips/chips/chips.module";
import {TopbarService} from "../../../../services/front/topbar.service";
import {filter, first, map} from "rxjs/operators";
import {CreateDataComponent} from "./create-data/create-data.component";
import {DataDetailComponent, DataDetailInput} from "../data-detail/data-detail.component";
import {SnackbarService} from "../../../../services/front/snackbar.service";
import {SnackbarModule} from "../../../global/snackbar/snackbar.module";
import {ApplicationCategoryService} from "../../../../services/back/application-category.service";
import {Category} from "../../../../services/model/application-category.model";

@Component({
  selector: 'app-data-list',
	imports: [
		DesignSystemModule,
		MatMenu,
		CommonModule,
		ReactiveFormsModule,
		TranslateModule,
		DropdownTriggerDirective,
		MatMenuTrigger,
		ChipsModule,
		SnackbarModule
	],
  templateUrl: './data-list.component.html',
  styleUrl: './data-list.component.scss'
})
export class DataListComponent {
	tenantId: string;

	_initializing: boolean;
	_loading: boolean;
	_exporting: boolean;

	dataRows: DataTableData[] = [];
	displayedDataRows: DataTableData[] = [];

	openSearch: boolean = false;
	openFilters: boolean = false;
	filterForm: FormGroup;
	list: {
		type: DataType[];
		applications: ApplicationGeneric[];
		privacy: DataPrivacy[];
		criticality: CriticalityLevel[],
		categories: Category[]
	}

	dataCategoryList: Category[] = [];

	activeFilters: DataFilter[] = [];
	form: typeof Form = Form;
	column: typeof DataSortColumn = DataSortColumn;
	direction: typeof SortDirection = SortDirection;

	isEditor: boolean = false;

	subscription: Subscription = new Subscription();

	constructor(private currentTenantService: CurrentTenantService,
				private translate: TranslateService,
				private rightSliderService: RightSliderService,
				private dialog: MatDialog,
				private exportService: ExportService,
				private router: Router,
				private activatedRoute: ActivatedRoute,
				private topbarService: TopbarService,
				private snackbarService: SnackbarService,
				private applicationCategoryService: ApplicationCategoryService,
				private dataService: DataService) {
	}

	ngOnInit(): void {
		this.createForm();
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantChanges()
			.pipe(tap(tenant => {
				this.tenantId = tenant.configuration.id
				this.isEditor = tenant.configuration.role !== 'read_only';
			}))
			.subscribe(() => this.initialize()));
		this.subscription.add(this.activatedRoute.queryParams
			.pipe(filter(params => params?.dataId), first())
			.subscribe(params => this.openDataDrawer(params.dataId)));
	}

	private createForm(): void {
		this.filterForm = new FormGroup({
			[Form.name]: new FormControl(''),
			[Form.type]: new FormControl([]),
			[Form.criticality]: new FormControl([]),
			[Form.category]: new FormControl([]),
			[Form.searchCategory]: new FormControl(''),
			[Form.privacy]: new FormControl([]),
			[Form.application]: new FormControl([]),
			[Form.searchApplication]: new FormControl(''),
			[Form.sort]: new FormControl({column: DataSortColumn.NAME, direction: SortDirection.ASC}),
		});
		this.subscription.add(merge(
			this.filterForm.get(Form.name)!.valueChanges
				.pipe(distinctUntilChanged()),
			this.filterForm.get(Form.type)!.valueChanges,
			this.filterForm.get(Form.criticality)!.valueChanges,
			this.filterForm.get(Form.privacy)!.valueChanges,
			this.filterForm.get(Form.application)!.valueChanges,
			this.filterForm.get(Form.category)!.valueChanges,
			this.filterForm.get(Form.sort)!.valueChanges)
			.pipe(
				tap(() => this.setActiveFilters()),
				tap(() => this.setDataListByFilter()))
			.subscribe());
		this.subscription.add(this.filterForm.get(Form.searchApplication)!.valueChanges
			.subscribe(search => this.searchApplicationFilter(search)));
		this.subscription.add(this.filterForm.get(Form.searchCategory)!.valueChanges
			.subscribe(search => this.searchCategoryFilter(search)));
	}

	initialize(firstTime: boolean = true): void {
		if (firstTime) {
			this.dataRows = [];
			this.displayedDataRows = [];
			this.clearFilters(false);
		}
		this.fetchDataTableData(firstTime);
		this.fetchFilterPanelData();
	}

	private fetchDataTableData(firstTime: boolean): void {
		this.subscription.add(this.setLoading(firstTime)
			.pipe(
				switchMap(() => this.dataService.getTenantDataTableData(this.tenantId)),
				tap(data => {
					this.topbarService.onTitleChange(
						this.translate.instant('menu.data'),
						this.translate.instant('menu.subtitle.data'),
						data.length.toString()
					);
					const apps = data
						.map(data => data.applications)
						.flat()
						.filter((application, index, self) => self.findIndex(a => a.id === application.id) === index);
					if (!this.list) {
						this.list = {
							type: [],
							applications: apps,
							privacy: [],
							criticality: [],
							categories: []
						}
					} else {
						this.list.applications = apps;
					}
					this.setDataRows(data)
				}),
				finalize(() => this.setLoading(false)))
			.subscribe());
	}

	private setDataRows(data: DataTableData[]): void {
		this.dataRows = data;
		this.dataRows.sort(this.sortData);
		this.setDataListByFilter();
	}

	private fetchFilterPanelData(): void {
		this.subscription.add(this.applicationCategoryService.getAllApplicationCategoryByTenantId(this.tenantId)
			.pipe(
				tap((categories) => this.buildFilterPanel(categories))
			).subscribe());
	}

	private buildFilterPanel(categories: Category[]): void {
		this.dataCategoryList = categories;
		if (!this.list) {
			this.list = {
				type: Object.values(DataType),
				applications: [],
				privacy: Object.values(DataPrivacy),
				criticality: Object.values(CriticalityLevel),
				categories: categories
			}
		} else {
			this.list.type = Object.values(DataType);
			this.list.criticality = Object.values(CriticalityLevel);
			this.list.privacy = Object.values(DataPrivacy);
			this.list.categories = categories;
		}
	}

	searchApplicationFilter(search?: string): void {
		this.list.applications = this.dataRows
			.map(data => data.applications)
			.flat()
			.filter((application, index, self) => self.findIndex(a => a.id === application.id) === index)
			.filter(application => application.name.toLowerCase().includes(search?.toLowerCase() || ''));
	}

	searchCategoryFilter(search?: string): void {
		this.list.categories =  this.dataCategoryList.filter(category => category.name.toLowerCase().includes(search?.toLowerCase() || ''));
	}

	setDataListByFilter(): void {
		const form: DataTableFilterForm = this.buildDataTableFilterForm();
		this.displayedDataRows = this.dataRows
			.filter(data => {
				return (form.type?.length === 0 || data.data.types && data.data.types.some(type => form.type?.includes(type)))
					&& (form.applications?.length === 0 || data.applications.some(application => form.applications?.map(app => app.id).includes(application.id)))
					&& (form.categories?.length === 0 || data.data.category && form.categories?.map(cat => cat.categoryId).includes(data.data.category.categoryId))
					&& (form.criticality?.length === 0 || data.data.criticality && form.criticality?.includes(data.data.criticality))
					&& (form.privacy?.length === 0 || data.data.privacy && form.privacy?.toString().includes(data.data.privacy))
					&& (!form.name || data.data.name.toLowerCase().includes(form.name.toLowerCase()));
			})
			.sort(this.sortData);
	}

	private buildDataTableFilterForm(): DataTableFilterForm {
		return {
			name: this.filterForm.get(Form.name)!.value?.trim().length > 0
				? this.filterForm.get(Form.name)!.value : null,
			type: this.filterForm.get(Form.type)!.value as DataType[],
			criticality: this.filterForm.get(Form.criticality)!.value as CriticalityLevel[],
			privacy: this.filterForm.get(Form.privacy)!.value as DataPrivacy[],
			categories: this.filterForm.get(Form.category)!.value as Category[],
			applications: this.filterForm.get(Form.application)!.value as ApplicationGeneric[],
			sortDirection: this.sortFormValue.direction,
			sortColumn: this.sortFormValue.column,
		}
	}

	private setActiveFilters(): void {
		this.activeFilters = [
			...(this.filterForm.get(Form.type)!.value as DataType[])
				.map(type => ({form: Form.type, id: type, value: type})),
			...(this.filterForm.get(Form.criticality)!.value as CriticalityLevel[])
				.map(criticality => ({form: Form.criticality, id: criticality, value: criticality})),
			...(this.filterForm.get(Form.privacy)!.value as DataPrivacy[])
				.map(privacy => ({form: Form.privacy, id: privacy, value: privacy})),
			...(this.filterForm.get(Form.application)!.value as ApplicationGeneric[])
				.map(application => ({form: Form.application, id: application.id, value: application.name})),
			...(this.filterForm.get(Form.category)!.value as Category[])
				.map(category => ({form: Form.category, id: category.categoryId, value: category.name}))
		];
		if (this.activeFilters.length <= 0) {
			this.openFilters = false;
		}
	}

	isRightSliderOpened(): boolean {
		return this.rightSliderService.isOpened();
	}

	removeFilter(filter: DataFilter): void {
		switch (filter.form) {
			case Form.type:
				const typeList: DataType[] = (this.filterForm.get(Form.type)!.value as DataType[])
					.filter(type => type !== filter.id);
				this.filterForm.get(Form.type)!.setValue(typeList);
				break;
			case Form.criticality:
				const criticalityList: CriticalityLevel[] = (this.filterForm.get(Form.criticality)!.value as CriticalityLevel[])
					.filter(criticality => criticality !== filter.id);
				this.filterForm.get(Form.criticality)!.setValue(criticalityList);
				break;
			case Form.privacy:
				const privacyList: DataPrivacy[] = (this.filterForm.get(Form.privacy)!.value as DataPrivacy[])
					.filter(privacy => privacy !== filter.id);
				this.filterForm.get(Form.privacy)!.setValue(privacyList);
				break;
			case Form.application:
				const applicationList: ApplicationGeneric[] = (this.filterForm.get(Form.application)!.value as ApplicationGeneric[])
					.filter(application => application.id !== filter.id);
				this.filterForm.get(Form.application)!.setValue(applicationList);
				break;
			case Form.category:
				const categoryList: Category[] = (this.filterForm.get(Form.category)!.value as Category[])
					.filter(category => category.categoryId !== filter.id);
				this.filterForm.get(Form.category)!.setValue(categoryList);
				break;
			default:
				break;
		}
	}

	openCreateData(): void {
		this.dialog.open(CreateDataComponent)
			.afterClosed()
			.pipe(
				filter((result: boolean) => result),
				tap(() => this.snackbarService.show(this.translate.instant('page.application.detail.update.success'))),
				switchMap(() => this.fetchAllData(false)),
				tap(() => this.clearFilters())
			).subscribe();
	}

	openDataDrawer(dataId: string): void {
		const data: DataDetailInput = {dataId};
		this.resetQueryParamsObservable()
			.pipe(
				switchMap(() => this.rightSliderService.openComponent(DataDetailComponent, data)),
				switchMap(() => this.resetQueryParamsObservable()),
				tap(() => this.initialize(false)),
			).subscribe();
	}

	resetQueryParamsObservable(): Observable<boolean> {
		return from(
			this.router.navigate([], {
				queryParams: {
					'selectedDataTab': null,
					'settings': null,
					'dataId': null
				},
				queryParamsHandling: 'merge'
			})
		);
	}

	fetchAllData(loading = true): Observable<{}> {
		return this.setLoading(loading).pipe(
			switchMap(form => this.dataService.getTenantDataTableData(this.tenantId)),
			tap(data => this.setDataRows(data)),
			switchMap(() => this.setLoading(false)));
	}

	sortBy(column: DataSortColumn): void {
		const sort: DataSort = {
			column: column,
			direction: this.sortFormValue.column === column && this.sortFormValue.direction === SortDirection.ASC
				? SortDirection.DESC
				: SortDirection.ASC
		}
		if (!this._loading) {
			this.filterForm.get(Form.sort)!.setValue(sort, {emitEvent: false});
			this.displayedDataRows = this.displayedDataRows.sort(this.sortData);
		} else {
			this.filterForm.get(Form.sort)!.setValue(sort);
		}
	}

	exportCsv = () => this.export(ExportType.CSV);
	exportXlsx = () => this.export(ExportType.XLSX);

	private export(type: ExportType): void {
		this.switchExporting()
			.pipe(
				map(() => this.buildDataExportData(this.displayedDataRows)),
				tap(data => this.exportService.export(type, data, 'data')),
				finalize(() => this.switchExporting()))
			.subscribe();
	}

	private buildDataExportData(data: DataTableData[]): DataExportCsv[] {
		return data.map((singleData: DataTableData) => ({
			name: singleData.data.name,
			description: singleData.data.description || '',
			types: singleData.data.types.map(type => this.translate.instant('page.data.typesValues.' + type.toLowerCase())).join(', '),
			criticality: singleData.data.criticality ? this.translate.instant('page.appDetails.businessCriticality.' + singleData.data.criticality.toLowerCase()) : '',
			privacy: singleData.data.privacy ? this.translate.instant('page.data.privacyValues.' + singleData.data.privacy.toLowerCase()) : '',
			applications: singleData.applications.map(app => app.name).join(', '),
			lastUpdate: singleData.data.updatedAt ? new Date(singleData.data.updatedAt).toLocaleDateString() : ''
		}));
	}

	private switchExporting(): Observable<{}> {
		this._exporting = !this._exporting;
		return of({});
	}

	private sortData = (data1: DataTableData, data2: DataTableData): number => {
		const direction: number = this.sortFormValue.direction === SortDirection.ASC ? 1 : -1;
		switch (this.sortFormValue.column) {
			case DataSortColumn.NAME:
				return data1.data.name.localeCompare(data2.data.name) * direction;
			case DataSortColumn.CRITICALITY:
				const order: (CriticalityLevel | null | undefined)[] = [CriticalityLevel.HIGH, CriticalityLevel.MEDIUM, CriticalityLevel.LOW, null, undefined];
				return (order.indexOf(data2.data.criticality) - order.indexOf(data1.data.criticality)) * direction;
			case DataSortColumn.PRIVACY:
				const orderPrivacy: (DataPrivacy | null | undefined)[] = [DataPrivacy.TOP_SECRET, DataPrivacy.SECRET, DataPrivacy.CONFIDENTIAL, DataPrivacy.INTERNAL, DataPrivacy.PUBLIC, null, undefined];
				return (orderPrivacy.indexOf(data2.data.privacy) - orderPrivacy.indexOf(data1.data.privacy)) * direction;
			case DataSortColumn.TYPE:
				return data1.data.types.join(', ').localeCompare(data2.data.types.join(', ')) * direction;
			case DataSortColumn.APPLICATIONS:
				return data1.applications.map(app => app.name).join(', ').localeCompare(data2.applications.map(app => app.name).join(', ')) * direction;
			case DataSortColumn.LAST_UPDATE:
				const date1 = data1.data.updatedAt ? new Date(data1.data.updatedAt) : new Date(0);
				const date2 = data2.data.updatedAt ? new Date(data2.data.updatedAt) : new Date(0);
				return (date2.getTime() - date1.getTime()) * direction;
			default:
				return 0;
		}
	}

	get sortFormValue(): DataSort {
		return this.filterForm.get(Form.sort)!.value;
	}

	clearFilters(emitEvent: boolean = true): void {
		this.openSearch = false;
		this.openFilters = false;
		this.filterForm.setValue({
			[Form.name]: '',
			[Form.type]: [],
			[Form.criticality]: [],
			[Form.privacy]: [],
			[Form.application]: [],
			[Form.searchApplication]: '',
			[Form.category]: [],
			[Form.searchCategory]: '',
			[Form.sort]: this.sortFormValue
		}, {emitEvent: emitEvent});
	}

	clearInput(): void {
		this.filterForm.get(Form.name)!.setValue('');
	}

	openSearchPanel(input: HTMLInputElement): void {
		this.openSearch = !this.openSearch;
		if (this.openSearch) {
			input.focus();
		} else {
			this.filterForm.get(Form.name)!.setValue('');
		}
	}

	get searchValue(): string {
		return this.filterForm.get(Form.name)!.value;
	}

	private setLoading(loading: boolean): Observable<{}> {
		this._loading = loading;
		return of({});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}

interface DataTableFilterForm {
	name?: string | null;
	type?: string[];
	criticality?: CriticalityLevel[];
	applications?: ApplicationGeneric[];
	privacy?: DataPrivacy[];
	categories?: Category[];
	sortDirection?: string;
	sortColumn?: string;
	limit?: number;
	offset?: number;
}

enum Form {
	name = 'name',
	type = 'type',
	criticality = 'criticality',
	privacy = 'privacy',
	application = 'application',
	searchApplication = 'searchApplication',
	category = 'category',
	searchCategory = 'searchCategory',
	sort = 'sort',
}

enum SortDirection {
	ASC = 'asc',
	DESC = 'desc'
}

interface DataFilter {
	form: Form;
	id: string;
	value: string;
}

interface DataSort {
	column: DataSortColumn;
	direction: SortDirection;
}

enum DataSortColumn {
	NAME = 'name',
	CRITICALITY = 'criticality',
	PRIVACY = 'privacy',
	TYPE = 'type',
	APPLICATIONS = 'applications',
	LAST_UPDATE = 'last_update',
}

interface DataExportCsv {
	name: string;
	description: string;
	types: string;
	criticality: string;
	privacy: string;
	applications: string;
	lastUpdate: string;
}
