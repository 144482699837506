import {Component, OnDestroy, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ServerNetworkComponent} from "./server-network/server-network.component";
import {Server} from "../../../../../../services/model/server.model";
import {finalize, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {ServerDetailData, ServerDetailService} from "../../../../../../services/front/server-detail.service";
import {ServerService} from "../../../../../../services/back/server.service";
import {DeckContentLoaderModule} from "../../../../../global/title-content-loader/deck-content-loader.module";
import {TranslateModule} from "@ngx-translate/core";
import {InfrastructureSchemaComponent} from "../../../infrastructure-schema/infrastructure-schema.component";
import {
	InfrastructureSchema,
	InfrastructureSchemaService
} from "../../../../../../services/back/infrastructure-schema.service";

@Component({
  selector: 'app-server-architecture-tab',
  standalone: true,
	imports: [CommonModule, ServerNetworkComponent, DeckContentLoaderModule, InfrastructureSchemaComponent, TranslateModule],
  templateUrl: './server-architecture-tab.component.html',
  styleUrl: './server-architecture-tab.component.scss'
})
export class ServerArchitectureTabComponent implements OnInit, OnDestroy{
	tenantId: string;
	isEditor: boolean;
	server: Server;
	isTenantInactivePlan: boolean;

	_initializing: boolean;
	_loading: boolean;

	initialized: boolean = false;

	schema: InfrastructureSchema|null = null;

	subscription: Subscription = new Subscription();

	constructor(private serverDetailService: ServerDetailService,
				private infrastructureSchemaService: InfrastructureSchemaService,
				private serverService: ServerService) {
	}

	ngOnInit() {
		this.subscription.add(this.serverDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.serverDetailService.getServerDetailDataChanges()
			.pipe(tap(data => this.setServerDetailData(data)))
			.subscribe(() => this.initialize()));
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				switchMap(() => this.infrastructureSchemaService.getServerSchema(this.tenantId, this.server.id)),
				tap((data) => this.schema = data),
				finalize(() => this.switchLoading())
			).subscribe(() => this.initialized = true));
	}

	switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	private setServerDetailData(data: ServerDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.server = data.server;
		this.isTenantInactivePlan = data.isInactivePlan;
	}
}
