import {ApplicationGeneric} from 'src/app/services/model/new-application.model';
import {TenantAccount} from 'src/app/services/model/account.model';
import {Server} from "./server.model";

export interface ApplicationActivityLog {
	application: ApplicationGeneric,
	user: TenantAccount|null,
	actionType: LogActionType,
	fieldType: ApplicationLogFieldType,
	creationDate: string
}

export interface ServerActivityLog {
	server: Server,
	user: TenantAccount|null,
	actionType: LogActionType,
	fieldType: ApplicationLogFieldType,
	creationDate: string
}

export interface AllActivityLog {
	applicationLogs: ApplicationActivityLog[],
	serverLogs: ServerActivityLog[]
}

export const sortApplicationActivityLogs = (a: ApplicationActivityLog, b: ApplicationActivityLog) => {
	if (a.creationDate !== b.creationDate) {
		return new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
	} else if (a.application.name !== b.application.name) {
		return a.application.name.localeCompare(b.application.name)
	} else {
		return a.actionType.localeCompare(b.actionType) * -1
	}
}

export const sortServerActivityLogs = (a: ServerActivityLog, b: ServerActivityLog) => {
	if (a.creationDate !== b.creationDate) {
		return new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
	} else if (a.server.name !== b.server.name) {
		return a.server.name.localeCompare(b.server.name)
	} else {
		return a.actionType.localeCompare(b.actionType) * -1
	}
}

export enum LogActionType {
	CREATE = 'create',
	UPDATE = 'update',
	DELETE = 'delete'
}

export enum ApplicationLogFieldType {
	COMMENT = 'application-comments',
	BUSINESS_SERVICE = 'business-service',
	CATEGORY = 'categories',
	FINANCE = 'finance',
	HTTP_CHECK = 'http-check',
	INSTANCE = 'instance',
	LIFE_CYCLE = 'life-cycle',
	LINKED_CONTENT = 'linked-content',
	RESOURCE_MAPPING = 'resource-mapping',
	RESPONSIBLE = 'responsible',
	APPLICATION_MAPPING = 'application-mapping',
	TAGS = 'tags',
	TECHNOLOGY = 'technology',
}

export enum ServerLogFieldType {
	GENERAL = 'general',
}
