import {Component, DestroyRef, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {SnackbarService} from "../../../../../../../services/front/snackbar.service";
import {ApplicationInstance} from "../../../../../../../services/model/new-application.model";
import {FormControl} from "@angular/forms";
import {Subscription, tap} from "rxjs";
import {ChipsModule} from "../../../../../../global/chips/chips/chips.module";
import {DeckContentLoaderModule} from "../../../../../../global/title-content-loader/deck-content-loader.module";
import {DesignSystemModule} from "../../../../../../design-system/design-system.module";
import {MatCheckbox, MatCheckboxChange} from "@angular/material/checkbox";
import {CommonModule} from "@angular/common";
import {SnackbarModule} from "../../../../../../global/snackbar/snackbar.module";

@Component({
  selector: 'app-add-apps-to-data',
	imports: [
		ChipsModule,
		DeckContentLoaderModule,
		DesignSystemModule,
		MatCheckbox,
		CommonModule,
		SnackbarModule,
		TranslateModule
	],
  templateUrl: './add-apps-to-data.component.html',
  styleUrl: './add-apps-to-data.component.scss'
})
export class AddAppsToDataComponent {
	constructor(public dialog: MatDialogRef<AddAppsToDataComponent>,
				@Inject(MAT_DIALOG_DATA) public data: { tenantApplications: ApplicationInstance[], dataApplications: ApplicationInstance[] },
				protected translate: TranslateService,
				protected destroyRef: DestroyRef,
				protected snackbarService: SnackbarService) {
	}

	searchInput: FormControl;

	filteredApplications: ApplicationInstance[] = [];

	selectedApplications: ApplicationInstance[] = [];

	subscription: Subscription = new Subscription();

	ngOnInit() {
		this.searchInput = new FormControl('');

		this.filteredApplications = this.data.tenantApplications;
		this.selectedApplications = [...this.data.dataApplications];

		this.subscription.add(this.searchInput.valueChanges
			.pipe(
				tap((value: string) => this.filterApplications(value))
			).subscribe());
	}

	filterApplications(value: string) {
		this.filteredApplications = this.data.tenantApplications.filter(app => app.name.toLowerCase().includes(value.toLowerCase()));
	}

	onAppSelectChange(app: ApplicationInstance, event: MatCheckboxChange) {
		if (event.checked) {
			this.selectedApplications.push(app);
		} else {
			this.selectedApplications = this.selectedApplications.filter(selectedApp => selectedApp.applicationId !== app.applicationId);
		}
	}

	onConfirm() {
		this.dialog.close({apps: this.selectedApplications, result: true});
	}

	onDismiss() {
		this.dialog.close({apps: [], result: false});
	}

	isAppSelected(app: ApplicationInstance): boolean {
		return this.data.dataApplications.some(dataApp => dataApp.applicationId === app.applicationId);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
