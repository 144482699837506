import {Component, DestroyRef, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl} from "@angular/forms";
import {debounceTime, filter, mergeMap} from "rxjs/operators";
import {distinctUntilChanged, finalize, from, merge, Subscription, tap} from "rxjs";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {OrganizationService} from "src/app/services/organization.service";
import {ApplicationInstanceService} from "src/app/services/application-instance.service";
import {CurrentTenantService} from "src/app/services/front/current-tenant.service";
import {TenantService} from "src/app/services/tenant.service";
import {SnackbarService} from "src/app/services/front/snackbar.service";
import {CommonModule} from "@angular/common";
import {SnackbarModule} from "src/app/modules/global/snackbar/snackbar.module";
import {ChipsModule} from "src/app/modules/global/chips/chips/chips.module";
import {ApplicationIconModule} from "src/app/modules/global/application-icon/application-icon.module";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {DeckContentLoaderModule} from "src/app/modules/global/title-content-loader/deck-content-loader.module";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';
import {AddAppsToServerDialogData} from "../server-applications.component";
import {ServerService} from "../../../../../../../../services/back/server.service";

@Component({
	selector: 'app-add-apps-to-server',
	templateUrl: './add-apps-to-server.component.html',
	styleUrls: ['./add-apps-to-server.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		SnackbarModule,
		ChipsModule,
		TranslateModule,
		ApplicationIconModule,
		MatCheckboxModule,
		DeckContentLoaderModule,
		DesignSystemModule,
	]
})
export class AddAppsToServerComponent implements OnInit, OnDestroy {
	constructor(public dialog: MatDialogRef<AddAppsToServerComponent>,
				@Inject(MAT_DIALOG_DATA) public data: { serverId: string, apps: AddAppsToServerDialogData[] },
				protected organizationService: OrganizationService,
				protected applicationService: ApplicationInstanceService,
				protected currentTenantService: CurrentTenantService,
				protected tenantService: TenantService,
				protected translate: TranslateService,
				protected destroyRef: DestroyRef,
				protected serverService: ServerService,
				protected snackbarService: SnackbarService) {
	}

	searchInput: FormControl;

	searchLoading = false;

	updatedApps: AddAppsToServerDialogData[] = [];

	filteredApps: AddAppsToServerDialogData[] = [];

	saving = false;

	tenantId: string;

	subscriptions: Subscription = new Subscription();

	ngOnInit() {
		this.subscriptions.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(
				tap(tenantId => this.tenantId = tenantId),
				takeUntilDestroyed(this.destroyRef)
			).subscribe());

		this.filteredApps = this.data.apps;

		this.searchInput = new FormControl();
		if (this.data.apps.length > 0) {
			this.searchInput.valueChanges
				.pipe(
					tap(() => this.searchLoading = true),
					debounceTime(500),
					distinctUntilChanged(),
					tap(value => {
						return this.filteredApps = this.data.apps.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
					}),
					tap(() => this.searchLoading = false)
				)
				.subscribe()
		}
	}

	onConfirm() {
		if (this.updatedApps.length > 0) {
			from(this.updatedApps)
				.pipe(
					tap(() => this.saving = true),
					mergeMap((updatedApp) => {
						if (updatedApp.selected) {
							return this.serverService.removeApplicationServer(this.tenantId, this.data.serverId, updatedApp.id)
						} else {
							return this.serverService.createApplicationServer(this.tenantId, this.data.serverId, updatedApp.id)
						}
					}),
					finalize(() => this.saving = false)
				).subscribe({
				error: () => {
					this.dialog.close({success: false});
				},
				complete: () => {
					this.dialog.close({success: true});
				}
			});
		} else {
			this.dialog.close({success: true});
		}
	}

	onDismiss() {
		this.dialog.close({apps: []});
	}

	onAppSelectChange(application: AddAppsToServerDialogData, event: any) {
		const index = this.updatedApps.findIndex(item => item.id === application.id);
		if (index > -1) {
			this.updatedApps[index].selected = event.checked;
		} else {
			this.updatedApps.push(application);
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	protected readonly filter = filter;
}
