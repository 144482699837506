<div class="pl-l pr-l pt-l pb-l primary">
	<div class="disp-f a-i-c j-c-s f-d-r mb-m">
		<app-chips [square]="true" [size]="'xl'">
			<img src="assets/icons/grid.svg" class="filter-grey" height="25px">
		</app-chips>
		<div class="flex-column gap-XS ml-s">
			<div class="font-L fw-700">{{ 'page.data.linkAnApp' | translate }}</div>
			<div class="font-M fw-300">{{ 'page.data.linkAnAppSubtitle' | translate }}</div>
		</div>
	</div>

	<div class="mb-m">
		<app-text-input [placeholder]="'page.applicationCatalog.searchPlaceholder' | translate"
						[input]="searchInput"></app-text-input>
	</div>

	<div class="bg-rounded" style="max-height: calc(80vh - 300px); min-height: calc(80vh - 300px); overflow: auto">
		<div>
			<div *ngFor="let app of filteredApplications; let index = index">
				<div class="pl-m pr-m pt-m pb-m disp-f a-i-c j-c-sb">
					<div class="disp-f a-i-c j-c-c gap-S overflow-hidden">
						<app-display-logo [logo]="app.logo" [size]="38"></app-display-logo>
						<span class="font-M fw-700 text-overflow-ellipsis">{{ app.name }}</span>
					</div>
					<div>
						<mat-checkbox [checked]="isAppSelected(app)" (change)="onAppSelectChange(app, $event)"></mat-checkbox>
					</div>
				</div>
				<hr *ngIf="index < filteredApplications.length - 1">
			</div>
			<div *ngIf="filteredApplications.length === 0" style="height: calc(80vh - 300px);">
				<div class="disp-f a-i-c j-c-c h-100">
					<span class="font-M fw-500 grey">{{ 'page.usageDashboard.noApps' | translate }}</span>
				</div>
			</div>
		</div>
	</div>

	<div class="w-100 disp-f f-d-r j-c-sb a-i-c mt-l gap-M">
		<app-text-button class="w-50" type="stroked" (click)="onDismiss()">{{ 'button.cancel' | translate }}
		</app-text-button>
		<app-text-button class="w-50" type="flat" (click)="onConfirm()">{{ 'button.save' | translate }}
		</app-text-button>
	</div>
</div>
