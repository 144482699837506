<div class="flex-center h-100">
	<div class="flex-justify-center flex-align-center onboarding-step-container h-100 w-100">
		<div class="flex-justify-center gap-M h-100 w-100 h-auto m-auto">
			<div *ngIf="!isMobile" class="onboarding-stepper-container flex-column gap-M mt-l">
				<div class="onboarding-stepper"></div>
				<div class="onboarding-stepper"></div>
				<div class="onboarding-stepper"></div>
				<div class="onboarding-stepper"></div>
				<div class="onboarding-stepper active-step"></div>
				<div *ngIf="stepCount >= 6" class="onboarding-stepper"></div>
			</div>
			<div class="onboarding-step flex-column flex-space-between w-30 gap-L border-box pad-L">
				<div class="h-100">
					<div class="flex-justify-end mb-l">
						<app-icon-button (click)="closeLoading = true; onDismiss.emit(undefined)" img='assets/icons/close.svg' size='s' color='accent' type='stroked'
										 shape='default' [disabled]='false' [loading]='closeLoading' [focused]='false'
										 [background]='true' panelClass='img-size-30'></app-icon-button>
					</div>
					<div class="flex-center mb-xxl" [class.mt-xxl]="panelClass === 'big-button'">
						<img src="assets/onboarding/illustration-3.svg" class="onboarding-illustration" alt="ill3">
					</div>
					<div class="font-XXL text-center mb-xxl onboarding-title">
						<span class="fw-700">{{ 'page.onboarding.step5.st1' | translate }}</span>
					</div>
					<div class="flex-justify-center text-center font-L fw-300" [style.line-height.px]="panelClass === 'big-button' ? 27 : 23">
						<div class="onboarding-subtitle">
							<span class="fw-700">{{ 'page.onboarding.step5.st2' | translate }}</span>{{ 'page.onboarding.step5.st3' | translate }}<span class="fw-700">{{ 'page.onboarding.step5.st4' | translate }}</span>{{ 'page.onboarding.step5.st5' | translate }}
						</div>
					</div>
				</div>
				<div class="flex-center w-100 pb-l">
					<app-text-button (click)="onNextClick.emit('Step 6')" class="w-90" color='accent' type='flat'
									 shape='default' [disabled]='false' [loading]='false' badge=''
									 badgePosition='above after' [panelClass]="panelClass">{{ 'page.onboarding.step5.st6' | translate }}
					</app-text-button>
				</div>
			</div>
		</div>
	</div>
</div>
