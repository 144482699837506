<div class="flex-column gap-widget">
	<div class="bg-rounded pad-M-M-0" style="padding-bottom: 5px">
		<app-data-information-form *ngIf="initialized" [data]="data"></app-data-information-form>
		<app-dashboard-page-loader *ngIf="!initialized"></app-dashboard-page-loader>
	</div>
	<div class="bg-rounded">
		<app-data-responsible></app-data-responsible>
	</div>
	<div class="bg-rounded">
		<app-data-description></app-data-description>
	</div>
	<div class="bg-rounded">
		<app-data-applications></app-data-applications>
	</div>
</div>
