import {Component, DestroyRef, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Data} from "../../../../../../../services/back/data.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {SnackbarService} from "../../../../../../../services/front/snackbar.service";
import {FormControl} from "@angular/forms";
import {Subscription, tap} from "rxjs";
import {MatCheckbox, MatCheckboxChange} from "@angular/material/checkbox";
import {ChipsModule} from "../../../../../../global/chips/chips/chips.module";
import {DesignSystemModule} from "../../../../../../design-system/design-system.module";
import {NgClass, NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'app-add-data-to-app',
	imports: [
		ChipsModule,
		DesignSystemModule,
		MatCheckbox,
		NgForOf,
		NgIf,
		TranslateModule,
		NgClass
	],
  templateUrl: './add-data-to-app.component.html',
  styleUrl: './add-data-to-app.component.scss'
})
export class AddDataToAppComponent {
	constructor(public dialog: MatDialogRef<AddDataToAppComponent>,
				@Inject(MAT_DIALOG_DATA) public data: { tenantData: Data[], applicationData: Data[] },
				protected translate: TranslateService,
				protected destroyRef: DestroyRef,
				protected snackbarService: SnackbarService) {
	}

	searchInput: FormControl;

	filteredData: Data[] = [];
	selectedData: Data[] = [];

	subscription: Subscription = new Subscription();

	ngOnInit() {
		this.searchInput = new FormControl('');

		this.filteredData = this.data.tenantData;
		this.selectedData = [...this.data.applicationData];

		this.subscription.add(this.searchInput.valueChanges
			.pipe(
				tap((value: string) => this.filterData(value))
			).subscribe());
	}

	filterData(value: string) {
		this.filteredData = this.data.tenantData.filter(data => data.name.toLowerCase().includes(value.toLowerCase()));
	}

	onDataSelectChange(data: Data, event: MatCheckboxChange) {
		if (event.checked) {
			this.selectedData.push(data);
		} else {
			this.selectedData = this.selectedData.filter(selectedData => selectedData.id !== data.id);
		}
	}

	onConfirm() {
		this.dialog.close({data: this.selectedData, result: true});
	}

	onDismiss() {
		this.dialog.close({data: [], result: false});
	}

	isDataSelected(data: Data): boolean {
		return this.data.applicationData.some(appData => appData.id === data.id);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
