import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatRippleModule} from "@angular/material/core";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {finalize, forkJoin, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {filter, map} from "rxjs/operators";
import {CurrentTenantService} from "../../../../services/front/current-tenant.service";
import {SnackbarService} from "../../../../services/front/snackbar.service";
import {NewApplicationService} from "../../../../services/back/new-application.service";
import {MatDialog} from "@angular/material/dialog";
import {DesignSystemModule} from "../../../design-system/design-system.module";
import {ApplicationGeneric} from "../../../../services/model/new-application.model";
import {
	ApplicationFlowFormDialogComponent
} from "../../applications/application-detail/application-data-tab/application-flow-form/dialog/application-flow-form-dialog.component";
import {
	ApplicationFlowFormData
} from "../../applications/application-detail/application-data-tab/application-flow-form/application-flow-form.component";
import {Flow} from "../../../../services/back/flow.service";
import {Data, DataService} from "../../../../services/back/data.service";

@Component({
  selector: 'app-diagram-tooltip',
  standalone: true,
	imports: [CommonModule, MatRippleModule, TranslateModule, DesignSystemModule],
  templateUrl: './diagram-tooltip.component.html',
  styleUrl: './diagram-tooltip.component.scss',
})
export class DiagramTooltipComponent implements OnInit {
	constructor(private currentTenantService: CurrentTenantService,
				private snackBar: SnackbarService,
				private applicationService: NewApplicationService,
				private dataService: DataService,
				private dialog: MatDialog,
				private translate: TranslateService) {
	}

	@Input() positionTop: number = 0;
	@Input() positionLeft: number = 0;
	@Input() data: Flow;

	@Output() updated: EventEmitter<void> = new EventEmitter<void>();

	tenantId: string;

	_loadingDialog: boolean;

	formData?: {
		applicationList: ApplicationGeneric[],
		dataList: Data[]
	};

	subscription: Subscription = new Subscription();

	ngOnChanges(changes: SimpleChanges) {
		if (changes.postionTop) {
			this.positionTop = changes.positionTop.currentValue;
		}

		if (changes.positionLeft) {
			this.positionLeft = changes.positionLeft.currentValue;
		}
	}

	ngOnInit() {
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges().subscribe(tenantId => this.tenantId = tenantId));
	}

	openFlowFormDialog(defaultData: Flow): void {
		this.switchLoadingDialog()
			.pipe(
				switchMap(() => this.getFlowFormData()),
				map(formData => this.buildFlowFormData(formData, defaultData)),
				switchMap(data => this.dialog.open(ApplicationFlowFormDialogComponent, { width: '475px', data: data }).afterClosed()),
				filter(updated => !!updated),
				tap(() => this.snackBar.show(this.translate.instant('page.application.detail.update.success'))),
				tap(() => this.updated.emit()),
				finalize(() => this.switchLoadingDialog()))
			.subscribe();
	}

	private switchLoadingDialog(): Observable<{}> {
		this._loadingDialog = !this._loadingDialog;
		return of({});
	}

	private getFlowFormData(): Observable<{ applicationList: ApplicationGeneric[], dataList: Data[] }> {
		return of(this.formData).pipe(
			switchMap(formData => !formData
				? forkJoin([
					this.applicationService.getAllApplication(this.tenantId),
					this.dataService.getAllTenantData(this.tenantId)])
					.pipe(map(([applicationList, dataList]) => ({ applicationList, dataList })))
				: of(formData)),
			tap(formData => this.formData = formData));
	}

	private buildFlowFormData(formData: { applicationList: ApplicationGeneric[], dataList: Data[] }, defaultData?: Flow): ApplicationFlowFormData {
		return {
			tenantId: this.tenantId,
			applicationId: this.data!.source.id,
			defaultData: defaultData,
			preloaded: {
				applicationList: formData.applicationList,
				dataList: formData.dataList
			}
		}
	}
}
