import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {finalize, forkJoin, Observable, of, Subscription, switchMap, tap, throwError} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {filter} from 'rxjs/operators';
import {ApplicationUrlService} from 'src/app/services/front/application-url.service';
import {SnackbarService} from 'src/app/services/front/snackbar.service';
import {ActivatedRoute} from '@angular/router';
import {NotificationService, NotificationType} from 'src/app/services/notification.service';
import {ApplicationDetailData, ApplicationDetailService} from 'src/app/services/front/application-detail.service';
import {ApplicationInstance, ApplicationTagListForm} from 'src/app/services/model/new-application.model';
import {CatalogTagService} from 'src/app/services/back/catalog-tag.service';
import {NewApplicationService} from 'src/app/services/back/new-application.service';
import {CatalogTag, CatalogTagForm, TagColor} from 'src/app/services/model/catalog-tag.model';
import {ApplicationCommentService} from 'src/app/services/back/application-comment.service';
import {UsageService} from "src/app/services/usage.service";
import {MatDialog} from "@angular/material/dialog";
import {AddUrlToAppComponent} from "./add-url-to-app/add-url-to-app.component";
import {DeleteDialogComponent} from "../../../design-system/delete-dialog/delete-dialog.component";
import {FormControl, FormGroup} from "@angular/forms";
import {QueryRangeType} from 'src/app/services/back/tenant-finance.service';

@Component({
	templateUrl: './application-detail.component.html',
	standalone: false,
	styleUrls: ['./application-detail.component.scss']
})
export class ApplicationDetailComponent implements OnInit, OnDestroy {

	@Input() data: ApplicationDetailInput;

	@Output() onClose = new EventEmitter<void>(); // Listening in home.component.ts

	@ViewChild('searchInput', {static: false}) public searchInput: any;

	tenantId: string;
	isEditor: boolean;
	application: ApplicationInstance;

	_initializing: boolean;
	_loading: boolean;
	_savingTag: boolean = false;
	initialized: boolean = false;

	sliderIndex: number = 1;
	lazyLoadedTabs: boolean[] = [false, false, false, false, false, false];

	applicationTags: CatalogTag[] = [];
	tenantTags: CatalogTag[] = [];
	tagsFilteredList: CatalogTag[] = [];

	selectedColor: TagColor = TagColor.SECONDARY;
	settings: boolean = false;
	comments: boolean = false;

	unreadCommentsNotifications: number = 0;
	commentsCount: number = 0;

	displayAutodiscoverTeamsNotification: boolean = false;
	autodiscoveredTeamsCount: number = 0;

	formGroup: FormGroup;

	searchTagControl: FormControl;


	subscriptions: Subscription = new Subscription();

	constructor(private applicationDetailService: ApplicationDetailService,
							private applicationUrlService: ApplicationUrlService,
							private notificationService: NotificationService,
							private newApplicationService: NewApplicationService,
							private applicationCommentService: ApplicationCommentService,
							private snackBarService: SnackbarService,
							private activatedRoute: ActivatedRoute,
							private translate: TranslateService,
							private catalogTagService: CatalogTagService,
							private dialog: MatDialog,
							private usageService: UsageService) {
	}

	ngOnInit(): void {
		this.formGroup = new FormGroup({
			[Form.tags]: new FormControl([], [])
		});

		this.sliderIndex = this.applicationUrlService.getCurrentApplicationTab();
		this.lazyLoadedTabs[this.sliderIndex] = true;

		this.applicationDetailService.initialize(this.data.applicationId);

		this.subscriptions.add(this.applicationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));

		this.subscriptions.add(this.applicationDetailService.getApplicationDetailDataChanges()
			.pipe(tap(data => this.setApplicationDetailData(data)), filter(() => !this.initialized))
			.subscribe(() => this.initialize()));

		this.subscriptions.add(this.activatedRoute.queryParams
			.subscribe(() => this.onQueryParamsChange()));
		this.subscriptions.add(this.notificationService.recheckNotificationsEvent
			.pipe(switchMap(() => this.fetchNotifications()))
			.subscribe());

		this.searchTagControl = new FormControl('');
		this.subscriptions.add(this.searchTagControl.valueChanges
			.subscribe(text => this.filterTagsList(text)));
	}

	initialize(): void {
		this.subscriptions.add(this.switchLoading()
			.pipe(
				switchMap(() => forkJoin([
					this.newApplicationService.getAllApplicationTag(this.tenantId, this.data.applicationId)
						.pipe(tap(data => {
							this.applicationTags = data
							this.formGroup.get(Form.tags)!.setValue(data);
						})),
					this.catalogTagService.getAllCatalogTagByTenantId(this.tenantId)
						.pipe(tap(data => this.setTenantTags(data))),
					this.usageService.getDiscoveredTeamCountByApplicationId(this.tenantId, this.data.applicationId, QueryRangeType.PAST_1_MONTH)
						.pipe(
							tap(count => this.autodiscoveredTeamsCount = count),
							tap(() => this.setAutoDiscoverNotificationDisplay(this.data.applicationId, true))
						),
					this.fetchNotifications()
				])),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.initialized = true));
	}

	private fetchNotifications(): Observable<{}> {
		return forkJoin([
			this.notificationService.getNotifications(this.tenantId)
				.pipe(tap((notifications) => this.unreadCommentsNotifications =
					notifications.notifications
						.filter(n => n.applicationId === this.application.applicationId && n.status === 'unread' && (n.type === NotificationType.COMMENT_ADDED || n.type === NotificationType.USER_TAGGED_IN_COMMENT)).length)),
			this.applicationCommentService.getApplicationComments(this.tenantId, this.data.applicationId)
				.pipe(tap((comments) => this.commentsCount = comments.length))
		]);
	}

	private setAutoDiscoverNotificationDisplay(applicationId: string, displayTooltip: boolean = false): void {
		const autodiscoveredTeamsCount = localStorage.getItem('autodiscovered-teams-count-' + applicationId);
		if (autodiscoveredTeamsCount) {
			const diff = this.autodiscoveredTeamsCount - parseInt(autodiscoveredTeamsCount, 10);
			this.displayAutodiscoverTeamsNotification = diff > 0;
		} else {
			this.displayAutodiscoverTeamsNotification = this.autodiscoveredTeamsCount > 0;
		}
	}

	setLocalStorageTeamsCount(): void {
		localStorage.setItem('autodiscovered-teams-count-' + this.application.applicationId, this.autodiscoveredTeamsCount.toString());
		this.setAutoDiscoverNotificationDisplay(this.application.applicationId);
	}

	private setApplicationDetailData(data: ApplicationDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.application = data.instance;

		if (!this.application.urlModalDismissed && !this.application.usageDomain) {
			this.dialog
				.open(AddUrlToAppComponent, {
					position: {
						right: '162.5px'
					},
					width: '475px',
					panelClass: 'add-url-to-app-modal-container',
					enterAnimationDuration: 0,
					exitAnimationDuration: 0,
				})
				.afterClosed()
				.pipe(
					filter(result => !!result),
					tap(() => this.snackBarService.show(this.translate.instant('page.appDetails.addUrl.success')))
				).subscribe(() => this.applicationDetailService.refreshApplicationInstance());
		}
	}

	private onQueryParamsChange(): void {
		this.settings = this.applicationUrlService.getParameter();
		this.comments = this.applicationUrlService.getCommentParameter();
	}

	onSliderChange(index: number): void {
		this.sliderIndex = index;
		this.lazyLoadedTabs[index] = true;
		this.applicationUrlService.changeCurrentApplicationTab(index);
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	close(): void {
		this.onClose.emit();
	}

	private setTenantTags(tenantTags: CatalogTag[]): void {
		this.tenantTags = tenantTags;
		this.tagsFilteredList = tenantTags;
	}


	createTag(value: string): void {
		if (value && value.trim().length > 0 && this.isNotExistingTag(this.tenantTags, value.trim())) {
			this._savingTag = true;
			const form: CatalogTagForm = {
				name: value.trim(),
				color: this.selectedColor
			}
			this.catalogTagService.createCatalogTagForAll(this.tenantId, form).subscribe({
				next: (tagId) => {
					const newTag: CatalogTag = {tagId: tagId, name: value.trim(), color: this.selectedColor, tenantId: this.tenantId};
					this.tenantTags.push(newTag);
					this.applicationTags.push(newTag);
					this.tagsFilteredList = this.tenantTags;
					this.doUpdateTags();
				},
				error: () => {
					this._savingTag = false;
					this.snackBarService.show(this.translate.instant('page.application.detail.createTag.fail'), 'danger-snack')
				}
			});
		} else {
			this.snackBarService.show(this.translate.instant('page.application.detail.createTag.fail'), 'danger-snack');
		}
	}

	confirmUpdateTags(): void {
		this.applicationTags = this.formGroup.get(Form.tags)!.value;
		this.doUpdateTags();
	}

	isNotExistingTag(tenantTags: CatalogTag[], value: string): boolean {
		return tenantTags.length < 1 || !tenantTags.find(tag => tag.name.toUpperCase() == value.toUpperCase());
	}

	/*updateTag(tagChangeEvent: SelectionEvent): void {
		if (tagChangeEvent.type === 'selection-change-event' && tagChangeEvent.getChange().isTagSelect()) {
			const tagSelected = tagChangeEvent.getChange().asTagSelect();
			const newTag: CatalogTag = {tagId: tagSelected.id, name: tagSelected.content, color: TagColor.SECONDARY, tenantId: this.tenantId};

			if (this.applicationTags.findIndex(tag => tag.tagId === newTag.tagId) < 0) {

				// Update UI list
				if (this.applicationTags) {
					if (this.applicationTags.findIndex(tag => tag.tagId === newTag.tagId) < 0) {
						this.applicationTags.push(newTag);
					}
				} else {
					this.applicationTags = [newTag];
				}
				this.doUpdateTags();
			}
		}
	}*/

	onRemoveTag(appTag: CatalogTag): void {
		this.applicationTags.splice(this.applicationTags.findIndex(tag => tag.tagId === appTag.tagId), 1);
		this.doUpdateTags();
	}

	private doUpdateTags(): void {
		const form: ApplicationTagListForm = {
			tags: this.applicationTags.map(tag => ({tagId: tag.tagId}))
		};
		this.newApplicationService.updateApplicationTagList(this.tenantId, this.application.applicationId, form).subscribe({
			next: () => {
				this.formGroup.get(Form.tags)!.setValue(this.applicationTags);
				this._savingTag = false;
			},
			error: () => {
				this._savingTag = false;
				this.snackBarService.show(this.translate.instant('page.application.detail.update.fail'), 'danger-snack')
			}
		});
	}

	filterTagsList(text: string): void {
		this.tagsFilteredList = this.tenantTags.filter(tag => tag.name.toUpperCase().includes(text.toUpperCase()));
	}

	onOpenDeleteAppPopup(): void {
		this.dialog.open(DeleteDialogComponent, {
			width: '475px',
			position: {
				right: '162.5px'
			},
			data: {
				icon: '/assets/illustrations/delete/account.svg',
				title: this.translate.instant('page.appDetails.delete.title'),
				subtitle: this.translate.instant('page.appDetails.delete.subtitle', { app: this.application.name}),
				warningMessage: this.translate.instant('page.appDetails.delete.warningMessage'),
				deleteButton: this.translate.instant('page.appDetails.delete.deleteButton')
			}})
			.afterClosed()
			.pipe(
				filter(result => result)
			).subscribe(() => this.onDeleteApp());
	}

	onDeleteApp(): void {
		this.newApplicationService.deleteApplicationInstance(this.tenantId, this.application.applicationId).subscribe({
			next: () => this.close(),
			error: () => this.snackBarService.show(this.translate.instant('confirmModal.deleteFail'), 'danger-snack')
		});
	}

	onOpenAppSettings(): void {
		this.applicationUrlService.navigateInAndOutOfParameter(!this.settings);
	}

	onOpenAppComments(): void {
		this.applicationUrlService.navigateInAndOutOfComments(!this.comments);
	}

	onAutodiscoverTeamsChanged(event: number) {
		this.autodiscoveredTeamsCount = event;
	}

	onSaveSettings(): void {
		this.snackBarService.show(this.translate.instant('page.application.detail.update.success'));
		this.applicationUrlService.navigateInAndOutOfParameter(false);
	}

	get tagsFormControl(): FormControl {
		return this.formGroup.get(Form.tags) as FormControl;
	}

	ngOnDestroy(): void {
		this.applicationUrlService.changeCurrentApplication(null);
		this.applicationDetailService.clearApplicationDetailData();
		this.subscriptions.unsubscribe();
	}
}

export interface ApplicationDetailInput {
	applicationId: string
}

enum Form {
	tags = 'tags'
}
