import {ClickableObject, Criticality, DrawableObject, Point, Rectangle} from "../diagram";
import {Layers} from "../layer";
import {CanvasHandler} from "../canvas-handler";

type ApplicationState = { isDragging: boolean, position?: Point, cameraPosition?: Point};

const criticalityHighImages =  new Image();
criticalityHighImages.src = 'assets/icons/criticality-high.svg';

const criticalityHighDisabledImages =  new Image();
criticalityHighDisabledImages.src = 'assets/icons/criticality-high-disabled.svg';


const criticalityMediumImages  = new Image();
criticalityMediumImages.src = 'assets/icons/criticality-medium.svg';

const criticalityMediumDisabledImages  = new Image();
criticalityMediumDisabledImages.src = 'assets/icons/criticality-medium-disabled.svg';

const criticalityLowImages = new Image();
criticalityLowImages.src = 'assets/icons/criticality-low.svg';

const criticalityLowDisabledImages = new Image();
criticalityLowDisabledImages.src = 'assets/icons/criticality-low-disabled.svg';


const criticalityFlow = new Map<string, { normal: HTMLImageElement, disabled: HTMLImageElement}>(
	[
		['low', {normal:criticalityLowImages, disabled: criticalityLowDisabledImages}],
		['medium', {normal:criticalityMediumImages, disabled: criticalityMediumDisabledImages}],
		['high', {normal:criticalityHighImages, disabled: criticalityHighDisabledImages}]
	]
);

export class ApplicationCard implements DrawableObject<ApplicationState>, ClickableObject {
	public static readonly WIDTH: number = 310;
	public static readonly HEIGHT: number = 60;
	public static readonly ACTIVE_FONT_COLOR = '#0C2633';
	public static readonly INACTIVE_BACKGROUND_COLOR = '#F7F8FA';
	public static readonly INACTIVE_FONT_COLOR = '#DCDCE9';

	protected originalImageCopy: {highResolution: HTMLImageElement, mediumResolution: HTMLImageElement, lowResolution: HTMLImageElement} | undefined;
	protected grayImage: {highResolution: HTMLImageElement, mediumResolution: HTMLImageElement, lowResolution: HTMLImageElement} | undefined;
	protected isRightAnchorActive = false;
	protected isLeftAnchorActive = false;
	protected isActive = true;
	protected mouseOver = false;
	protected isDragging = false;

	constructor(
		protected ctx: any,
		public coordinates: Point = {x: 0, y: 0},
		protected applicationId: string,
		protected applicationLogo: string,
		public applicationName: string,
		protected applicationDescription?: string|null,
		protected applicationCriticality?: Criticality|null,
		protected anchorColor?: string,
		protected isSelected: boolean = false) {
		this.preloadImage();
	}

	getId(): string {
		return this.applicationId;
	}

	getBottomMiddlePosition(): Point {
		return { x: this.coordinates.x + (ApplicationCard.WIDTH / 2), y: this.coordinates.y + ApplicationCard.HEIGHT };
	}

	protected moveTo(coordinates: Point): void {
		this.coordinates = coordinates;
	}

	update(state: ApplicationState): void {
		this.isDragging = state.isDragging;

		if (state.position) {
			this.moveTo(state.position);
			this.isRightAnchorActive = false;
			this.isLeftAnchorActive = false;
		}
    }

	getRectangle(): Rectangle {
		return {
			topLeft: {
				x: this.coordinates.x,
				y: this.coordinates.y
			},
			bottomRight: {
				x: this.coordinates.x + ApplicationCard.WIDTH,
				y: this.coordinates.y + ApplicationCard.HEIGHT
			}
		};
	}

	getRightAnchor(): Point {
		return {
			x: this.coordinates.x + ApplicationCard.WIDTH,
			y: this.coordinates.y + ApplicationCard.HEIGHT / 2
		}
	}

	getLeftAnchor(): Point {
		return {
			x: this.coordinates.x,
			y: this.coordinates.y + ApplicationCard.HEIGHT / 2
		}
	}

	activateRightAnchor(): void {
		this.isRightAnchorActive = true;
	}

	activateLeftAnchor(): void {
		this.isLeftAnchorActive = true;
	}

	isAtCoordinates(mouseCoordinates: Point): boolean {
		return  mouseCoordinates.x >= this.coordinates.x && mouseCoordinates.x <= this.coordinates.x + ApplicationCard.WIDTH &&
			mouseCoordinates.y >= this.coordinates.y && mouseCoordinates.y <= this.coordinates.y + ApplicationCard.HEIGHT;
	}

	setMouseOver() {
		this.mouseOver = true;
	}

	unsetMouseOver() {
		this.mouseOver = false;
	}

	selected(): boolean {
		return this.isSelected;
	}

	select() : void {
		this.isSelected = true;
		this.isActive = true
	}

	unselect() : void {
		this.isSelected = false;
		this.isActive = true;
	}

	setUnactive() : void {
		this.isActive = false;
	}

	setActive() : void {
		this.isActive = true;
	}


	draw(layers: Layers): void {
		const rect = this.getRectangle()
		const canvasRect = this.ctx.canvas.getBoundingClientRect();
		const realCoordinates = CanvasHandler.canvasToWindowCoordinates(this.ctx, Math.max(2, window.devicePixelRatio), {x: rect.topLeft.x, y: rect.topLeft.y});
		if (realCoordinates.x < (canvasRect.width + canvasRect.x) && realCoordinates.y < (canvasRect.height + canvasRect.y)) {
			layers.getLayer(2).addObject(this.drawShadow());
			layers.getLayer(2).addObject(this.drawBackground());
			layers.getLayer(2).addObject(this.drawLogo());
			layers.getLayer(2).addObject(this.drawCriticality());
			layers.getLayer(2).addObject(this.drawSubtitle());
			layers.getLayer(2).addObject(this.drawTitle());
			layers.getLayer(5).addObject(this.drawAnchor());
		}
	}

	protected preloadImage() {
		if (this.applicationLogo) {
			const img = new Image();
			img.style.borderRadius = '50%';
			img.crossOrigin = "Anonymous";
			img.src = this.applicationLogo;
			img.onload = () => {
				// i need the image to have a border radius
				this.buildCopyImage(img);
				this.buildGrayImage(img);
				this.drawLogo();
			};
		}
	}

	protected getImageWithResolution(isGrey: boolean, canvas: HTMLCanvasElement, img: HTMLImageElement, ctx:any, resolution: number) {
		const width = img.width / resolution;
		const height = img.height / resolution;

		canvas.width = width;
		canvas.height = height;
		ctx.drawImage(img, 0, 0, width, height); // Or at whatever offset you like

		if (isGrey) {
			const imgPixels: ImageData = ctx.getImageData(0, 0, img.width, img.height);

			for(let y = 0; y < imgPixels.height; y++){
				for(let x = 0; x < imgPixels.width; x++){
					const i = (y * 4) * imgPixels.width + x * 4;
					const avg = (imgPixels.data[i] + imgPixels.data[i + 1] + imgPixels.data[i + 2]) / 3;
					imgPixels.data[i] = avg;
					imgPixels.data[i + 1] = avg;
					imgPixels.data[i + 2] = avg;
				}
			}

			ctx.putImageData(imgPixels, 0, 0, 0, 0, width, height)
		} else {
			ctx.drawImage(img, 0, 0, width, height); // Or at whatever offset you like
			const imgPixels: ImageData = ctx.getImageData(0, 0, width, height);
			ctx.putImageData(imgPixels, 0, 0, 0, 0, width, height)
			ctx.globalCompositeOperation = 'source-over';
		}

		const imageWithResolution = new Image();
		imageWithResolution.src = canvas.toDataURL();

		return imageWithResolution;
	}

	protected buildCopyImage(img: HTMLImageElement){
		const canvas = document.createElement('canvas');
		const ctx:any = canvas.getContext('2d');

		// High Resolution
		const highResolution = this.getImageWithResolution(false, canvas, img, ctx, 1);

		// Medium Resolution
		const mediumResolution = this.getImageWithResolution(false, canvas, img, ctx, 5);

		// Low Resolution
		const lowResolution = this.getImageWithResolution(false, canvas, img, ctx, 50);

		this.originalImageCopy = {highResolution, mediumResolution, lowResolution};

		canvas.remove();
	}


	protected buildGrayImage(img: HTMLImageElement) {
		const canvas = document.createElement('canvas');
		const ctx:any = canvas.getContext('2d');

		// High Resolution
		const highResolution = this.getImageWithResolution(true, canvas, img, ctx, 1);

		// Medium Resolution
		const mediumResolution = this.getImageWithResolution(true, canvas, img, ctx, 5);

		// Low Resolution
		const lowResolution = this.getImageWithResolution(true, canvas, img, ctx, 50);

		this.grayImage = {highResolution, mediumResolution, lowResolution};

		canvas.remove();
	}

	protected drawLogo(): ()=>void {
		return () => {
			try {
				if (this.grayImage && !this.isActive) {
					const scale = this.ctx.getTransform().d;
					const image = this.getImageResolution(this.isDragging, scale, this.grayImage);

					this.ctx.save();
					this.ctx.globalAlpha = 0.3;
					this.roundedImage(this.coordinates.x + 15, this.coordinates.y + 12.5, 35, 35, 10);
					this.ctx.clip();
					this.ctx.drawImage(image, this.coordinates.x + 15, this.coordinates.y + 12.5, 35, 35);
					this.ctx.globalAlpha = 1;
					this.ctx.restore();
				} else if (this.originalImageCopy) {
					const scale = this.ctx.getTransform().d;
					const image = this.getImageResolution(this.isDragging, scale, this.originalImageCopy);

					this.ctx.save();
					this.roundedImage(this.coordinates.x + 15, this.coordinates.y + 12.5, 35, 35, 10);
					this.ctx.clip();
					this.ctx.drawImage(image, this.coordinates.x + 15, this.coordinates.y + 12.5, 35, 35);
					this.ctx.restore();
				}
			} catch (e) {
				this.drawDefaultIcon()
			}
		}
	}

	protected getImageResolution(isDragging: boolean, scale: number, image: {highResolution: HTMLImageElement, mediumResolution: HTMLImageElement, lowResolution: HTMLImageElement} | undefined) {
		if (scale > 1.5 && !isDragging) {
			return image?.highResolution
		} else if (scale > 0.5) {
			return image?.mediumResolution
		} else {
			return image?.lowResolution
		}
	}

	protected drawDefaultIcon() {
		this.ctx.font = 'bold 14px proxima-nova';
		this.ctx.textAlign = "center";
		this.ctx.fillStyle = this.isActive ? '#0C2633' : ApplicationCard.INACTIVE_FONT_COLOR;
		this.ctx.fillText(this.applicationName.slice(0,2), this.coordinates.x + 33, this.coordinates.y + 35);
		this.ctx.fill();
		this.ctx.textAlign = "start";
	}

	protected drawTitle(): ()=>void {
		return () => {
			this.ctx.beginPath();
			const transform = this.ctx.getTransform();
			let fontSize = 24 * 1 / transform.d;

			this.ctx.font = "bold "+ (fontSize > 14 ? fontSize : 14) + "px proxima-nova";

			const metrics = this.ctx.measureText(this.applicationName);

			let fontHeight;
			if (metrics.fontBoundingBoxDescent && metrics.fontBoundingBoxAscent) {
				fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
			} else {
				fontHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
			}

			let ellipsisText = ''
			for (let i=0; i < 100 && this.ctx.measureText(ellipsisText + '...').width < ApplicationCard.WIDTH - 115; i++) {
				ellipsisText += this.applicationName.slice(i, i+1);
			}

			if (ellipsisText.length < this.applicationName.length) {
				ellipsisText += '...'
			}

			let marginTop;
			if (this.applicationDescription && (transform.d > 1.6)) {
				marginTop  = (ApplicationCard.HEIGHT / 2) - fontHeight / 6
			} else {
				marginTop = (ApplicationCard.HEIGHT / 2) + (fontHeight / 4)
			}

			this.ctx.fillStyle = this.isActive ? ApplicationCard.ACTIVE_FONT_COLOR : ApplicationCard.INACTIVE_FONT_COLOR;
			this.ctx.fillText(ellipsisText, this.coordinates.x + 62, this.coordinates.y + marginTop);
		}
	}

	protected drawSubtitle() : ()=>void {
		return () => {
			const transform = this.ctx.getTransform();

			if (this.applicationDescription && (transform.d > 1.6)) {
				this.ctx.beginPath();
				let fontSize = 18 * 1/transform.d;
				this.ctx.font =  (fontSize > 12 ? fontSize : 12) + "px proxima-nova";

				const metrics = this.ctx.measureText(this.applicationName);

				let fontHeight:number, marginTop:number;
				if (metrics.fontBoundingBoxDescent && metrics.fontBoundingBoxAscent) {
					fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
					marginTop  =  (ApplicationCard.HEIGHT / 2) + fontHeight / 1.3
				} else {
					fontHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
					marginTop  =  (ApplicationCard.HEIGHT / 2) + fontHeight * 1.3
				}

				this.ctx.fillStyle = this.isActive ? ApplicationCard.ACTIVE_FONT_COLOR : ApplicationCard.INACTIVE_FONT_COLOR;

				// Add ... if desc to long
				let desc = '';
				for (let i = 0;this.ctx.measureText(desc + '...').width < ApplicationCard.WIDTH - 110 && i < this.applicationDescription.length; i++) {
					desc += this.applicationDescription[i]
				}

				if (desc.length < this.applicationDescription.length) {
					desc += '...'
				}

				this.ctx.fillText(desc, this.coordinates.x + 62, this.coordinates.y + marginTop);
			}
		}
	}

	protected drawCriticality(): ()=>void {
		return () => {
			if (this.applicationCriticality) {
				const image = criticalityFlow.get(this.applicationCriticality);
				this.ctx.drawImage(this.isActive ? image?.normal : image?.disabled, this.coordinates.x + ApplicationCard.WIDTH - 35, this.coordinates.y + ApplicationCard.HEIGHT / 2 - 10, 12, 19);
			}
		}
	}

	protected drawShadow(): ()=>void {
		return () => {
			this.ctx.beginPath()
			this.ctx.fillStyle = '#E3E3E3FF';
			this.ctx.roundRect(this.coordinates.x + 2, this.coordinates.y + 2, ApplicationCard.WIDTH, ApplicationCard.HEIGHT, 15);
			//this.ctx.fill();

			this.ctx.shadowColor = "#E3E3E3FF";
			this.ctx.shadowBlur = 3;
			this.ctx.shadowOffsetX = 3;
			this.ctx.shadowOffsetY = 3;
			const repeats = 2
			for(let i=0;i<repeats;i++){
				this.ctx.shadowBlur+=0.25;
			}

			this.ctx.fill();
			this.ctx.shadowBlur = 0
			this.ctx.shadowColor = "transparent";
		}
	}

	protected drawBackground(): ()=>void {
		return () => {
			this.ctx.beginPath()

			this.ctx.fillStyle = 'white';
			this.ctx.roundRect(this.coordinates.x, this.coordinates.y, ApplicationCard.WIDTH, ApplicationCard.HEIGHT, 15);
			this.ctx.fill()

			this.ctx.strokeStyle = (this.isSelected || this.mouseOver) ? '#36B0EB' : '#ececee';
			this.ctx.lineWidth = 1;
			this.ctx.stroke();
		}
	}

	drawAnchor() : ()=>void {
		return () => {
			const color = this.anchorColor || 'rgba(54, 176, 235, 1)';

			if (this.isRightAnchorActive) {
				this.ctx.beginPath();
				this.ctx.fillStyle = color;
				this.ctx.arc(this.getRightAnchor().x, this.getRightAnchor().y, 7, 0, 2 * Math.PI);
				this.ctx.fill();

				this.ctx.beginPath();
				this.ctx.fillStyle = 'white';
				this.ctx.arc(this.getRightAnchor().x, this.getRightAnchor().y, 6, 0, 2 * Math.PI);
				this.ctx.fill();
			}

			if (this.isLeftAnchorActive) {
				this.ctx.beginPath();
				this.ctx.fillStyle = color;
				this.ctx.arc(this.getLeftAnchor().x, this.getLeftAnchor().y, 7, 0, 2 * Math.PI);
				this.ctx.fill();

				this.ctx.beginPath();
				this.ctx.fillStyle = 'white';
				this.ctx.arc(this.getLeftAnchor().x, this.getLeftAnchor().y, 6, 0, 2 * Math.PI);
				this.ctx.fill();
			}
		}
	}

	protected roundedImage(x: number, y: number, width: number, height: number, radius: number){
		this.ctx.beginPath();
		this.ctx.moveTo(x + radius, y);
		this.ctx.lineTo(x + width - radius, y);
		this.ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
		this.ctx.lineTo(x + width, y + height - radius);
		this.ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
		this.ctx.lineTo(x + radius, y + height);
		this.ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
		this.ctx.lineTo(x, y + radius);
		this.ctx.quadraticCurveTo(x, y, x + radius, y);
		this.ctx.closePath();
	}
}
