import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewChild
} from "@angular/core";
import {
	CriticalityLevel, DiagramApplicationFlow, DiagramApplicationFlowFilterForm,
	TenantService
} from "src/app/services/tenant.service";
import {BehaviorSubject, delayWhen, finalize, forkJoin, Observable, of, Subject, Subscription, switchMap} from "rxjs";
import {filter, first, map, tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {Organization, OrganizationService, OrganizationTree} from "src/app/services/organization.service";
import {APPLICATIONS_URL} from "src/app/models/home/navigation.model";
import {CurrentTenantService} from "src/app/services/front/current-tenant.service";
import {MenuStateService} from "src/app/services/front/menu-state.service";
import {FlowDiagramEngine} from "./engine/flow-diagram-engine";
import {ApplicationOverview, Criticality, FlowOverview} from "../common/diagram";
import {FormControl, FormGroup} from '@angular/forms';
import jsPDF, {Point} from 'jspdf';
import {Category} from 'src/app/services/model/application-category.model';
import {ApplicationCategoryService} from 'src/app/services/back/application-category.service';
import {ApplicationGeneric, ApplicationStatus} from 'src/app/services/model/new-application.model';
import {NewApplicationService} from 'src/app/services/back/new-application.service';
import {CatalogTag} from "../../../../services/model/catalog-tag.model";
import {animate, style, transition, trigger} from "@angular/animations";
import {Flow, FlowService, ProtocolType} from "../../../../services/back/flow.service";

@Component({
	selector: 'app-diagram-app-flows',
	standalone: false,
	templateUrl: './diagram-app-flows.component.html',
	styleUrls: ['./diagram-app-flows.component.scss'],
	animations: [
		trigger('fadeInOut', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate('0.2s', style({ opacity: 1 }))
			]),
			transition(':leave', [
				style({ opacity: 1 }),
				animate('0.2s', style({ opacity: 0 }))
			])
		])
	]
})
export class DiagramAppFlowsComponent implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
	@ViewChild('canvasContainer', { static: true }) canvasContainer: ElementRef<HTMLElement>;

	tenantId: string;

	_initializing: boolean;
	_loading: boolean;
	_loadingFilter: boolean;
	_exporting: boolean;
	initialized: boolean = false;

	focusApplicationId?: string;
	applicationFlows: DiagramApplicationFlow[] = [];
	filterForm: FormGroup;
	searchOrganizationControl: FormControl;
	organizations: OrganizationTree[] = [];
	searchCategoryControl: FormControl;
	categories: Category[] = [];
	searchApplicationControl: FormControl;
	applications: ApplicationGeneric[] = [];
	filterData: {
		application: ApplicationGeneric[],
		organization: OrganizationTree[],
		criticality: CriticalityLevel[],
		category: Category[],
		protocol: ProtocolType[],
		tag: CatalogTag[],
		status: ApplicationStatus[],
		port: string[],
	};
	formName: typeof Form = Form;

	isEmpty: boolean = false;
	expandAdvancedFilters: boolean = false;
	displayProgressBar: boolean = true;
	topOrganizationName: string;
	toolbarApplicationId: string|undefined;
	toolbarPositionLeft: number = 0;
	toolbarPositionTop: number = 0;
	toolbarVisible: boolean = false;

	tooltipData: Flow | undefined;
	tooltipPositionLeft: number = 0;
	tooltipPositionTop: number = 0;
	tooltipVisible: boolean = false;

	menuWidth: number = 90;
	engine: FlowDiagramEngine;
	width: number = 0;
	height: number = 0;
	activeFilters: number = 0;
	viewInit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	subscription: Subscription = new Subscription();

	constructor(private applicationCategoryService: ApplicationCategoryService,
							private flowService: FlowService,
							private currentTenantService: CurrentTenantService,
							private organizationService: OrganizationService,
							private applicationService: NewApplicationService,
							private changeDetectorRef: ChangeDetectorRef,
							private menuStateService: MenuStateService,
							private tenantService: TenantService,
							private translate: TranslateService,
							private renderer: Renderer2,
							private router: Router) {
	}

	ngOnInit() {
		this.createFilterForm();
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize()));
		this.subscription.add(this.menuStateService.onMenuStateChange()
			.pipe(tap(state => this.menuWidth = state.width))
			.subscribe(() => setTimeout(() => this.onResize({}))));
		this.renderer.addClass(document.body, 'disable-two-finger-back');
	}

	ngAfterViewInit() {
		this.width = this.canvasContainer.nativeElement.clientWidth;
		this.height = this.canvasContainer.nativeElement.clientHeight;
		this.changeDetectorRef.detectChanges();
		this.viewInit.next(true);
	}

	private createFilterForm(): void {
		this.filterForm = new FormGroup({
			[Form.organization]: new FormControl([]),
			[Form.application]: new FormControl([]),
			[Form.criticality]: new FormControl([]),
			[Form.category]: new FormControl([]),
			[Form.withNoData]: new FormControl(true),
			[Form.withExternal]: new FormControl(false),
			[Form.protocol]: new FormControl([]),
			[Form.port]: new FormControl([]),
			[Form.status]: new FormControl([]),
			[Form.tag]: new FormControl([]),
		});
		this.subscription.add(this.filterForm.valueChanges
			.pipe(
				tap(() => this.setActiveFilters()),
				switchMap(() => this.fetchDiagramApplicationFlows()))
			.subscribe());
		this.searchOrganizationControl = new FormControl('');
		this.subscription.add(this.searchOrganizationControl.valueChanges
			.subscribe(search => this.searchOrganizationFilter(search)));
		this.searchCategoryControl = new FormControl('');
		this.subscription.add(this.searchCategoryControl.valueChanges
			.subscribe(search => this.searchCategoryFilter(search)));
		this.searchApplicationControl = new FormControl('');
		this.subscription.add(this.searchApplicationControl.valueChanges
			.subscribe(search => this.searchApplicationFilter(search)));
	}

	initialize(): void {
		this.subscription.add(this.fetchDiagramApplicationFlows()
			.subscribe(() => this.initialized = true));
		this.subscription.add(this.fetchDiagramFilterData()
			.subscribe());
	}

	fetchDiagramApplicationFlows(): Observable<{}> {
		return this.switchLoading().pipe(
			map(() => this.buildDiagramApplicationFlowsFilterForm()),
			switchMap(form => this.tenantService.getAllDiagramApplicationFlows(this.tenantId, form)),
			delayWhen(() => this.viewInit.pipe(filter(init => init), first())),
			tap(apps => this.setDiagramApplicationFlows(apps)),
			finalize(() => this.switchLoading()));
	}

	private buildDiagramApplicationFlowsFilterForm(): DiagramApplicationFlowFilterForm {
		return {
			organizations: this.organizationFormValue.map(o => o.organizationId),
			applications: this.applicationFormValue.map(c => c.id),
			categories: this.categoryFormValue.map(c => c.categoryId),
			criticality: this.criticalityFormValue,
			withNoData: this.withNoDataFormValue,
			withExternal: this.withExternalFormValue,
			protocols: this.protocolFormValue,
			ports: this.portFormValue,
			status: this.statusFormValue,
			tag: this.tagFormValue.map(t => t.tagId)
		}
	}

	private setDiagramApplicationFlows(apps: DiagramApplicationFlow[]): void {
		this.applicationFlows = apps;
		const applicationOverviews: ApplicationOverview[] = [];
		const flowsOverviews: FlowOverview[] = [];
		apps.forEach(app => {
			applicationOverviews.push({
				applicationId: app.application.id,
				applicationLogo: app.application.logo,
				applicationName: app.application.name,
				applicationDesc: app.application.description,
				applicationCriticality: app.application.criticality?.toLowerCase() as Criticality,
				groupName: app.category?.name || 'Others',
				groupId: app.category?.categoryId || 'default_id_1',
			});
			app.flowList.forEach(flow => {
				if (!flowsOverviews.find(f => f.id === flow.id)) {
					flowsOverviews.push({
						id: flow.id,
						source: flow.source.id,
						target: flow.target.id,
						data: flow.dataList.map(d => d.name)
					});
				}
			});
		});
		if (!this.initialized) {
			this.isEmpty = apps.length === 0;
			this.engine?.shutdown();
			if (!this.isEmpty) {
				setTimeout(() => this.startEngine(applicationOverviews, flowsOverviews));
			}
		} else {
			this.engine.update(false,  applicationOverviews, flowsOverviews, undefined, this.focusApplicationId);
			this.onRecenter();
		}
	}

	private startEngine(applications: ApplicationOverview[], flowsOverview: FlowOverview[]): void {
		const context: CanvasRenderingContext2D|null = this.canvas.nativeElement.getContext('2d');
		if (context !== null) {
			this.engine = new FlowDiagramEngine(
				context,
				this.canvas.nativeElement,
				applications,
				flowsOverview,
				this.canvasContainer.nativeElement.getBoundingClientRect().height,
				this.canvasContainer.nativeElement.getBoundingClientRect().width
			);
			this.engine.warmUp();
			this.subscription.add(this.engine.onObjectClicked
				.subscribe((event) => this.setToolbarPosition(event)));
			this.subscription.add(this.engine.onObjectHovered
				.subscribe((event) => {this.setTooltipPosition(event)}));
			this.subscription.add(this.engine.onObjectHoveredStacked
				.subscribe((event) => {this.setTooltipPositionStacked(event)}));
		}
	}

	setToolbarPosition(event?: { id: string, position: Point }): void {
		if (event) {
			this.toolbarPositionTop = event.position.y + this.canvas.nativeElement.getBoundingClientRect().y;
			this.toolbarPositionLeft = event.position.x ;
			this.toolbarApplicationId = event.id;
			this.toolbarVisible = this.toolbarPositionTop > this.canvas.nativeElement.getBoundingClientRect().y;
			this.changeDetectorRef.detectChanges();
		} else {
			this.toolbarVisible = false;
		}
	}

	onUpdate(): void {
		this.fetchDiagramApplicationFlows().subscribe();
	}

	setTooltipPosition(event?: { sourceId: string, targetId: string, position: Point }): void {
		if (event) {
			this.tooltipPositionTop = event.position.y + this.canvas.nativeElement.getBoundingClientRect().y;
			this.tooltipPositionLeft = event.position.x ;
			this.tooltipData = this.applicationFlows
				.find(a => a.application.id === event.sourceId)!
				.flowList
				.find(m => m.source.id === event.sourceId && m.target.id === event.targetId);
			this.tooltipVisible = this.tooltipPositionTop > this.canvas.nativeElement.getBoundingClientRect().y && this.tooltipData !== undefined;
			this.changeDetectorRef.detectChanges();
		} else {
			this.tooltipVisible = false;
		}
	}

	setTooltipPositionStacked(event?: { sourceId: string, targetId: string, id: string, position: Point }): void {
		if (event) {
			this.tooltipPositionTop = event.position.y + this.canvas.nativeElement.getBoundingClientRect().y;
			this.tooltipPositionLeft = event.position.x ;
			this.tooltipData = this.applicationFlows
				.find(a => a.application.id === event.sourceId)!
				.flowList
				.find(m => m.id === event.id);
			this.tooltipVisible = this.tooltipPositionTop > this.canvas.nativeElement.getBoundingClientRect().y && this.tooltipData !== undefined;
			this.changeDetectorRef.detectChanges();
		} else {
			this.tooltipVisible = false;
		}
	}

	fetchDiagramFilterData(): Observable<{}> {
		return this.switchLoadingFilter().pipe(
			switchMap(() => forkJoin([
				this.applicationService.getAllApplication(this.tenantId),
				this.organizationService.getOrganizationTreeByTenantId(this.tenantId),
				this.applicationCategoryService.getAllApplicationCategoryByTenantId(this.tenantId),
				this.flowService.getAllUsedPortByTenantId(this.tenantId),
				this.applicationService.getAllApplicationTagByTenantId(this.tenantId)
			])),
			tap(([applications, organizations, categories, ports, tags]) => this.setFilterData(applications, organizations, categories, ports, tags)),
			finalize(() => this.switchLoadingFilter()));
	}

	private setFilterData(applications: ApplicationGeneric[], organization: OrganizationTree, categories: Category[], ports: string[], tags: CatalogTag[]): void {
		this.topOrganizationName = organization.organization.name;
		this.organizations = organization.children
			.filter(c => c.children.length > 0)
			.sort((a, b) => a.organization.name.localeCompare(b.organization.name));
		this.organizations.forEach(o => o.children.sort((a, b) => a.organization.name.localeCompare(b.organization.name)));
		this.categories = categories
			.sort((a, b) => a.name.localeCompare(b.name));
		this.applications = applications
			.sort((a, b) => a.name.localeCompare(b.name));
		this.filterData = {
			application: this.applications,
			organization: this.organizations,
			category: this.categories,
			criticality: Object.values(CriticalityLevel),
			protocol: Object.values(ProtocolType),
			port: ports,
			tag: tags,
			status: Object.values(ApplicationStatus)
		};
	}

	private searchOrganizationFilter(search?: string): void {
		if (!search) {
			this.filterData.organization = this.organizations;
		} else {
			const lowercaseValue = search.toLowerCase();
			this.filterData.organization = this.organizations
				.reduce((acc: OrganizationTree[], org) => {
					const matchingChildren = org.children.filter(team =>
						team.organization.name.toLowerCase().includes(lowercaseValue)
					);

					if (org.organization.name.toLowerCase().includes(lowercaseValue) || matchingChildren.length > 0) {
						acc.push({
							...org,
							children: org.organization.name.toLowerCase().includes(lowercaseValue)
								? org.children
								: matchingChildren
						});
					}
					return acc;
				}, [])
				.sort((a, b) => a.organization.name.localeCompare(b.organization.name));
		}
	}

	private searchCategoryFilter(search?: string): void {
		this.filterData.category = this.categories.filter(c => !search || c.name.toLowerCase().includes(search.toLowerCase()));
	}

	private searchApplicationFilter(search?: string): void {
		this.filterData.application = this.applications.filter(c => !search || c.name.toLowerCase().includes(search.toLowerCase()));
	}

	exportToPdf() {
		this.switchExporting()
			.pipe(
				map(() => [this.translate.instant('page.diagram.flowDiagram.pdf.title'), this.buildPdfSubtitle()]),
				switchMap(([title, subtitle]) => this.engine.exportToPDF(title, subtitle, 'assets/icons/diagram-app-mapping.png')),
				finalize(() => this.switchExporting()))
			.subscribe(pdf => this.savePdf(pdf));
	}

	private buildPdfSubtitle(): string {
		return !this.organizationFormValue.length
			? this.topOrganizationName
			: this.organizations
				.filter(parent => parent.children.some(c => this.organizationFormValue.map(o => o.organizationId).includes(c.organization.organizationId)))
				.map(parent => parent.organization.name
					+ ' ('
					+ parent.children
						.filter(c => this.organizationFormValue.map(o => o.organizationId).includes(c.organization.organizationId))
						.map(c => c.organization.name)
						.join(', ')
					+ ')')
				.join(' - ');
	}

	private savePdf(pdf: jsPDF): void {
		const titleDate: string = new Date().toISOString().split('T')[0];
		pdf.save('flow-' + titleDate + '.pdf');
	}

	focusApplication(applicationId: string): void {
		this.focusApplicationId = applicationId;
		const linkedIds: string[] = this.applicationFlows
			.find(a => a.application.id === applicationId)!
			.flowList
			.map(m => [m.source.id, m.target.id])
			.flat()
			.filter(id => this.applicationFlows.map(a => a.application.id).includes(id));
		const distinctIds: string[] = [...new Set(linkedIds)];
		this.filterForm.get(Form.application)!.setValue(this.applications.filter(a => distinctIds.includes(a.id)));
	}

	cancelFocus(): void {
		this.focusApplicationId = undefined;
		this.filterForm.get(Form.application)!.setValue([]);
	}

	setActiveFilters(): void {
		this.activeFilters = this.applicationFormValue.length
			+ this.organizationFormValue.length
			+ this.criticalityFormValue.length
			+ this.categoryFormValue.length
			+ this.protocolFormValue.length
			+ this.portFormValue.length
			+ (this.withNoDataFormValue ? 0 : 1)
		    + this.statusFormValue.length
			+ this.tagFormValue.length;
	}

	resetFilters(): void {
		this.filterForm.setValue({
			[Form.application]: [],
			[Form.organization]: [],
			[Form.criticality]: [],
			[Form.category]: [],
			[Form.withNoData]: true,
			[Form.withExternal]: false,
			[Form.protocol]: [],
			[Form.port]: [],
			[Form.status]: [],
			[Form.tag]: []
		});
		this.filterForm.markAsPristine();
	}

	protected onMouseOut(): void {
		this.engine?.onFocusOut();
	}

	protected redirectToApps(): void {
		this.router.navigate([APPLICATIONS_URL]);
	}

	get organizationFormValue(): Organization[] {
		return this.filterForm.get(Form.organization)!.value;
	}

	get criticalityFormValue(): CriticalityLevel[] {
		return this.filterForm.get(Form.criticality)!.value;
	}

	get applicationFormValue(): ApplicationGeneric[] {
		return this.filterForm.get(Form.application)!.value;
	}

	get categoryFormValue(): Category[] {
		return this.filterForm.get(Form.category)!.value;
	}

	get protocolFormValue(): ProtocolType[] {
		return this.filterForm.get(Form.protocol)!.value;
	}

	get portFormValue(): string[] {
		return this.filterForm.get(Form.port)!.value;
	}

	get withNoDataFormValue(): boolean {
		return this.filterForm.get(Form.withNoData)!.value;
	}

	get withExternalFormValue(): boolean {
		return this.filterForm.get(Form.withExternal)!.value;
	}

	get statusFormValue(): ApplicationStatus[] {
		return this.filterForm.get(Form.status)!.value;
	}

	get tagFormValue(): CatalogTag[] {
		return this.filterForm.get(Form.tag)!.value;
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: any): void {
		this.engine?.onResize(
			this.canvasContainer.nativeElement.getBoundingClientRect().height,
			this.canvasContainer.nativeElement.getBoundingClientRect().width
		);
	}

	onZoomIn(): void {
		this.engine?.zoomIn();
	}

	onZoomOut(): void {
		this.engine?.zoomOut();
	}

	onRecenter(): void {
		this.engine?.recenter();
	}

	getZoomLevel(): number {
		return this.engine?.getZoomPercent() ?? -1;
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	private switchLoadingFilter(): Observable<{}> {
		this._loadingFilter = !this._loadingFilter;
		return of({});
	}

	private switchExporting(): Observable<{}> {
		this._exporting = !this._exporting;
		return of({});
	}

	ngOnDestroy() {
		this.engine?.shutdown();
		this.subscription.unsubscribe();
		this.renderer.removeClass(document.body, 'disable-two-finger-back');
	}

	protected readonly setTimeout = setTimeout;
}

enum Form {
	organization = 'organization',
	application = 'application',
	criticality = 'criticality',
	category = 'category',
	withNoData = 'withNoData',
	withExternal = 'withExternal',
	protocol = 'protocol',
	port = 'port',
	status = 'status',
	tag = 'tag'
}
