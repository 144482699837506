<div class="flex-column gap-M">
	<div class="flex-align-start flex-space-between">
		<span class="font-MM fw-700">{{'page.appDetails.architecture.title'|translate}}</span>
		<app-button-widget (onClick)="openFlowFormDialog()" [loading]="_initializing || _loading || !!_deleting">
			<img width="12" src="assets/icons/add.svg" class="filter-primary">
		</app-button-widget>
	</div>
	<div class="w-100" *ngIf="flowList.length > 0 && initialized">
		<div class="flex grey">
			<span style="width: 37%">{{ 'page.data.source' | translate }}</span>
			<span style="width: 38%">{{ 'page.data.dataUppercase' | translate }}</span>
			<span style="width: 20%">{{ 'page.data.target' | translate }}</span>
			<span>Action</span>
		</div>
		<hr class="hr-document">
		<div class="w-100 p-relative" *ngFor="let flow of flowList; let i = index">
			<div class="flex-space-between pad-S-0" [style]="{ 'padding-bottom': i === flowList.length - 1 ? '0' : '10px' }">
				<div class="flex-align-center flex-space-between w-80"
					 *ngVar="{ left: flow.source.id === applicationId ? flow.source : flow.target, right: flow.target.id === applicationId ? flow.source : flow.target } as app">
					<div class="flex-center" style="background: white; padding: 0 3px; z-index: 2">
						<div class="data-app flex-center">
							<app-display-logo [logo]="app.left.logo" [size]="30" [tooltip]="app.left.name" tooltipPosition="right"></app-display-logo>
						</div>
					</div>

					<div class="fluxDataWrapper">
						<div class="fluxDataLeftCircle"></div>
						<div class="fluxDataLine"></div>
						<div class="fluxDataRightCircle"></div>
						<div class="flex-center gap-XS w-100 h-100 dataTag" style="cursor: default">
							<div *ngIf="app.left.id === flow.target.id" (mouseover)="displayTooltip(flow)" (mouseleave)="hideTooltip()" style="border: 1px solid #B5B5C3" class="flex-center fluxDataLeftArrow bkg-white grey">
								<img width="6" src="assets/icons/arrow-right-black.svg" style="transform: rotate(180deg) translateX(1px);" class="filter-grey">
							</div>
							<div (mouseover)="displayTooltip(flow)" (mouseleave)="hideTooltip()" *ngFor="let data of flow.dataList|slice:0:3" style="border-radius: 20px; border: 1px solid #B5B5C3; height: 20px; min-height: 20px; max-height: 20px;" class="flex-center horizontal-bar-L bkg-white grey pad-0-XS">
								<span class="font-S">{{data.name}}</span>
							</div>
							<div (mouseover)="displayTooltip(flow)" (mouseleave)="hideTooltip()" *ngIf="flow.dataList.length > 3" style="border-radius: 50%; border: 1px solid #B5B5C3; height: 20px; min-height: 20px; max-height: 20px; width: 20px;" class="flex-center bkg-white grey">
								<span class="font-XS">+{{ flow.dataList.length - 3 }}</span>
							</div>
							<div *ngIf="app.right.id === flow.target.id" (mouseover)="displayTooltip(flow)" (mouseleave)="hideTooltip()" style="border: 1px solid #B5B5C3" class="flex-center fluxDataRightArrow bkg-white grey">
								<img width="6" src="assets/icons/arrow-right-black.svg" style="transform: translateX(0.5px);" class="filter-grey">
							</div>
						</div>
					</div>
					<div class="flex-center" style="background: white; padding: 0 3px; z-index: 2">
						<div class="data-app flex-center">
							<app-display-logo [logo]="app.right.logo" [size]="30" [tooltip]="app.right.name" tooltipPosition="left"></app-display-logo>
						</div>
					</div>
				</div>
				<div class="flex-align-center">
					<div *ngIf="flow.documents && flow.documents.length > 1">
						<app-icon-button [size]="'s'" [img]="'assets/icons/download-01.svg'"
										 [panelClass]="'img-size-70'"
										 [tooltip]="'button.download' | translate"
										 [disabled]="_initializing || _loading || !!_deleting"
										 [dropdownTrigger]="downloadDropdown">
						</app-icon-button>
						<app-dropdown #downloadDropdown>
							<div style="width: 300px">
								<div *ngFor="let file of flow.documents; let index = index">
									<div *ngIf="file.type === fileType.FILE" class="uploading-container" [ngClass]="{'mb-l': index < flow.documents.length - 1}">
										<div class="flex gap-M w-100">
											<div class="icon-bg" style="background-color: #ECF9FF">
												<img ngSrc="assets/icons/document-outline.svg" class="filter-accent" height="24" width="24" alt="">
											</div>
											<div class="flex-column flex-justify-center a-i-b w-100">
												<div class="flex-align-center flex-space-between" style="max-width: 136px;">
													<span class="text-overflow-ellipsis">{{ file.name }}</span>
												</div>
												<span *ngIf="file.size" class="font-S fw-500 grey">{{ sizePrettily(file.size) }}</span>
											</div>
										</div>
										<!-- DOWNLOAD BUTTON -->
										<div class="flex-center radius-half pb-s pt-s pr-s pl-s">
											<app-icon-button [size]="'s'" [img]="'assets/icons/download-01.svg'"
															 [panelClass]="'img-size-70'"
															 (click)="downloadFile(file)"></app-icon-button>
										</div>
									</div>
									<div *ngIf="file.type === fileType.LINK" class="uploading-container" [ngClass]="{'mb-l': index < flow.documents.length - 1}">
										<div class="flex gap-M w-100">
											<div class="icon-bg" style="background-color: #ECF9FF">
												<img ngSrc="assets/icons/link.svg" class="filter-accent" height="20" width="20" alt="">
											</div>
											<div class="flex-column flex-justify-center w-100">
												<div class="flex-align-center flex-space-between" style="max-width: 200px;">
													<a class="text-overflow-ellipsis text-underline accent" target="_blank"
													   [href]="file.link">{{ file.name }}</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</app-dropdown>
					</div>
					<div *ngIf="flow.documents && flow.documents.length === 1">
						<app-icon-button [size]="'s'" [img]="flow.documents[0].type == fileType.FILE ? 'assets/icons/download-01.svg' : 'assets/icons/link.svg'"
										 [panelClass]="'img-size-70'"
										 [tooltip]="flow.documents[0].type == fileType.FILE ? ('button.downloadOneFile' | translate) : ('button.openLink' | translate)"
										 [disabled]="_initializing || _loading || !!_deleting"
										 (click)="flow.documents[0].type == fileType.FILE ? downloadFile(flow.documents[0]) : openLink(flow.documents[0].link!)">
						</app-icon-button>
					</div>
					<app-icon-button color="accent" size="s" [img]="'assets/icons/edit.svg'" [tooltip]="'button.edit'|translate" [panelClass]="'fix-edit-margin'"
									 [disabled]="_initializing || _loading || _loadingDialog || !!_deleting"
									 (click)="openFlowFormDialog(flow)">
					</app-icon-button>
					<app-icon-button color="danger" size="s" [img]="'assets/icons/trash.svg'" [tooltip]="'button.delete'|translate"
									 [disabled]="_initializing || _loading || _loadingDialog || !!_deleting"
									 [loading]="_deleting === flow.id"
									 (click)="deleteApplicationFlow(flow.id)">
					</app-icon-button>
				</div>
			</div>
			<div flowTooltipPosition *ngIf="displayedTooltip && displayedTooltip.id === flow.id" class="diagram-tooltip">
				<div class="diagram-tooltip-inner">
					<!-- DETAILS -->
					<div
						[class.mb-l]="displayedTooltip.dataList.length > 0 || displayedTooltip.comment">
						<span class="fw-700 font-MM">{{ 'page.appDetails.architecture.dialog.flowDetails' | translate }}</span>
						<div class="wrapper mt-m">
							<div class="first first-col-table-cell flex-align-center flex-space-between">
								<div style="border-right: 1px solid #F2F2F8; padding-right: 10px" class="flex-align-center flex-space-between w-100">
									<div class="flex gap-XS flex-align-center">
										<img class="filter-grey" src="assets/icons/protocole.svg" alt="">
										<span class="grey">{{ 'page.appDetails.architecture.dialog.protocol' | translate }}</span>
									</div>
									<span *ngIf="displayedTooltip.protocol" class="fw-700">{{ 'page.diagram.flowDiagram.tooltip.protocol.' + displayedTooltip.protocol | translate }}</span>
									<span *ngIf="!displayedTooltip.protocol">-</span>
								</div>
							</div>
							<div class="second second-col-table-cell flex-align-center flex-space-between">
								<div class="flex-align-center flex-space-between w-100" style="padding-left: 10px">
									<div class="flex gap-XS flex-align-center">
										<img class="filter-grey" src="assets/icons/format.svg" alt="">
										<span class="grey">{{ 'page.appDetails.architecture.dialog.format' | translate }}</span>
									</div>
									<span *ngIf="displayedTooltip.format" class="fw-700">{{ 'page.diagram.flowDiagram.tooltip.format.' + displayedTooltip.format | translate }}</span>
									<span *ngIf="!displayedTooltip.format">-</span>
								</div>
							</div>
							<div class="third first-col-table-cell flex-align-center flex-space-between">
								<div style="border-right: 1px solid #F2F2F8; padding-right: 10px" class="flex-align-center flex-space-between w-100">
									<div class="flex gap-XS flex-align-center">
										<img class="filter-grey" src="assets/icons/port-type.svg" alt="">
										<span class="grey">{{ 'page.appDetails.architecture.dialog.portType' | translate }}</span>
									</div>
									<span *ngIf="displayedTooltip.portType" class="fw-700">{{ 'page.diagram.flowDiagram.tooltip.portType.' + displayedTooltip.portType | translate }}</span>
									<span *ngIf="!displayedTooltip.portType">-</span>
								</div>
							</div>
							<div class="fourth second-col-table-cell flex-align-center flex-space-between">
								<div class="flex-align-center flex-space-between w-100" style="padding-left: 10px">
									<div class="flex gap-XS flex-align-center">
										<img class="filter-grey" src="assets/icons/port-type.svg" alt="">
										<span class="grey">{{ 'page.appDetails.architecture.dialog.port' | translate }}</span>
									</div>
									<span *ngIf="displayedTooltip.portType" class="fw-700 text-overflow-ellipsis" style="max-width: 120px;">{{ displayedTooltip.port }}</span>
									<span *ngIf="!displayedTooltip.port">-</span>
								</div>
							</div>
							<div class="fifth first-col-table-cell flex-align-center flex-space-between">
								<div style="border-right: 1px solid #F2F2F8; padding-right: 10px" class="flex-align-center flex-space-between w-100">
									<div class="flex gap-XS flex-align-center">
										<img class="filter-grey" src="assets/icons/frequency.svg" alt="">
										<span class="grey">{{ 'page.appDetails.architecture.dialog.exchangeFrequencyShort' | translate }}</span>
									</div>
									<span *ngIf="displayedTooltip.exchangeFrequency" class="fw-700">{{ 'page.diagram.flowDiagram.tooltip.exchangeFrequency.' + displayedTooltip.exchangeFrequency | translate }}</span>
									<span *ngIf="!displayedTooltip.exchangeFrequency">-</span>
								</div>
							</div>
							<div class="sixth second-col-table-cell flex-align-center flex-space-between">
								<div class="flex-align-center flex-space-between w-100" style="padding-left: 10px">
									<div class="flex gap-XS flex-align-center">
										<img class="filter-grey" src="assets/icons/encryption.svg" alt="">
										<span class="grey">{{ 'page.appDetails.architecture.dialog.flowEncryption' | translate }}</span>
									</div>
									<span *ngIf="displayedTooltip.flowEncryption !== undefined && displayedTooltip.flowEncryption !== null" class="fw-700">{{ 'page.diagram.flowDiagram.tooltip.flowEncryption.' + displayedTooltip.flowEncryption | translate }}</span>
									<span *ngIf="displayedTooltip.flowEncryption === undefined || displayedTooltip.flowEncryption === null">-</span>
								</div>
							</div>
						</div>
					</div>
					<!-- DATA -->
					<div *ngIf="displayedTooltip.dataList.length > 0" [class.mb-l]="displayedTooltip.comment">
						<span class="fw-700 font-MM">{{ 'page.appDetails.architecture.dialog.data' | translate }}</span>
						<div class="flex mt-m gap-M flex-wrap">
							<div *ngFor="let flowData of displayedTooltip.dataList" class="data-bubble flex-align-center gap-M" [class.flex-center]="flowData.types.length <= 0">
								<span class="text-overflow-ellipsis">{{ flowData.name }}</span>
								<div *ngIf="flowData.types.length > 0" class="flex-align-center gap-XS">
									<div *ngFor="let type of flowData.types" class="data-type-bubble flex-center" [ngClass]="type">
										<span class="font-S">{{ type.toUpperCase() }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- COMMENTS -->
					<div *ngIf="displayedTooltip.comment">
						<span class="fw-700 font-MM">{{ 'page.appDetails.architecture.dialog.comments' | translate }}</span>
						<div class="mt-m pad-M comment-box">
							<span>{{ displayedTooltip.comment }}</span>
						</div>
					</div>
				</div>
			</div>
			<hr *ngIf="i < flowList.length - 1" class="hr-document">
		</div>
	</div>
	<div *ngIf="flowList.length <= 0 && initialized" class="w-100 flex-center flex-column" style="gap: 30px; height: 280px;">
		<img src="assets/illustrations/no-flow.svg" width="150">
		<span class="grey">{{ 'page.data.noFlow' | translate }}</span>
	</div>
</div>
<div class="pad-M" *ngIf="!initialized">
	<app-dashboard-page-loader></app-dashboard-page-loader>
</div>
