<div class="font-L fw-700">{{ 'page.settings.menu.account' | translate }}</div>

<!-- Global info -->
<div class="disp-f f-d-col j-c-c a-i-c" style="margin-top: 50px; margin-bottom: 50px">
	<app-display-user-logo [user]="currentUser" [size]=50></app-display-user-logo>
	<div class="fw-600 font-MM mt-s">{{!currentUser ? '' : currentUser.account.firstName + ' ' + currentUser.account.lastName}}</div>
	<div class="grey">{{!currentUser ? '' : currentUser.account.email}}</div>
</div>

<!-- Change lang -->
<div class="divider"></div>
<div class="font-MM fw-700 mt-l">{{ 'page.account.changeLangTitle' | translate }}</div>
<div class="font-M mt-xs mb-s">{{ 'page.account.changeLangSubtitle' | translate }}</div>
<app-multi-select [inputControl]="langForm">
	<app-select-trigger appearance="field">{{getDefaultLang()}}</app-select-trigger>
	<app-select-option [value]="'en'">English</app-select-option>
	<app-select-option [value]="'fr'">Français</app-select-option>
</app-multi-select>

<!-- Weekly report -->
<div class="divider"></div>
<div class="flex-space-between flex-align-center">
    <div class="flex-column">
        <div class="font-MM fw-700">{{ 'page.account.weeklyReportTitle' | translate }}</div>
        <div class="font-M mt-xs">{{ 'page.account.weeklyReportSubtitle' | translate }}</div>
    </div>
    <app-toggle-button [toggleInput]="weeklyReportControl"></app-toggle-button>
</div>

<!-- Change password -->
<ng-container *ngIf="currentUser.account.provider === 'local'">
    <div class="divider"></div>
    <div class="font-MM fw-700 mt-l">{{ 'page.account.changePassword.title' | translate }}</div>
    <div class="font-M mt-xs mb-s">{{ 'page.account.changePassword.subtitle' | translate }}</div>
    <div class="disp-f f-d-col gap-S">
        <app-text-input type="password" [input]="$any(passwordForm.get('oldPassword'))" [placeholder]="'page.account.changePassword.input.oldPassword' | translate"></app-text-input>
        <app-text-input class="mt-s" type="password" [input]="$any(passwordForm.get('newPassword'))" [placeholder]="'page.account.changePassword.input.newPassword' | translate"></app-text-input>
        <app-text-input class="mt-s" type="password" [input]="$any(passwordForm.get('newPasswordConfirmation'))" [placeholder]="'page.account.changePassword.input.newPasswordConfirmation' | translate"></app-text-input>
        <app-text-button color="accent" type="flat" [loading]="isLoadingPasswordChange" [disabled]="!passwordForm.valid" (click)="onChangePassword()">{{ 'page.account.changePassword.title' | translate }}</app-text-button>
    </div>
</ng-container>

<!-- Delete account -->
<div class="divider" style="margin-bottom: 5px"></div>
<app-text-button color="danger" [disabled]="!currentUser" (click)="onDeleteAccount()">{{ 'page.account.deleteButton' | translate }}</app-text-button>
