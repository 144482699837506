import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {SecurityUtils} from 'src/app/services/security.utils';
import {catchError} from 'rxjs/operators';
import {HttpClientUtils} from 'src/app/utils/http-client.utils';
import {
	ApplicationServerListForm,
	InfrastructureOverview,
	MetricAverage, Server,
	ServerAgent,
	ServerForm, ServerInformationForm,
	ServerTableData
} from 'src/app/services/model/server.model';
import {ServerApplication} from 'src/app/services/model/new-application.model';
import {CriticalityLevel} from "../tenant.service";
import {Network} from "./network.service";

@Injectable()
export class ServerService {

	private backendUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/servers`;

	constructor(protected httpClient: HttpClient) {
	}

	deleteServer(tenantId: string, serverId: string): Observable<boolean> {
		return this.httpClient.delete<boolean>(`${this.backendUrl(tenantId)}/${serverId}`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	getTenantServersTableData(tenantId: string): Observable<ServerTableData[]> {
		return this.httpClient.get<ServerTableData[]>(`${this.backendUrl(tenantId)}/table-data`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getTenantServers(tenantId: string): Observable<Server[]> {
		return this.httpClient.get<Server[]>(`${this.backendUrl(tenantId)}`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getServer(tenantId: string, serverId: string): Observable<Server> {
		return this.httpClient.get<Server>(`${this.backendUrl(tenantId)}/${serverId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getServerMetricAverage(tenantId: string, serverId: string): Observable<MetricAverage> {
		return this.httpClient.get<MetricAverage>(`${this.backendUrl(tenantId)}/${serverId}/metric-average`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	updateServerInformation(tenantId: string, serverId: string, form: ServerInformationForm): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.backendUrl(tenantId)}/${serverId}/information`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	getServerCriticality(tenantId: string, serverId: string): Observable<CriticalityLevel | null> {
		return this.httpClient.get<CriticalityLevel | null>(`${this.backendUrl(tenantId)}/${serverId}/criticality`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getServerApplications(tenantId: string, serverId: string): Observable<ServerApplication[]> {
		return this.httpClient.get<ServerApplication[]>(`${this.backendUrl(tenantId)}/${serverId}/applications`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	removeApplicationServer(tenantId: string, serverId: string, applicationId: string): Observable<boolean> {
		return this.httpClient.delete<boolean>(`${this.backendUrl(tenantId)}/${serverId}/applications/${applicationId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	createApplicationServer(tenantId: string, serverId: string, applicationId: string): Observable<boolean> {
		return this.httpClient.post<boolean>(`${this.backendUrl(tenantId)}/${serverId}/applications/${applicationId}`, null, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	updateApplicationServers(tenantId: string, applicationId: string, form: ApplicationServerListForm): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.backendUrl(tenantId)}/applications/${applicationId}`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	getApplicationServers(tenantId: string, applicationId: string): Observable<Server[]> {
		return this.httpClient.get<Server[]>(`${this.backendUrl(tenantId)}/applications/${applicationId}/servers`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getServerMetrics(tenantId: string, serverId: string, os: string): Observable<InfrastructureOverview> {
		return this.httpClient.get<any>(`${this.backendUrl(tenantId)}/${serverId}/${os}/metrics`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getTenantMonthlyAverageMetrics(tenantId: string): Observable<MetricAverage> {
		return this.httpClient.get<MetricAverage>(`${this.backendUrl(tenantId)}/monthly-average`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getServerAgent(tenantId: string, serverId: string): Observable<ServerAgent> {
		return this.httpClient.get<ServerAgent>(`${this.backendUrl(tenantId)}/${serverId}/agent`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	createManualServer(tenantId: string, body: ServerForm): Observable<boolean> {
		return this.httpClient.post<boolean>(`${this.backendUrl(tenantId)}`, body, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	getTenantServerCount(tenantId: string): Observable<number> {
		return this.httpClient.get<number>(`${this.backendUrl(tenantId)}/count`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, 0)));
	}

	getServerNetwork(tenantId: string, serverId: string): Observable<Network | null> {
		return this.httpClient.get<Network | null>(`${this.backendUrl(tenantId)}/${serverId}/network`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	updateServerNetwork(tenantId: string, serverId: string, networkId: string): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.backendUrl(tenantId)}/${serverId}/network`, {networkId}, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	getInfrastructureLocationList(tenantId: string): Observable<string[]> {
		return this.httpClient.get<string[]>(`${this.backendUrl(tenantId)}/location-list`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}
}
