import {Component, OnDestroy, OnInit} from "@angular/core";
import {finalize, Observable, of, Subscription, switchMap} from "rxjs";
import {Router} from "@angular/router";
import {CurrentTenantService} from "src/app/services/front/current-tenant.service";
import {SnackbarService} from "src/app/services/front/snackbar.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {APPLICATIONS_URL} from "src/app/models/home/navigation.model";
import {filter, map, tap} from "rxjs/operators";
import {CommonModule} from "@angular/common";
import {ApplicationIconModule} from "../../../../../../global/application-icon/application-icon.module";
import {ChipsModule} from "../../../../../../global/chips/chips/chips.module";
import {TagsModule} from "../../../../../../global/tags/tags.module";
import {Server} from "src/app/services/model/server.model";
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';
import {ApplicationGeneric, ServerApplication} from 'src/app/services/model/new-application.model';
import {ServerDetailData, ServerDetailService} from "../../../../../../../services/front/server-detail.service";
import {ServerService} from "../../../../../../../services/back/server.service";
import {NewApplicationService} from "../../../../../../../services/back/new-application.service";
import {AddAppsToServerComponent} from "./add-apps-to-server/add-apps-to-server.component";
import {OrganizationType} from "../../../../../../../services/model/autodiscover.model";
import {MiniButtonModule} from "../../../../../../global/button/mini-button/mini-button.module";
import {DeckContentLoaderModule} from "../../../../../../global/title-content-loader/deck-content-loader.module";
import {Organization} from "../../../../../../../services/organization.service";

@Component({
	selector: 'app-server-applications',
	templateUrl: './server-applications.component.html',
	styleUrls: ['./server-applications.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		ApplicationIconModule,
		ChipsModule,
		TagsModule,
		TranslateModule,
		DesignSystemModule,
		MiniButtonModule,
		DeckContentLoaderModule,
	]
})
export class ServerApplicationsComponent implements OnInit, OnDestroy {

	tenantId: string
	isEditor: boolean;
	server: Server;
	isTenantInactivePlan: boolean;

	_initializing: boolean;
	_loading: boolean = false;
	initialized: boolean = false;

	linkedApps: ServerApplication[] = [];
	tenantApplications: ApplicationGeneric[] = [];

	subscription: Subscription = new Subscription();

	constructor(private router: Router,
				private newApplicationService: NewApplicationService,
				private serverDetailService: ServerDetailService,
				private serverService: ServerService,
				private currentTenantService: CurrentTenantService,
				private snackbarService: SnackbarService,
				private translate: TranslateService,
				private dialog: MatDialog) {
	}

	ngOnInit() {
		this.subscription.add(this.serverDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.serverDetailService.getServerDetailDataChanges()
			.pipe(tap((data) => this.setServerDetailData(data)))
			.subscribe(() => this.initialize()));
	}

	private setServerDetailData(data: ServerDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.server = data.server;
		this.isTenantInactivePlan = data.isInactivePlan;
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				switchMap(() => this.serverService.getServerApplications(this.tenantId, this.server.id)),
				tap((apps) => this.setData(apps)),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.initialized = true));
	}

	setData(apps: ServerApplication[]): void {
		this.linkedApps = apps;
		this.linkedApps.sort((a, b) => a.application.application.name.localeCompare(b.application.application.name));
	}

	goToApp(applicationInstanceId: string) {
		this.currentTenantService.getCurrentTenantId().subscribe(tenantId => {
			this.router.navigate([APPLICATIONS_URL], {
				queryParams: {
					tenant: tenantId,
					applicationId: applicationInstanceId
				}
			});
		});
	}

	openLinkServerToTeam() {
		of(this.tenantApplications)
			.pipe(
				switchMap(apps => !apps?.length
					? this.newApplicationService.getAllApplication(this.tenantId)
					: of(apps)),
				tap(apps => this.tenantApplications = apps),
				map(apps => this.buildAddAppsToServerDialogData(apps)),
				switchMap(data => this.dialog.open(AddAppsToServerComponent, {
					position: {right: '162.5px'},
					width: '475px',
					data: {serverId: this.server.id, apps: data}
				}).afterClosed()),
				filter(result => result && result.success),
				tap(() => this.snackbarService.show(this.translate.instant('page.organization.appsSuccessfullyAdded'))))
			.subscribe(() => this.serverDetailService.refreshServer());
	}

	private buildAddAppsToServerDialogData(apps: ApplicationGeneric[]): AddAppsToServerDialogData[] {
		return apps.map(r => ({
			id: r.id,
			name: r.name,
			logo: r.logo,
			selected: this.linkedApps.some(app => app.application.application.applicationId === r.id)
		})).sort((a, b) => {
			if (a.selected === b.selected) {
				// If selected is the same, sort alphabetically by name
				return a.name.localeCompare(b.name);
			}
			return a.selected ? -1 : 1;
		})

	}

	buildTeamTooltipView(businessUnits: Organization[]): Map<string, { name: string, businessServices: string[] }> {
		const structureMap = new Map<string, { name: string, businessServices: string[] }>;
		businessUnits.forEach(bu => {
			const parent = bu.parentOrganization
			if (parent && structureMap.has(parent.organizationId)) {
				structureMap.get(parent.organizationId)!.businessServices.push(bu.name)
			} else if (parent) {
				structureMap.set(parent.organizationId, {name: parent.name, businessServices: [bu.name]})
			} else {
				structureMap.set(bu.organizationId, {name: bu.name, businessServices: []})
			}
		})
		return structureMap
	}

	switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	protected readonly OrganizationType = OrganizationType;
}

export interface AddAppsToServerDialogData {
	id: string;
	name: string;
	logo: string;
	selected: boolean;
}
