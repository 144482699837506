import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Data, DataApplicationForm, DataService} from "../../../../../../services/back/data.service";
import {DataDetailData, DataDetailService} from "../../../../../../services/front/data-detail.service";
import {SnackbarService} from "../../../../../../services/front/snackbar.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {
	ApplicationGeneric,
	ApplicationInstance,
	ApplicationUsage
} from "../../../../../../services/model/new-application.model";
import {NewApplicationService} from "../../../../../../services/back/new-application.service";
import {FormControl} from "@angular/forms";
import {finalize, forkJoin, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {filter} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {AddAppsToDataComponent} from "./add-apps-to-data/add-apps-to-data.component";
import {DesignSystemModule} from "../../../../../design-system/design-system.module";
import {CommonModule} from "@angular/common";
import {MiniButtonModule} from "../../../../../global/button/mini-button/mini-button.module";
import {APPLICATIONS_URL} from "../../../../../../models/home/navigation.model";
import {CurrentTenantService} from "../../../../../../services/front/current-tenant.service";
import {Router} from "@angular/router";
import {DeckContentLoaderModule} from "../../../../../global/title-content-loader/deck-content-loader.module";
import {ButtonWidgetComponent} from "../../../../../design-system/button-widget/button-widget.component";

@Component({
  selector: 'app-data-applications',
	imports: [
		DesignSystemModule,
		MiniButtonModule,
		CommonModule,
		TranslateModule,
		DeckContentLoaderModule,
		ButtonWidgetComponent
	],
  templateUrl: './data-applications.component.html',
  styleUrl: './data-applications.component.scss'
})
export class DataApplicationsComponent implements OnInit, OnDestroy{
	data: Data;
	tenantId: string;

	tenantApplications: ApplicationInstance[] = [];
	dataApplications: ApplicationUsage[] = [];

	dataDetailService = inject(DataDetailService);
	dataService = inject(DataService);
	applicationService = inject(NewApplicationService);
	snackbarService = inject(SnackbarService);
	translateService = inject(TranslateService);
	dialog = inject(MatDialog);
	currentTenantService = inject(CurrentTenantService);
	router = inject(Router);

	initialized: boolean = false;
	_initializing: boolean;
	_loading: boolean;

	subscription: Subscription = new Subscription();

	ngOnInit() {
		this.subscription.add(this.dataDetailService.getDataDetailDataChanges()
			.pipe(tap(data => this.setDataDetailData(data)))
			.subscribe(() => this.initialize()));
	}

	initialize(): void {
		this.switchLoading()
			.pipe(
				switchMap(() => forkJoin([
					this.applicationService.getAllApplicationInstance(this.tenantId),
					this.dataService.getDataApplications(this.tenantId, this.data.id)
				])),
				tap(([tenantApplications, dataApplications]) => this.setData(tenantApplications, dataApplications)),
				finalize(() => this.switchLoading())
			).subscribe(() => this.initialized = true);
	}

	private setDataDetailData(data: DataDetailData): void {
		this.data = data.data;
		this.tenantId = data.tenantId;
	}

	setData(tenantApplications: ApplicationInstance[], dataApplications: ApplicationUsage[]): void {
		this.tenantApplications = tenantApplications
			.sort((a, b) => a.name.localeCompare(b.name));
		this.dataApplications = dataApplications
			.sort((a, b) => a.application.application.name.localeCompare(b.application.application.name));
	}

	updateDataApplication(added: ApplicationInstance[], removed: ApplicationInstance[]): void {
		const form: DataApplicationForm = {
			added: added.map(app => app.applicationId),
			removed: removed.map(app => app.applicationId)
		};
		this.dataService.updateDataApplications(this.tenantId, this.data.id, form)
			.pipe(
				filter(success => success),
				tap(() => this.snackbarService.show(this.translateService.instant('page.application.detail.update.success')))
			).subscribe(() => this.dataDetailService.refreshData());
	}

	openDialog() {
		this.dialog.open(AddAppsToDataComponent, {
			position: {right: '162.5px'},
			width: '475px',
			data: {
				tenantApplications: this.tenantApplications,
				dataApplications: this.dataApplications.map(app => app.application.application),
			}
		})
			.afterClosed()
			.pipe(
				filter((result: {apps: ApplicationInstance[], result: boolean}) => result.result),
				tap((result: {apps: ApplicationInstance[], result: boolean}) => {
					const added = result.apps
						.filter(app => !this.dataApplications.find(dataApp => dataApp.application.application.applicationId === app.applicationId));
					const removed = this.dataApplications
						.filter(dataApp => !result.apps.find(app => app.applicationId === dataApp.application.application.applicationId))
						.map(dataApp => dataApp.application.application);
					if (added.length + removed.length > 0) {
						this.updateDataApplication(added, removed);
					}
				})
			).subscribe();
	}

	goToApp(applicationInstanceId: string) {
		this.currentTenantService.getCurrentTenantId().subscribe(tenantId => {
			this.router.navigate([APPLICATIONS_URL], {
				queryParams: {
					tenant: tenantId,
					applicationId: applicationInstanceId
				}
			});
		});
	}

	switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
