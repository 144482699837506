<div class="widget-button flex-center c-p" (click)="!disabled && !loading ? onClick.emit() : null" [class.button-widget-disabled]="disabled || loading">
	<ng-container *ngIf="!loading">
		<ng-container *ngTemplateOutlet="content"></ng-container>
	</ng-container>
	<ng-template #content>
		<ng-content></ng-content>
	</ng-template>
	<mat-spinner color="primary" *ngIf="loading" [diameter]="16"></mat-spinner>
</div>

