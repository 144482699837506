import {NgModule} from "@angular/core";
import {MatRippleModule} from "@angular/material/core";
import {CommonModule} from "@angular/common";
import {ContentLoaderModule} from "@ngneat/content-loader";
import {TranslateModule} from "@ngx-translate/core";
import {RouterModule} from "@angular/router";
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';
import {DeckContentLoaderModule} from 'src/app/modules/global/title-content-loader/deck-content-loader.module';
import {DiagramToolbarComponent} from 'src/app/modules/home/diagrams/diagram-toolbar/diagram-toolbar.component';
import {DiagramBreadcrumbModule} from 'src/app/modules/home/diagrams/diagram-breadcrumb/diagram-breadcrumb.module';
import {DropdownTriggerDirective} from 'src/app/modules/design-system/dropdown/dropdown-trigger.directive';
import {MatBadgeModule} from '@angular/material/badge';
import {DisplayAppStatusComponent} from "../../../design-system/display-app-status/display-app-status.component";
import {DiagramTooltipComponent} from "../diagram-tooltip/diagram-tooltip.component";
import {SnackbarModule} from "../../../global/snackbar/snackbar.module";
import {DiagramAppFlowsComponent} from "./diagram-app-flows.component";

@NgModule({
	declarations: [
		DiagramAppFlowsComponent
	],
  imports: [
    CommonModule,
    MatRippleModule,
    DeckContentLoaderModule,
    ContentLoaderModule,
    TranslateModule,
    RouterModule,
    DiagramToolbarComponent,
    DesignSystemModule,
    DiagramBreadcrumbModule,
    DropdownTriggerDirective,
    MatBadgeModule,
    DisplayAppStatusComponent,
    DiagramTooltipComponent,
    SnackbarModule,
  ],
	exports: [
		DiagramAppFlowsComponent
	]
})
export class DiagramAppFlowsModule {}
