<app-snackbar></app-snackbar>
<div class="w-100 h-100">
	<div class="header-height">
		<!-- Title loader -->
		<div class="pl-l pr-l pt-l pb-l" *ngIf="!data || _initializing" style="height: 37px">
			<app-title-content-loader></app-title-content-loader>
		</div>

		<!-- Title -->
		<div class="pl-l pr-l pt-l pb-l slider-header" *ngIf="data && !_initializing">
			<div class="flex-center w-100">
				<div class="data-icon flex-center" style="flex-shrink: 0;">
					<img width="24" src="assets/icons/data.svg" class="filter-accent">
				</div>
				<span class="ml-s w-100" style="display: inline-block; line-height: 0.8rem">
					<div class="flex-align-center mb-xs">
						<div
							class="primary flex-column flex-justify-center flex-align-start gap-XS text-overflow-ellipsis"
							style="max-width: 500px;">
							<label class="font-L fw-700">{{ dataDto.name }}</label>
							<label class="font-M fw-500">{{ dataDto.category?.name || '-' }}</label>
						</div>
					</div>
				</span>
			</div>

			<div class="flex-align-center gap-S">
				<app-icon-button type="stroked" background [img]="'assets/icons/close-drawer.svg'"
								 (click)="close()">
				</app-icon-button>
				<app-icon-button type="stroked" background [img]="'assets/icons/save.svg'"
								 *ngIf="settings"
								 (click)="onSaveSettings()">
				</app-icon-button>
				<app-icon-button type="stroked" background
								 [img]="!settings ? 'assets/icons/cog-inactive.svg' : 'assets/icons/cog-active.svg'"
								 [focused]="settings"
								 (click)="onOpenSettings()">
				</app-icon-button>
				<app-icon-button type="stroked" background color="danger" [img]="'assets/icons/trash.svg'"
								 (click)="openDeleteDataPopup()">
				</app-icon-button>
			</div>
		</div>
	</div>

	<div *ngIf="settings" [ngClass]="{'settings-triangle': settings}"></div>

	<div class="content-height border-box overflow-y above-triangle">
		<app-tabs-group [hidden]="settings"
						[selectedIndex]="0">
			<app-tab [icon]="'overview'" label="{{ 'menu.overview2' | translate }}">
				<div class="flex-column gap-XL" style="margin-top: 17px;">
					<app-data-overview-tab></app-data-overview-tab>
				</div>
			</app-tab>
		</app-tabs-group>
		<app-data-settings *ngIf="settings"></app-data-settings>
	</div>
</div>
