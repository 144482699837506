<div class="flex-center h-100">
	<div class="flex-justify-center flex-align-center onboarding-step-container h-100 w-100">
		<div class="flex-justify-center gap-M h-100 w-100 h-auto m-auto">
			<div *ngIf="!isMobile" class="onboarding-stepper-container flex-column gap-M mt-l">
				<div class="onboarding-stepper"></div>
				<div class="onboarding-stepper"></div>
				<div class="onboarding-stepper"></div>
				<div class="onboarding-stepper active-step"></div>
				<div *ngIf="stepCount >= 5" class="onboarding-stepper"></div>
				<div *ngIf="stepCount >= 6" class="onboarding-stepper"></div>
			</div>
			<div class="onboarding-step flex-column flex-space-between w-30 gap-L border-box pad-L">
                <div class="h-100">
                    <div class="flex-justify-end" style="height: 34px;">
                        @if (!isMobile) {
                            <app-icon-button (click)="closeLoading = true; onDismiss.emit(undefined)" img='assets/icons/close.svg' size='s' color='accent' type='stroked'
                                             [loading]='closeLoading' [background]='true' panelClass='img-size-30'></app-icon-button>
                        }
                    </div>
                    <div class="flex-center mb-xxl" [class.mt-xxl]="panelClass === 'big-button'">
                        @if (!isMobile) {
                            <img src="assets/onboarding/illustration-2.svg" class="onboarding-illustration" alt="ill2">
                        } @else {
                            <img src="assets/onboarding/illustration-4.svg" class="onboarding-illustration" alt="ill2" style="margin: 24px 0;">
                        }
                    </div>
                    <div class="font-XXL text-center mb-xxl onboarding-title">
                        @if (!isMobile) {
                            <span class="fw-700">{{ 'page.onboarding.step4.st1' | translate }}</span>
                        } @else {
                            <span class="fw-700">{{ 'page.onboarding.step4.mobile.st1' | translate }}{{currentUser.firstName}}&nbsp;!</span>
                        }
                    </div>
                    <div class="flex-justify-center text-center font-L fw-300" [style.line-height.px]="panelClass === 'big-button' ? 27 : 23">
                        <div class="onboarding-subtitle">
                            @if (!isMobile) {
                                {{ 'page.onboarding.step4.st2' | translate }}
                                <span class="fw-700">{{ 'page.onboarding.step4.st3' | translate }}</span>
                                {{ 'page.onboarding.step4.st4' | translate }}
                                <span class="fw-700">{{ 'page.onboarding.step4.st5' | translate }}</span>
                                {{ 'page.onboarding.step4.st6' | translate }}
                                <span class="fw-700">{{ 'page.onboarding.step4.st7' | translate }}</span>
                                {{ 'page.onboarding.step4.st8' | translate }}
                                <span class="fw-700">{{ 'page.onboarding.step4.st9' | translate }}</span>
                            } @else {
                                {{ 'page.onboarding.step4.mobile.st2' | translate }}<br>
                                <span class="fw-700">{{ 'page.onboarding.step4.mobile.st3' | translate }}</span>
                                {{ 'page.onboarding.step4.mobile.st4' | translate }}<br>
                                {{ 'page.onboarding.step4.mobile.st5' | translate }}<br>
                                <span class="fw-700">{{ 'page.onboarding.step4.mobile.st6' | translate }}</span>
                            }
                        </div>
                    </div>
                </div>
                <div class="flex-center w-100 pb-l">
                    @if (!isMobile) {
                        <app-text-button class="w-90" [panelClass]="panelClass" type='flat'
                                         (click)="openBrowserCorrespondingStore()">{{ 'page.onboarding.step4.st10' | translate }}
                        </app-text-button>
                    } @else {
                        @if (!emailSent) {
                            <app-text-button class="w-90" [panelClass]="panelClass" type='flat'
                                             [loading]="_sendingEmail"
                                             (click)="sendRedirectionEmail()">{{ 'page.onboarding.step4.mobile.st7' | translate }}
                            </app-text-button>
                        } @else {
                            <div class="flex-center" style="height: 42px;">
                                <span class="font-L fw-300 success">{{ 'page.onboarding.step4.mobile.st8' | translate }}</span>
                            </div>
                        }
                    }
                </div>
			</div>
		</div>
	</div>
</div>
