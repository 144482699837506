import {ActivatedRoute, Router} from "@angular/router";
import {Injectable} from "@angular/core";

@Injectable()
export class DataUrlService {
	constructor(protected activatedRoute: ActivatedRoute, protected router: Router) {
	}

	public getParameter(): boolean {
		let settingsPresent = this.activatedRoute.snapshot.queryParamMap.get('settings');
		return settingsPresent != null && settingsPresent != 'false';
	}

	navigateInAndOutOfParameter(parameter: boolean) {
		this.navigateInAndOut(parameter);
	}

	private navigateInAndOut(setting: boolean): void {
		this.router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParams: {settings: setting},
			queryParamsHandling: 'merge'
		});
	}
}
