import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChipsModule} from '../chips/chips/chips.module';
import {ApplicationIconModule} from '../application-icon/application-icon.module';
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';
import {TranslateModule} from "@ngx-translate/core";
import {ApplicationServerCardComponent} from "./application-server-card.component";

@NgModule({
  imports: [
    CommonModule,
    ChipsModule,
    ApplicationIconModule,
    DesignSystemModule,
    TranslateModule,
  ],
	declarations: [
		ApplicationServerCardComponent
	],
	exports: [
		ApplicationServerCardComponent
	]
})
export class ApplicationServerCardModule {
}
