<div *ngIf="initialized" class="flex-column gap-M">
	<div class="flex-align-start flex-space-between">
		<span class="font-MM fw-700">Données</span>
		<app-button-widget (onClick)="openDialog()" [loading]="_initializing || _loading">
			<img width="12" src="assets/icons/add.svg" class="filter-primary" alt="">
		</app-button-widget>
	</div>
	@for (data of dataList; track data.id) {
		<div class="data-card">
			<div (click)="goToData(data.id)" class="pl-s pr-m pt-s pb-s flex-space-between flex-align-center gap-S c-p greyed-hover-2">
				<div class="flex-align-center c-p gap-L">
					<div class="flex-center gap-S" style="padding-left: 3px;">
						<div class="data-icon flex-center">
							<img width="22" class="filter-accent" src="assets/icons/data.svg">
						</div>
						<div class="flex-column justify-center gap-XS" style="max-width: 300px;">
							<span class="fw-700 font-M text-overflow-ellipsis">
								{{ data.name }}
							</span>
							<span class="font-S text-overflow-ellipsis">
								{{ data.category?.name || '-' }}
							</span>
						</div>
					</div>
					<div class="flex-align-center gap-S">
						<div *ngFor="let type of data.types" class="data-type-bubble flex-center" [ngClass]="type">
							<span class="font-S">{{ type.toUpperCase() }}</span>
						</div>
					</div>
				</div>
				<div class="flex-align-center gap-XL">
					<div style="margin-left: auto">
						<div class="disp-f justify-center a-i-c">
							<img src="assets/icons/arrow-right.svg">
						</div>
					</div>
				</div>
			</div>
		</div>
	} @empty {
		<div class="w-100 flex-center flex-column" style="gap: 30px; height: 280px;">
			<img src="assets/illustrations/no-data2.svg" width="150">
			<span class="grey">{{ 'page.data.noData' | translate }}</span>
		</div>
	}
</div>
<div class="pad-M" *ngIf="!initialized">
	<app-dashboard-page-loader></app-dashboard-page-loader>
</div>
