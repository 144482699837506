
export interface TenantAccount {
  account: Account;
  role: TenantAccountRole;
  pending: boolean;
  createdAt: string;
  isWeeklySubscriber: boolean;
}

export interface Account {
  accountId: string;
  email: string;
  firstName: string;
  lastName: string;
  provider: string;
}

export interface TenantAccountRoleForm {
  role: string;
}

export interface TenantAccountWeeklyReportForm {
  isSubscribed: boolean;
}

export enum TenantAccountRole {
  READ_ONLY ='read_only',
  READ_WRITE = 'read_write',
  ADMIN = 'admin',
	NO_ACCESS = 'no_access'
}
