import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {NgIf, NgTemplateOutlet} from "@angular/common";

@Component({
  selector: 'app-button-widget',
	imports: [
		MatProgressSpinner,
		NgIf,
		NgTemplateOutlet
	],
  templateUrl: './button-widget.component.html',
  styleUrl: './button-widget.component.scss'
})
export class ButtonWidgetComponent {
	@Input() disabled: boolean = false;
	@Input() loading: boolean = false;
	@Output() onClick = new EventEmitter<void>();
}
