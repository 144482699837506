import {Injectable, Type} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';

@Injectable()
export class RightSliderService {
	constructor() {
	}

	public get open() { return this._open };

	protected _open: boolean = false;

	protected subscriptions: Array<Subscription> = []

	protected componentChange = new Subject<{component: Type<any>, data?: any, onOutputEvent?: Subject<any>, onClose: Subject<any>}>();

	openComponent<U>(component: Type<any>, data?:U, onOutputEvent?: Subject<any>): Observable<any> {
		const onClose: Subject<any> = new Subject<any>();
		this._open = true;
		this.componentChange.next({component, data, onOutputEvent, onClose});
		return onClose.asObservable();
	}

	isOpened(): boolean {
		return this._open;
	}

	onComponentChange() : Observable<{component: Type<any>, data?: any, onClose: Subject<any>, onOutputEvent?: Subject<any>}> {
		return this.componentChange.asObservable();
	}

	close(): void {
		this._open = false;
	}
}
