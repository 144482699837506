<div class="pl-l pr-l pt-l pb-l primary">
	<div class="disp-f a-i-c j-c-s f-d-r mb-m">
		<app-chips [square]="true" [size]="'xl'">
			<img src="assets/icons/data.svg" class="filter-grey" height="25px">
		</app-chips>
		<div class="flex-column gap-XS ml-s">
			<div class="font-L fw-700">{{ 'page.data.linkAData' | translate }}</div>
			<div class="font-M fw-300">{{ 'page.data.linkADataSubtitle' | translate }}</div>
		</div>
	</div>

	<div class="mb-m">
		<app-text-input [placeholder]="'page.data.search' | translate"
						[input]="searchInput"></app-text-input>
	</div>

	<div class="bg-rounded-2" style="max-height: calc(80vh - 300px); min-height: calc(80vh - 300px); overflow: auto">
		<div>
			<div *ngFor="let data of filteredData; let index = index">
				<div class="pad-M flex-align-center flex-space-between">
					<div class="disp-f a-i-c j-c-c gap-S overflow-hidden">
						<div class="data-icon flex-center">
							<img width="16" class="filter-accent" src="assets/icons/data.svg">
						</div>
						<span class="font-M fw-700 text-overflow-ellipsis">{{ data.name }}</span>
					</div>
					<div class="flex-align-center gap-M">
						<div class="flex-align-center gap-S">
							<div [tooltip]="type.toUpperCase()" [tooltipOffset]="5" *ngFor="let type of data.types" class="data-type" [ngClass]="type"></div>
						</div>
						<mat-checkbox class="custom-checkbox" [checked]="isDataSelected(data)" (change)="onDataSelectChange(data, $event)"></mat-checkbox>
					</div>
				</div>
				<hr *ngIf="index < filteredData.length - 1">
			</div>
			<div *ngIf="filteredData.length === 0" style="height: calc(80vh - 300px);">
				<div class="disp-f a-i-c j-c-c h-100">
					<span class="font-M fw-500 grey">{{ 'page.data.noData' | translate }}</span>
				</div>
			</div>
		</div>
	</div>

	<div class="w-100 disp-f f-d-r j-c-sb a-i-c mt-l gap-M">
		<app-text-button class="w-50" type="stroked" (click)="onDismiss()">{{ 'button.cancel' | translate }}
		</app-text-button>
		<app-text-button class="w-50" type="flat" (click)="onConfirm()">{{ 'button.save' | translate }}
		</app-text-button>
	</div>
</div>
