<app-snackbar *ngIf="!isRightSliderOpened()"></app-snackbar>
<div class="flex-column gap-M pad-L">
	<div class="flex gap-M header-container w-100">
		<div class="flex-align-center search-bar-container bkg-white b-radius-9">
			<app-text-button type="default" color="primary" class="search-btn" panelClass="h-100-imp pad-0-imp"
							 [disabled]="_initializing"
							 (click)="openSearchPanel(input)">
				<div class="flex-center">
					<img src="assets/icons/search-md.svg" width="18" height="18" alt=""/>
				</div>
			</app-text-button>
			<div class="flex-space-between">
				<input #input class="search-input" [ngClass]="{'display-input': openSearch}"
					   [formControl]="$any(filterForm.get(form.name))"
					   [placeholder]="openSearch ? ('page.data.search'|translate) : ''">
				<app-text-button (click)="clearInput()" *ngIf="openSearch && searchValue" type="default"
								 color="primary"
								 panelClass="h-100-imp pad-0-imp" class="delete-text-button mr-xs">
					<div class="flex-center">
						<img src="assets/icons/close.svg" alt="">
					</div>
				</app-text-button>
				<div *ngIf="openSearch && !searchValue" style="width: 35px; height: 30px;">
				</div>
			</div>
		</div>
		<div class="flex-align-center filter-bar bkg-white b-radius-9 w-100">
			<div class="flex-align-center h-100 filter-btn-container" style="z-index: 2;">
				<app-dropdown #filterDropdown>
					<div class="flex-column gap-S" style="width: 300px;"
						 *ngIf="!_initializing && !!list">
						<div class="flex-column gap-S">
							<span class="font-M fw-500 grey">{{ 'page.data.types' | translate }}</span>
							<app-multi-select [inputControl]="$any(filterForm.get(form.type))" multiple [addMinHeight]="true">
								<app-select-trigger appearance="field">{{!$any(filterForm.get(form.type)).value[0] ? '-' : (( ($any(filterForm.get(form.type)).value[0].toUpperCase()) | translate)  + ($any(filterForm.get(form.type)).value.length > 1 ? ' (+' + ($any(filterForm.get(form.type)).value.length - 1) + ')' : ''))}}</app-select-trigger>
								<app-select-option *ngFor="let type of list.type" [value]="type">
									{{ 'page.data.typesValues.' + type.toLowerCase() | translate }}
								</app-select-option>
							</app-multi-select>
						</div>

						<div class="flex-column gap-S">
							<span class="font-M fw-500 grey">{{ 'page.data.criticality' | translate }}</span>
							<app-multi-select [inputControl]="$any(filterForm.get(form.criticality))" multiple>
								<app-select-trigger appearance="field">{{!$any(filterForm.get(form.criticality)).value[0] ? '-' : ((('page.data.criticalityValues.' + $any(filterForm.get(form.criticality)).value[0].toLowerCase())|translate) + ($any(filterForm.get(form.criticality)).value.length > 1 ? ' (+' + ($any(filterForm.get(form.criticality)).value.length - 1) + ')' : ''))}}</app-select-trigger>
								<app-select-option *ngFor="let level of list.criticality" [value]="level">
									<div class="flex-align-center gap-M">
										<img src="assets/icons/criticality-{{level.toLowerCase()}}.svg" height="16" alt=""/>
										<span>{{('page.data.criticalityValues.' + level.toLowerCase())|translate}}</span>
									</div>
								</app-select-option>
							</app-multi-select>
						</div>

						<div class="flex-column gap-S">
							<span class="font-M fw-500 grey">{{ 'page.data.applications' | translate }}</span>
							<app-multi-select [inputControl]="$any(filterForm.get(form.application))" multiple [addMinHeight]="true">
								<app-select-search [searchControl]="$any(filterForm.get(form.searchApplication))"></app-select-search>
								<app-select-trigger appearance="field">{{!$any(filterForm.get(form.application)).value[0] ? '-' : (($any(filterForm.get(form.application)).value[0].name | translate)  + ($any(filterForm.get(form.application)).value.length > 1 ? ' (+' + ($any(filterForm.get(form.application)).value.length - 1) + ')' : ''))}}</app-select-trigger>
								<app-select-option *ngFor="let application of list.applications" [value]="application">
									<div class="flex-align-center gap-S">
										<app-display-logo [logo]="application.logo"></app-display-logo>
										<span>{{ application.name }}</span>
									</div>
								</app-select-option>
							</app-multi-select>
						</div>

						<div class="flex-column gap-S">
							<span class="font-M fw-500 grey">{{ 'page.data.category' | translate }}</span>
							<app-multi-select [inputControl]="$any(filterForm.get(form.category))" multiple [addMinHeight]="true">
								<app-select-search [searchControl]="$any(filterForm.get(form.searchCategory))"></app-select-search>
								<app-select-trigger appearance="field">{{!$any(filterForm.get(form.category)).value[0] ? '-' : (($any(filterForm.get(form.category)).value[0].name | translate)  + ($any(filterForm.get(form.category)).value.length > 1 ? ' (+' + ($any(filterForm.get(form.category)).value.length - 1) + ')' : ''))}}</app-select-trigger>
								<app-select-option *ngFor="let category of list.categories" [value]="category">
									{{ category.name }}
								</app-select-option>
							</app-multi-select>
						</div>

						<div class="flex-column gap-S">
							<span class="font-M fw-500 grey">{{ 'page.data.privacy' | translate }}</span>
							<app-multi-select [inputControl]="$any(filterForm.get(form.privacy))" multiple [addMinHeight]="true">
								<app-select-trigger appearance="field">{{!$any(filterForm.get(form.privacy)).value[0] ? '-' : (('page.data.privacyValues.' + $any(filterForm.get(form.privacy)).value[0] | translate) + ($any(filterForm.get(form.privacy)).value.length > 1 ? ' (+' + ($any(filterForm.get(form.privacy)).value.length - 1) + ')' : ''))}}</app-select-trigger>
								<app-select-option *ngFor="let privacy of list.privacy" [value]="privacy">
									{{'page.data.privacyValues.' + privacy | translate}}
								</app-select-option>
							</app-multi-select>
						</div>
					</div>


					<div class="flex w-100" style="margin: 20px -20px -20px -20px; width: calc(100% + 40px);">
						<app-text-button color="primary" class="w-100"
										 panelClass="bkg-light-grey-2-imp b-radius-bottom-9"
										 (click)="clearFilters(); filterDropdown.close();">
							<div class="flex-align-center gap-S" style="margin-left: -15px;">
								<img src="assets/icons/close.svg" width="12" height="12" alt=""/>
								<span>{{ 'page.data.clearFilters'|translate }}</span>
							</div>
						</app-text-button>
					</div>
				</app-dropdown>
				<app-text-button type="default" color="primary" class=filter-btn panelClass="h-100-imp"
								 [badge]="activeFilters.length > 0 ? activeFilters.length : undefined"
								 [disabled]="_initializing || !list"
								 [dropdownTrigger]="filterDropdown">
					<div class="flex-center gap-S">
						<span>{{ 'page.data.filters'|translate }}</span>
						<img src="assets/icons/filter-lines.svg" width="16" height="16" alt=""/>
					</div>
				</app-text-button>
			</div>

			<div class="flex-align-center w-100 h-100 border-light-grey-left pad-left-S filter-panel" style="z-index: 1; transition: 0s;" #responsiveFilterPanel
				 [ngClass]="{'filter-panel-open': openFilters}" [style.top.px]="(responsiveFilterPanel.offsetHeight / 2) - 26.5" >
				<div class="flex-wrap flex-align-center h-100 gap-S" style="width: calc(100% - 30px); margin: 12px 0;"
					 [ngStyle]="{'max-height': openFilters ? '100%' : '28px'}" [ngClass]="{'overflow-hidden': !openFilters}">
					@for (filter of activeFilters; track filter.id) {
						<div class="flex-center horizontal-bar-L bkg-light-grey-2 pad-0-M gap-M">
							@if (filter.form === form.type) {
								<span class="font-S fw-500">{{ filter.value.toUpperCase() }}</span>
							} @else if (filter.form === form.criticality) {
								<span class="font-S fw-500">{{ 'page.data.criticalityValues.' + filter.value.toLowerCase() | translate }} {{ 'global.criticality' | translate }}</span>
							} @else if (filter.form === form.privacy) {
								<span class="font-S fw-500">{{ 'page.data.privacyValues.' + filter.value | translate }}</span>
							} @else {
								<span class="font-S fw-500">{{ filter.value }}</span>
							}
							<img class="filter-grey c-p" src="assets/icons/close.svg" width="9" height="9" alt="" style="z-index: 9999"
								 (click)="removeFilter(filter)"/>
						</div>
					}
				</div>
				<div class="flex-center b-radius-right-9 c-p" style="width: 30px; height: 30px;"
					 (click)="openFilters = !openFilters" *ngIf="activeFilters.length">
					<img src="assets/icons/arrow-right-black.svg" class="rotate-90 filter-grey" height="12" alt="">
				</div>
			</div>
		</div>
		<div class="flex-align-center filter-bar bkg-white b-radius-9" style="padding: 3px;">
			<app-text-button type="default" color="primary" style="width: 43px; height: 43px;"
							 panelClass="h-100-imp pad-0-imp"
							 [matMenuTriggerFor]="selectMenu" [disabled]="_initializing || _loading"
							 [loading]="_exporting">
				<div class="flex-center">
					<img src="assets/icons/dots-vertical.svg" height="18" alt=""/>
				</div>
			</app-text-button>
			<mat-menu #selectMenu class="mat-menu-custom"
					  xPosition="before" yPosition="below">
				<div class="flex-align-center gap-M greyed-hover b-radius-6 c-p" style="padding: 6px;"
					 (click)="exportCsv()">
					<img src="assets/icons/share.svg" width="18" height="18" alt=""/>
					<div class="font-M fw-500">{{ 'page.data.exportCSV'|translate }}</div>
				</div>
				<div class="flex-align-center gap-M greyed-hover b-radius-6 c-p" style="padding: 6px;"
					 (click)="exportXlsx()">
					<img src="assets/icons/layout-grid-02.svg" width="18" height="18" alt=""/>
					<div class="font-M fw-500">{{ 'page.data.exportXLSX'|translate }}</div>
				</div>
			</mat-menu>
		</div>
		<app-text-button type="flat" color="accent" panelClass="h-100-imp b-radius-9"
						 [disabled]="_initializing"
						 (click)="openCreateData()">
			<span class="font-M fw-500">{{ 'page.data.addData' | translate }}</span>
		</app-text-button>
	</div>
	<div *ngIf="!_loading"
		 class="flex-column app-list-content bkg-white">
		<table class="app-list-table" aria-describedby="apps"
			   [style.height]="displayedDataRows.length <= 0 ? 'calc(100vh - 190px)' : ''"
			   [style.min-height]="displayedDataRows.length <= 0 ? '350px' : ''">
			<tr class="app-list-header">
				<th>
					<div class="flex-align-center gap-S pad-0-S greyed-hover sort-column c-p"
						 style="border-top-left-radius: 14px; padding-left: 67px;"
						 [ngClass]="{'sort-active': sortFormValue.column === column.NAME}"
						 (click)="sortBy(column.NAME)">
						<span
							class="font-M fw-500 text-left grey">{{ 'page.data.name' | translate }}</span>
						<img src="assets/icons/arrow-right-black.svg" class="filter-grey" height="10"
							 alt=""
							 [ngStyle]="{'transform': 'rotate(' + (sortFormValue.direction === direction.DESC && sortFormValue.column === column.NAME ? '90' : '-90') + 'deg)'}">
					</div>
				</th>
				<th>
					<div class="flex-align-center gap-S pad-0-S greyed-hover sort-column c-p"
						 [ngClass]="{'sort-active': sortFormValue.column === column.CRITICALITY}"
						 (click)="sortBy(column.CRITICALITY)">
						<div class="flex-align-center" style="gap: 2px">
							<span class="font-M fw-500 text-left grey">{{ 'page.data.criticality' | translate }}</span>
						</div>
						<img src="assets/icons/arrow-right-black.svg" class="filter-grey" height="10"
							 alt=""
							 [ngStyle]="{'transform': 'rotate(' + (sortFormValue.direction === direction.DESC && sortFormValue.column === column.CRITICALITY ? '90' : '-90') + 'deg)'}">
					</div>
				</th>
				<th>
					<div class="flex-align-center gap-S pad-0-S greyed-hover sort-column c-p"
						 [ngClass]="{'sort-active': sortFormValue.column === column.PRIVACY}"
						 (click)="sortBy(column.PRIVACY)">
					<span
						class="font-M fw-500 text-left grey">{{ 'page.data.privacy' | translate }}</span>
						<img src="assets/icons/arrow-right-black.svg" class="filter-grey" height="10"
							 alt=""
							 [ngStyle]="{'transform': 'rotate(' + (sortFormValue.direction === direction.DESC && sortFormValue.column === column.PRIVACY ? '90' : '-90') + 'deg)'}">
					</div>
				</th>
				<th>
					<div class="flex-align-center gap-S pad-0-S greyed-hover sort-column c-p"
						 [ngClass]="{'sort-active': sortFormValue.column === column.TYPE}"
						 (click)="sortBy(column.TYPE)">
					<span
						class="font-M fw-500 text-left grey">{{ 'page.data.types' | translate }}</span>
						<img src="assets/icons/arrow-right-black.svg" class="filter-grey" height="10"
							 alt=""
							 [ngStyle]="{'transform': 'rotate(' + (sortFormValue.direction === direction.DESC && sortFormValue.column === column.TYPE ? '90' : '-90') + 'deg)'}">
					</div>
				</th>
				<th>
					<div class="flex-align-center gap-S pad-0-S greyed-hover sort-column c-p"
						 [ngClass]="{'sort-active': sortFormValue.column === column.APPLICATIONS}"
						 (click)="sortBy(column.APPLICATIONS)">
						<span
							class="font-M fw-500 text-left grey">{{ 'page.data.applications' | translate }}</span>
						<img src="assets/icons/arrow-right-black.svg" class="filter-grey" height="10"
							 alt=""
							 [ngStyle]="{'transform': 'rotate(' + (sortFormValue.direction === direction.DESC && sortFormValue.column === column.APPLICATIONS ? '90' : '-90') + 'deg)'}">
					</div>
				</th>
				<th>
					<div class="flex-align-center gap-S pad-0-S greyed-hover sort-column c-p"
						 [ngClass]="{'sort-active': sortFormValue.column === column.LAST_UPDATE}"
						 (click)="sortBy(column.LAST_UPDATE)">
					<span
						class="font-M fw-500 text-left grey">{{ 'page.data.lastUpdate' | translate }}</span>
						<img src="assets/icons/arrow-right-black.svg" class="filter-grey" height="10"
							 alt=""
							 [ngStyle]="{'transform': 'rotate(' + (sortFormValue.direction === direction.DESC && sortFormValue.column === column.LAST_UPDATE ? '90' : '-90') + 'deg)'}">
					</div>
				</th>
			</tr>
			@if (dataRows.length > 0) {
				@for (data of displayedDataRows; track data.data.id) {
					<tr class="app-list-row font-M fw-500 greyed-hover c-p"
						(click)="openDataDrawer(data.data.id)">
						<td style="width: 20%;">
							<div class="flex gap-M" style="padding-left: 3px;">
								<div class="data-icon flex-center">
									<img width="22" class="filter-accent" src="assets/icons/data.svg">
								</div>
								<div class="flex-column justify-center gap-XS" style="max-width: 300px;">
									<span class="fw-700 font-M text-overflow-ellipsis">
										{{ data.data.name }}
									</span>
									<span class="font-S text-overflow-ellipsis">
										{{ data.data.category?.name || '-' }}
									</span>
								</div>
							</div>
						</td>
						<td style="width: 10%;">
							<div class="flex">
								<app-chips-criticality [criticality]="data.data.criticality" short></app-chips-criticality>
							</div>
						</td>
						<td style="width: 15%;">
							<div class="flex">
								<app-chips-text *ngIf="data.data.privacy" [bold]="false" [centered]="false">
									<span class="primary">{{ 'page.data.privacyValues.' + data.data.privacy | translate }}</span>
								</app-chips-text>
								<span *ngIf="!data.data.privacy">-</span>
							</div>
						</td>
						<td style="width: 15%;">
							<div style="max-width: 210px;" class="text-overflow-ellipsis">
								<span *ngIf="data.data.types.length <= 0">-</span>
								<div *ngIf="data.data.types.length > 0" class="flex-align-center gap-S">
									<div *ngFor="let type of data.data.types" [tooltip]="'page.data.typesValues.' + type | translate" tooltipPosition="bottom" [tooltipOffset]="5" class="data-type-bubble flex-center" [ngClass]="type">
										<span class="fw-700 font-S">{{ type.toUpperCase() }}</span>
									</div>
								</div>
							</div>
						</td>
						<td style="width: 25%">
							<div class="flex-align-center gap-S">
								<span *ngIf="data.applications.length <= 0">-</span>
								<div *ngFor="let application of data.applications | slice:0:4; let i = index" class="data-app flex-center">
									<app-display-logo [logo]="application.logo" [size]="30" [tooltip]="application.name" tooltipPosition="right" [tooltipOffset]="10"></app-display-logo>
								</div>
								<div *ngIf="data.applications.length > 4" [tooltipTriggerFor]="appsTooltip" [tooltipOffset]="10" tooltipPosition="right" class="more-apps flex-center">
									<span class="fw-700 font-S">+ {{ data.applications.length - 4 }}</span>
									<app-tooltip #appsTooltip>
										<div class="flex-column flex-align-start gap-M">
											<div *ngFor="let app of data.applications" class="flex-align-center gap-S">
												<app-display-logo [logo]="app.logo" [size]="24"></app-display-logo>
												<span class="font-S">{{ app.name }}</span>
											</div>
										</div>
									</app-tooltip>
								</div>
							</div>
						</td>
						<td style="width: 15%">
							<div class="flex-space-between pad-right-S" style="padding-left: 35px">
								<span *ngIf="data.data.updatedAt">{{ data.data.updatedAt | date:'dd/MM/YYYY' }}</span>
								<span *ngIf="!data.data.updatedAt">-</span>
								<img src="assets/icons/arrow-right.svg" width="9" alt=""/>
							</div>
						</td>
					</tr>
				} @empty {
					<tr>
						<td colspan="6">
							<div class="flex-center flex-column gap-XL">
								<img src="assets/illustrations/empty-app.svg">
								<span class="grey">{{ 'page.data.noDataSearch' | translate }}</span>
							</div>
						</td>
					</tr>
				}
			} @else {
				<tr>
					<td colspan="6">
						<div class="flex-center flex-column gap-XL">
							<img src="assets/illustrations/no-server-network.svg">
							<span
								class="grey">{{ 'page.data.noDataInKabeen' | translate }}</span>
							<app-text-button type="flat" color="accent"
											 (click)="openCreateData()">{{ 'page.data.addMyFirstData' | translate }}
							</app-text-button>
						</div>
					</td>
				</tr>
			}
		</table>
		<div *ngIf="displayedDataRows.length > 0" class="app-list-footer bkg-white">
			<div class="font-M fw-500 pad-M grey">
				Total {{ displayedDataRows.length }} {{ 'page.data.data' | translate }}
			</div>
		</div>
	</div>
	<div *ngIf="_loading" class="flex-justify-center flex-align-center flex-column gap-20"
		 style="height: calc(100vh - 250px)">
		<app-spinner color="grey" [scale]="0.8"></app-spinner>
	</div>
</div>



