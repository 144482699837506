import {Component, inject} from '@angular/core';
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {DesignSystemModule} from "../../../../../design-system/design-system.module";
import {DeckContentLoaderModule} from "../../../../../global/title-content-loader/deck-content-loader.module";
import {Data, DataApplicationForm, DataService} from "../../../../../../services/back/data.service";
import {finalize, forkJoin, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {CurrentTenantService} from "../../../../../../services/front/current-tenant.service";
import {Router} from "@angular/router";
import {
	ApplicationDetailData,
	ApplicationDetailService
} from "../../../../../../services/front/application-detail.service";
import {filter} from "rxjs/operators";
import {DATA_URL} from "../../../../../../models/home/navigation.model";
import {ButtonWidgetComponent} from "../../../../../design-system/button-widget/button-widget.component";
import {MatDialog} from "@angular/material/dialog";
import {AddDataToAppComponent} from "./add-data-to-app/add-data-to-app.component";
import {SnackbarService} from "../../../../../../services/front/snackbar.service";

@Component({
  selector: 'app-application-data',
	imports: [
		TranslateModule,
		CommonModule,
		DesignSystemModule,
		DeckContentLoaderModule,
		ButtonWidgetComponent
	],
  templateUrl: './application-data.component.html',
  styleUrl: './application-data.component.scss'
})
export class ApplicationDataComponent {
	initialized: boolean = false;
	_initializing: boolean;
	_loading: boolean;

	tenantId: string;
	applicationId: string;
	isEditor: boolean = false;

	tenantData: Data[] = [];
	dataList: Data[] = [];

	dataService = inject(DataService);
	currentTenantService = inject(CurrentTenantService);
	applicationDetailService = inject(ApplicationDetailService);
	router = inject(Router);
	dialog = inject(MatDialog);
	snackbarService = inject(SnackbarService);
	translateService = inject(TranslateService)

	subscriptions: Subscription = new Subscription();

	ngOnInit(): void {
		this.subscriptions.add(this.applicationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscriptions.add(this.applicationDetailService.getApplicationDetailDataChanges()
			.pipe(tap(data => this.setApplicationDetailData(data)))
			.subscribe(() => this.initialize()));
	}

	private setApplicationDetailData(data: ApplicationDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.applicationId = data.instance.applicationId;
	}

	initialize(): void {
		this.subscriptions.add(this.switchLoading()
			.pipe(
				switchMap(() => forkJoin([
					this.dataService.getApplicationData(this.tenantId, this.applicationId),
					this.dataService.getAllTenantData(this.tenantId)
				])),
				tap(([dataList, tenantData]) => {
					this.dataList = dataList;
					this.tenantData = tenantData;
				}),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.initialized = true));
	}

	goToData(dataId: string): void {
		this.router.navigate([DATA_URL], {
			queryParams: {
				tenant: this.tenantId,
				dataId: dataId
			}
		});
	}

	openDialog(): void {
		this.dialog.open(AddDataToAppComponent, {
			position: {right: '162.5px'},
			width: '475px',
			data: {
				tenantData: this.tenantData,
				applicationData: this.dataList,
			}
		})
			.afterClosed()
			.pipe(
				filter((result: {data: Data[], result: boolean}) => result.result),
				tap((result: {data: Data[], result: boolean}) => {
					const added = result.data
						.filter(data => !this.dataList.find(appData => appData.id === data.id));
					const removed = this.dataList
						.filter(appData => !result.data.find(data => data.id === appData.id));
					if (added.length + removed.length > 0) {
						this.updateApplicationData(added, removed);
					}
				})
			).subscribe();
	}

	updateApplicationData(added: Data[], removed: Data[]): void {
		const form: DataApplicationForm = {
			added: added.map(app => app.id),
			removed: removed.map(app => app.id)
		};
		this.dataService.updateApplicationData(this.tenantId, this.applicationId, form)
			.pipe(
				filter(success => success),
				tap(() => this.snackbarService.show(this.translateService.instant('page.application.detail.update.success')))
			).subscribe(() => this.applicationDetailService.refreshApplicationInstance());
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}
}
