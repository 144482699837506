import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {QueryRangeType} from 'src/app/services/back/tenant-finance.service';

@Component({
	selector: 'application-mini-widget',
	standalone: false,
	templateUrl: './application-mini-widget.component.html',
	styleUrls: ['./application-mini-widget.component.scss']
})
export class ApplicationMiniWidgetComponent implements OnInit {
	// TODO @TAN REWORK / type
	@Input() public widget: TendencyWidgetContent | undefined;
	@Input() public type: string | undefined;
	@Input() public title: string | undefined;
	@Input() public displayLoaderAsData = false;
	@Input() public displayTendency = true;
	@Input() public subtitle: string | undefined;
	@Input() displayQueryType: boolean = true;

	constructor(private translate: TranslateService) { }

	ngOnInit(): void {
	}

	getAvailability(value: number) {
		return value >= 99 ? this.translate.instant('page.dashboard.typology.good') : value >= 95 && value < 99 ? this.translate.instant('page.dashboard.typology.needs_improvement') : this.translate.instant('page.dashboard.typology.poor')
	}

	getPerformance(value: number) {
		return value == 1 ? this.translate.instant('page.dashboard.typology.goodPerf') : value == 2 ? this.translate.instant('page.dashboard.typology.needs_improvementPerf') : this.translate.instant('page.dashboard.typology.poorPerf')
	}

	getUsage(value: number) {
		return value.toFixed(0)
	}
}

export interface TendencyWidgetContent {
	value: number|undefined|null;
	tendency?: number;
	day?: QueryRangeType;
}
