<div *ngIf="!_loading" class="onboarding-container pl-l pt-l pb-l pr-l" style="overflow-x: hidden; scrollbar-gutter: stable">
	<div class="flex-space-between">
		<img [ngSrc]="environment.applicationLogo" priority width="44" height="42" alt="logo">
		<div>
            <app-multi-select class="w-100" [inputControl]="languageFormControl">
                <app-select-trigger appearance="field">
                    <span>{{ languageValue }}</span>
                </app-select-trigger>
                <app-select-option [value]="'en'">
                    <span>English</span>
                </app-select-option>
                <app-select-option [value]="'fr'">
                    <span>Français</span>
                </app-select-option>
            </app-multi-select>
			<!--<button mat-button [matMenuTriggerFor]="lang">
				<mat-icon>arrow_drop_down</mat-icon>
				{{ 'global.currentLanguage' | translate }}
			</button>
			<mat-menu #lang="matMenu">
				<button class="w-100" [class.selected]="translateService.currentLang === 'en'" (click)="onChangeLang('en')" mat-menu-item>English</button>
				<button class="w-100" [class.selected]="translateService.currentLang !== 'en'" (click)="onChangeLang('fr')" mat-menu-item>Français</button>
			</mat-menu>-->
		</div>
	</div>
	<div *ngIf="!otherTenantsExisting" style="height: calc(100% - 47.5px);">
		<div [@inOutAnimation] *ngIf="currentStep === 'Step 1'" class="w-100 h-100">
			<app-onboarding-step-1 [stepCount]="6" [isMobile]="isMobile" (onNextClick)="scrollToStep($event)"></app-onboarding-step-1>
		</div>
		<div [@inOutAnimation] *ngIf="currentStep === 'Step 2'" class="w-100 h-100">
			<app-onboarding-step-2 [stepCount]="6" [isMobile]="isMobile" (onNextClick)="setSecondStepForm($event)"></app-onboarding-step-2>
		</div>
		<div [@inOutAnimation] *ngIf="currentStep === 'Step 3'" class="w-100 h-100">
			<app-onboarding-step-3 [stepCount]="6" [isMobile]="isMobile" (onNextClick)="scrollToStep($event)" [secondStepForm]="secondStepForm"></app-onboarding-step-3>
		</div>
		<div [@inOutAnimation] *ngIf="currentStep === 'Step 4'" class="w-100 h-100">
			<app-onboarding-step-4 [stepCount]="6" [isMobile]="isMobile" (onNextClick)="scrollToStep($event)" (onDismiss)="onComplete($event)"></app-onboarding-step-4>
		</div>
		<div [@inOutAnimation] *ngIf="currentStep === 'Step 5'" class="w-100 h-100">
			<app-onboarding-step-5 [stepCount]="6" [isMobile]="isMobile" (onNextClick)="scrollToStep($event)" (onDismiss)="onComplete($event)"></app-onboarding-step-5>
		</div>
		<div [@inOutAnimation] *ngIf="currentStep === 'Step 6'" class="w-100 h-100">
			<app-onboarding-step-6 [isMobile]="isMobile" (onNextClick)="onComplete($event)" (onDismiss)="onComplete($event)"></app-onboarding-step-6>
		</div>
	</div>
	<div *ngIf="otherTenantsExisting" style="height: calc(100% - 47.5px);">
		<div [@inOutAnimation] *ngIf="currentStep === 'Step 1'" class="w-100 h-100">
			<app-onboarding-step-1 [stepCount]="3" [isMobile]="isMobile" [canClose]="true" (onNextClick)="scrollToStep($event)"></app-onboarding-step-1>
		</div>
		<div [@inOutAnimation] *ngIf="currentStep === 'Step 2'" class="w-100 h-100">
			<app-onboarding-step-2 [stepCount]="3" [isMobile]="isMobile" [canClose]="true" (onNextClick)="setSecondStepForm($event)"></app-onboarding-step-2>
		</div>
		<div [@inOutAnimation] *ngIf="currentStep === 'Step 3'" class="w-100 h-100">
			<app-onboarding-step-3 [stepCount]="3" [isMobile]="isMobile" [canClose]="true" [secondStepForm]="secondStepForm" [finalStep]="true" (onComplete)="onComplete(undefined, $event)"></app-onboarding-step-3>
		</div>
	</div>
</div>
