import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import * as Bowser from "bowser";

@Component({
  selector: 'app-text-area',
	standalone: false,
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent {
	@Input() input: FormControl;
	@Input() label: string;
	@Input() rows?: number = 3;
	@Input() placeholder: string;
	@Input() forceFocus: boolean = false;

	@Output() blur = new EventEmitter<void>();
	@Output() enter = new EventEmitter<void>();

	isChromeBased: boolean;

	ngAfterViewInit() {
		this.isChromeBased = Bowser.parse(window.navigator.userAgent).engine.name === 'Blink';
	}
}
