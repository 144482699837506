import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
	ServerTechnicalInformationComponent
} from "./server-technical-information/server-technical-information.component";
import {InfrastructureOverview, Server} from "../../../../../../services/model/server.model";
import {ServerDetailData, ServerDetailService} from "../../../../../../services/front/server-detail.service";
import {ServerUrlService} from "../../../../../../services/front/server-url.service";
import {ServerService} from "../../../../../../services/back/server.service";
import {finalize, forkJoin, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {ServerLoadGraphComponent} from "./server-load-graph/server-load-graph.component";
import {ServerStorageGraphComponent} from "./server-storage-graph/server-storage-graph.component";
import {DeckContentLoaderModule} from "../../../../../global/title-content-loader/deck-content-loader.module";
import {TranslateModule} from "@ngx-translate/core";

@Component({
	selector: 'app-server-operation-tab',
	standalone: true,
	imports: [CommonModule, ServerTechnicalInformationComponent, ServerLoadGraphComponent, ServerStorageGraphComponent, DeckContentLoaderModule, TranslateModule],
	templateUrl: './server-operation-tab.component.html',
	styleUrl: './server-operation-tab.component.scss'
})
export class ServerOperationTabComponent implements OnInit, OnDestroy {
	tenantId: string;
	isEditor: boolean;
	server: Server;
	isTenantInactivePlan: boolean;

	metrics: InfrastructureOverview | undefined | null = undefined;

	_initializing: boolean;
	_loading: boolean;

	subscription: Subscription = new Subscription();

	constructor(private serverDetailService: ServerDetailService,
				private serverService: ServerService) {
	}

	ngOnInit() {
		this.subscription.add(this.serverDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.serverDetailService.getServerDetailDataChanges()
			.pipe(tap(data => this.setServerDetailData(data)))
			.subscribe(() => this.initialize()));
	}

	private setServerDetailData(data: ServerDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.server = data.server;
		this.isTenantInactivePlan = data.isInactivePlan;
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				switchMap(() => this.server.automatic ? this.serverService.getServerMetrics(this.tenantId, this.server.id, this.server.system) : of(null)),
				tap(metrics => this.metrics = metrics),
				finalize(() => this.switchLoading()))
			.subscribe());
	}

	redirectToDoc(): void {
		window.open('https://help.kabeen.io/fr/collections/3331016-deploiement-de-l-agent-kapsul', '_blank', 'noopener');
	}

	switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
