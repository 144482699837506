import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Data, DataForm, DataService} from "../../../../../../services/back/data.service";
import {finalize, Observable, of, Subscription, switchMap, takeWhile, tap} from "rxjs";
import {DataDetailData, DataDetailService} from "../../../../../../services/front/data-detail.service";
import {filter, map} from "rxjs/operators";
import {FormControl} from "@angular/forms";
import {SnackbarService} from "../../../../../../services/front/snackbar.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {DesignSystemModule} from "../../../../../design-system/design-system.module";
import {ContentLoaderModule} from "@ngneat/content-loader";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-data-description',
	imports: [
		TranslateModule,
		DesignSystemModule,
		ContentLoaderModule,
		CommonModule
	],
  templateUrl: './data-description.component.html',
  styleUrl: './data-description.component.scss'
})
export class DataDescriptionComponent implements OnInit, OnDestroy{
	data: Data;
	tenantId: string;

	descriptionControl: FormControl<string|null> = new FormControl<string|null>(null);

	initialized: boolean = false;
	_saving: boolean;

	edit: boolean = false;

	dataDetailService = inject(DataDetailService);
	dataService = inject(DataService);
	snackbarService = inject(SnackbarService);
	translateService = inject(TranslateService);

	subscription: Subscription = new Subscription();

	ngOnInit() {
		this.subscription.add(this.dataDetailService.getDataDetailDataChanges()
			.subscribe((data) => this.setDataDetailData(data)));
	}

	setDataDetailData(data: DataDetailData) {
		this.data = data.data;
		this.tenantId = data.tenantId;
		this.initialized = true;
		this.descriptionControl.disable();
		this.descriptionControl.setValue(this.data.description, {emitEvent: false});
	}

	changeEditState(): void {
		if (this.edit)
			this.updateData();
		this.edit = !this.edit;
		this.edit ? this.descriptionControl.enable() : this.descriptionControl.disable();
	}

	updateData(): void {
		this.switchSaving()
			.pipe(
				filter(() => this.hasInformationChanged() && this.descriptionControl.valid),
				map(() => this.buildDataForm()),
				switchMap(form => this.dataService.update(this.tenantId, this.data.id, form)),
				tap((updateSucceeded) => this.displayUpdatedSucceed(updateSucceeded)),
				tap(() => this.dataDetailService.refreshData()),
				tap(() => this.edit = false),
				takeWhile(() => !this.subscription.closed),
				finalize(() => this.switchSaving()))
			.subscribe();
	}

	hasInformationChanged(): boolean {
		return this.data.description !== (this.descriptionControl.value || null);
	}

	buildDataForm(): DataForm {
		return {
			name: this.data.name,
			description: this.descriptionControl.value || undefined,
			privacy: this.data.privacy || undefined,
			criticality: this.data.criticality || undefined,
			category: this.data.category || undefined,
			types: this.data.types
		};
	}

	displayUpdatedSucceed(updateSucceed: boolean): void {
		if (updateSucceed) {
			this.snackbarService.show(this.translateService.instant('page.application.detail.update.success'));
		} else {
			this.snackbarService.show(this.translateService.instant('page.application.detail.update.fail'), 'danger-snack');
		}
	}

	switchSaving(): Observable<{}> {
		this._saving = !this._saving;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
