<div class="flex-column gap-L">
	<div class="flex-align-center gap-S">
		<div class="font-MM fw-700">{{ 'page.appDetails.overview.informations'|translate }}</div>
		<div *ngIf="advancementPercent < 100" [style.background-color]="advancementPercent > 0 ? '#F2F2F8' : '#DADADA'"
			 style=" height: 5px; border-radius: 2px; width: 30px;">
			<div class="bkg-accent completion-anim" style="height: 5px; border-radius: 2px;"
				 [style.width.%]="advancementPercent"></div>
		</div>
		<img [src]="advancementPercent >= 100 ? 'assets/icons/check-circle-green.svg' : 'assets/icons/check-circle.svg'"
			 height="14px" alt="">
	</div>
	<div class="flex-column">
		<div class="flex-align-center border-light-grey-top" style="height: 50px;">
			<div class="flex-align-center pad-S w-50 border-box">
				<div class="flex-align-center gap-XS w-40">
					<img class="filter-grey" src="assets/icons/package-2.svg" height="14px" alt="">
					<span class="font-M fw-500 grey">{{ 'page.data.category' | translate }}</span>
				</div>
				<div class="flex-align-center w-60">
					<app-multi-select class="w-100" [inputControl]="categoryFormControl"
									  [disabled]="_saving">
						@if (initialized && !categoryFormControl.value) {
							<app-chips-button select-trigger>
								<img src="assets/icons/add.svg" class="filter-grey"
									 height="12px" alt="">
								<span
									class="font-M fw-500 grey">{{ 'page.data.addACategory' | translate }}</span>
							</app-chips-button>
						} @else if (initialized && !!categoryFormControl.value) {
							<app-select-trigger
								class="w-100">{{ categoryFormControl.value.name }}
							</app-select-trigger>
						} @else {
							<app-select-trigger class="w-100">-</app-select-trigger>
						}
						<app-select-search [searchControl]="categorySearchFormControl"></app-select-search>
						<app-select-insert [titlePath]="'global.adds.category'" (create)="createCategory($event)"></app-select-insert>
						<app-select-option [value]="null">{{ '-' }}</app-select-option>
						<app-select-option *ngFor="let category of filteredDataCategoryList"
										   [value]="category">
							{{ category.name }}
						</app-select-option>
					</app-multi-select>
				</div>
			</div>
			<div class="border-light-grey-left" style="height: 25px"></div>
			<div class="flex-align-center pad-S w-50 border-box">
				<div class="flex-align-center gap-XS w-40">
					<img src="assets/icons/eye-off.svg" height="14px" alt="">
					<span class="font-M fw-500 grey">{{ 'page.data.privacy' | translate }}</span>
				</div>
				<div class="flex-align-center w-60">
					<app-multi-select class="w-100" [inputControl]="privacyFormControl"
									  [disabled]="_saving">
						@if (initialized && !!privacyFormControl.value) {
							<app-select-trigger class="w-100">
								{{ 'page.data.privacyValues.' + privacyFormControl.value.toLowerCase() | translate }}
							</app-select-trigger>
						} @else {
							<app-chips-button select-trigger>
								<img src="assets/icons/add.svg" class="filter-grey"
									 height="12px" alt="">
								<span
									class="font-M fw-500 grey">{{ 'page.data.addAPrivacy' | translate }}</span>
							</app-chips-button>
						}
						<app-select-option *ngFor="let privacy of dataPrivacyList"
										   [value]="privacy">{{ 'page.data.privacyValues.' + privacy.toLowerCase() | translate }}
						</app-select-option>
					</app-multi-select>
				</div>
			</div>
		</div>
		<div class="flex-align-center border-light-grey-top" style="height: 50px;">
			<div class="flex-align-center pad-S w-50 border-box">
				<div class="flex-align-center gap-XS w-40">
					<img src="assets/icons/infrastructure_overview/router/criticality.svg"
						 height="14px" alt="">
					<div class="flex-align-center">
						<span class="font-M fw-500 grey">{{ 'page.data.criticality' | translate }}</span>
					</div>
				</div>
				<div class="flex-align-center w-60">
					<app-multi-select *ngIf="data" class="w-100" [inputControl]="criticalityFormControl">
						@if (initialized && !!criticalityFormControl.value) {
							<app-select-trigger class="w-100">
								<div class="flex-align-center">
									<img alt="Criticality icon"
										 src="assets/icons/criticality-{{criticalityFormControl.value!.toLowerCase()}}.svg"
										 height="14">
									<span
										class="ml-s">{{ ('page.appDetails.businessCriticality.' + criticalityFormControl.value!.toLowerCase())|translate }}</span>
								</div>
							</app-select-trigger>
						} @else {
							<app-chips-button select-trigger>
								<img src="assets/icons/add.svg" class="filter-grey"
									 height="12px" alt="">
								<span
									class="font-M fw-500 grey">{{ 'page.data.addACriticality' | translate }}</span>
							</app-chips-button>
						}
						<app-select-option *ngFor="let criticality of dataCriticalityList" [value]="criticality">
							<div class="flex-align-center gap-M">
								<img src="assets/icons/criticality-{{criticality.toLowerCase()}}.svg" height="16" alt=""/>
								<span>{{('page.data.criticalityValues.' + criticality.toLowerCase())|translate}}</span>
							</div>
						</app-select-option>
					</app-multi-select>
				</div>
			</div>
			<div class="border-light-grey-left" style="height: 25px"></div>
			<div class="flex-align-center pad-S w-50 border-box">
				<div class="flex-align-center gap-XS w-40">
					<img src="assets/icons/infrastructure_overview/router/type.svg" height="14px" alt="">
					<span class="font-M fw-500 grey">{{ 'page.data.type' | translate }}</span>
				</div>
				<div class="flex-align-center w-60">
					<app-multi-select [confirmButton]="false" class="w-100" [inputControl]="typeFormControl"
									  [disabled]="_saving" [multiple]="true">
						@if (initialized && !!typeFormControl.value && typeFormControl.value.length > 0) {
							<app-select-trigger class="w-100">
								<span>{{ !typeFormControl.value[0] ? '-' : (typeFormControl.value[0].toUpperCase()) + (typeFormControl.value.length > 1 ?  '(+' + (typeFormControl.value.length - 1) + ')' : '')}}</span>
							</app-select-trigger>
						} @else {
							<app-chips-button select-trigger>
								<img src="assets/icons/add.svg" class="filter-grey" height="12px" alt="">
								<span class="font-M fw-500 grey">{{ 'page.data.addAType' | translate }}</span>
							</app-chips-button>
						}
						<app-select-option *ngFor="let type of dataTypeList" [value]="type">
							{{ 'page.data.typesValues.' + type.toLowerCase() | translate }}
						</app-select-option>
					</app-multi-select>
				</div>
			</div>
		</div>
	</div>
</div>
