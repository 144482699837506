import {Injectable} from '@angular/core';
import {BehaviorSubject, finalize, Observable, of, switchMap, tap} from 'rxjs';
import {TenantOverview, TenantService} from '../tenant.service';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {filter, first, map} from 'rxjs/operators';
import {AuthenticationService} from '../authentication.service';
import {HOME_URL} from '../../models/home/navigation.model';
import {TenantAccount} from 'src/app/services/model/account.model';
import {TenantAccountService} from 'src/app/services/back/tenant-account.service';

@Injectable()
export class CurrentTenantService {

	private tenants: TenantOverview[];

	private currentTenantBS = new BehaviorSubject<TenantOverview|undefined>(undefined);
	private initializingBS = new BehaviorSubject<boolean>(true);
	private applicationCountBS = new BehaviorSubject<number|undefined>(undefined);
	private tenantAccountBS = new BehaviorSubject<TenantAccount|undefined>(undefined);

	constructor(private authenticationService: AuthenticationService,
							private activatedRoute: ActivatedRoute,
							private tenantAccountService: TenantAccountService,
							private tenantService: TenantService,
							private router: Router) {
	}

	protected getTenantIdFromLocalStorage(): string|undefined {
		let myTenantId: string|undefined;
		const tenantIdJson = localStorage.getItem('tenantId');
		if (!!tenantIdJson) {
			const tenantIdArray: StoredUserAndTenantID[] = JSON.parse(tenantIdJson);
			const userId = this.authenticationService.getUserInfo()?.id;
			myTenantId = tenantIdArray.find(e => e.userId === userId)?.tenantId;
		}
		return myTenantId;
	}

	initialize(defaultTenantId?: string, redirectHome = true): Observable<Boolean> {
		this.getCurrentTenantIdChanges()
			.subscribe(tenantId => this.fetchTenantAccount(tenantId));

		this.getCurrentTenantIdChanges()
			.subscribe(tenantId => this.fetchApplicationCount(tenantId));

		this.setInitializing(true)
			.pipe(
				switchMap(() => this.tenantService.getTenants()),
				tap(tenants => this.tenants = tenants),
				map(() => !defaultTenantId ? this.getDefaultTenantId() : defaultTenantId),
				finalize(() => this.setInitializing(false)))
			.subscribe(tenantId => {
				this.checkAndChangeTenant(tenantId!, !!defaultTenantId && redirectHome)
			});

		return this.initializingBS.asObservable().pipe(filter(initializing => !initializing), first());
	}

	private getDefaultTenantId(): string|undefined|null {
		let defaultTenantId: string|undefined|null = this.activatedRoute.snapshot.queryParamMap.get('tenant');
		if (!defaultTenantId || !this.tenants.find(tenant => tenant.configuration.id === defaultTenantId)) {
			defaultTenantId = this.getTenantIdFromLocalStorage();
		}
		if (!defaultTenantId || !this.tenants.find(tenant => tenant.configuration.id === defaultTenantId)) {
			defaultTenantId = this.tenants.find(tenant => tenant.configuration.isDefault)?.configuration.id;
		}
		if (!defaultTenantId || !this.tenants.find(tenant => tenant.configuration.id === defaultTenantId)) {
			defaultTenantId = this.tenants[0]?.configuration.id;
		}
		return defaultTenantId;
	}

	changeTenant(tenantId: string, redirectHome = false): void {
		this.checkAndChangeTenant(tenantId, redirectHome);
	}

	private checkAndChangeTenant(tenantId: string|null|undefined, redirectHome = false): void {
		const tenant: TenantOverview|undefined = this.tenants.find(tenant => tenant.configuration.id === tenantId);
		if (!!tenant) {
			this.setTenantIdInLocalStorage(tenantId!);
			localStorage.setItem('currency', tenant.configuration.currency.symbol);
			const extras: NavigationExtras = {
				queryParams: {
					tenant: tenantId
				},
				queryParamsHandling: 'merge'
			};
			this.currentTenantBS.next(tenant);
			this.router.navigate(redirectHome ? [HOME_URL] : [], extras);
		} else {
			this.currentTenantBS.next(undefined);
			this.router.navigate([HOME_URL]);
		}
	}

	private setTenantIdInLocalStorage(tenantId: string): void {
		const accountId: string|undefined = this.authenticationService.getUserInfo()?.id;
		if (!!accountId) {
			let tenantIdArray: StoredUserAndTenantID[] = [];
			const tenantIdJson = localStorage.getItem('tenantId');
			if (!!tenantIdJson) {
				tenantIdArray = JSON.parse(tenantIdJson);
				const userTenant = tenantIdArray.find(e => e.userId === accountId);
				if (!!userTenant) {
					userTenant.tenantId = tenantId;
				} else {
					tenantIdArray.push({userId: accountId, tenantId: tenantId});
				}
			} else {
				tenantIdArray.push({userId: accountId, tenantId: tenantId});
			}
			localStorage.setItem('tenantId', JSON.stringify(tenantIdArray));
		}
	}

	getCurrentTenant(): Observable<TenantOverview> {
		return this.getCurrentTenantChanges().pipe(first());
	}

	getCurrentTenantId(): Observable<string> {
		return this.getCurrentTenantIdChanges().pipe(first());
	}

	getCurrentTenantChanges(): Observable<TenantOverview> {
		return this.currentTenantBS.asObservable().pipe(
			filter(tenant => !!tenant),
			map(tenant => tenant!));
	}

	getCurrentTenantIdChanges(): Observable<string> {
		return this.getCurrentTenantChanges().pipe(
			map(tenant => tenant.configuration.id));
	}

	getIsNoTenant(): Observable<boolean> {
		return this.currentTenantBS.asObservable().pipe(
			filter(() => !!this.tenants),
			map(() => this.tenants.length === 0));
	}

	getInitializingChanges(): Observable<boolean> {
		return this.initializingBS.asObservable();
	}

	private setInitializing(initializing: boolean): Observable<{}> {
		this.initializingBS.next(initializing);
		return of({});
	}

	getTenants(): TenantOverview[] {
		return this.tenants;
	}

	refreshApplicationCount(): void {
		this.getCurrentTenantId()
			.subscribe(tenantId => this.fetchApplicationCount(tenantId));
	}

	private fetchApplicationCount(tenantId: string): void {
		this.tenantService.getApplicationCount(tenantId)
			.subscribe(count => this.applicationCountBS.next(count.count));
	}

	getApplicationCountChanges(): Observable<number> {
		return this.applicationCountBS.asObservable().pipe(
			filter(count => count !== undefined),
			map(count => count!));
	}

	refreshTenantAccount(): void {
		this.getCurrentTenantId()
			.subscribe(tenantId => this.fetchTenantAccount(tenantId));
	}

	private fetchTenantAccount(tenantId: string): void {
		const accountId: string = this.authenticationService.getUserInfo()!.id;
		this.tenantAccountService.getTenantAccount(tenantId, accountId)
			.subscribe(account => this.tenantAccountBS.next(account!));
	}

	getTenantAccountChanges(): Observable<TenantAccount> {
		return this.tenantAccountBS.asObservable().pipe(
			filter(account => account !== undefined),
			map(account => account!));
	}

	reset(): void {
	 	this.currentTenantBS = new BehaviorSubject<TenantOverview|undefined>(undefined);
		this.initializingBS = new BehaviorSubject<boolean>(true);
		this.applicationCountBS = new BehaviorSubject<number|undefined>(undefined);
		this.tenantAccountBS = new BehaviorSubject<TenantAccount|undefined>(undefined);
	}
}

interface StoredUserAndTenantID {
	userId: string;
	tenantId: string;
}
