import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Component, Inject} from '@angular/core';
import {tap} from "rxjs/operators";
import {RightSliderService} from "../../../../../../../services/front/right-slider.service";
import {MainMenuPath} from "../../../../../../../models/home/navigation.model";
import {ActivatedRoute, Router} from "@angular/router";
import {ApplicationServersFormData} from "../application-server-form.component";

@Component({
	templateUrl: './application-server-form-dialog.component.html',
	standalone: false,
	styleUrls: ['./application-server-form-dialog.component.scss']
})
export class ApplicationServerFormDialogComponent {
	protected innerState = ResourceDialogState.LOADING;

	constructor(@Inject(MAT_DIALOG_DATA) public data: ApplicationServersFormData,
				protected rightService: RightSliderService,
				protected router: Router,
				protected activatedRoute: ActivatedRoute,
				public dialogRef: MatDialogRef<ApplicationServerFormDialogComponent>) {
		dialogRef.disableClose = true;
	}

	changeState(hasApplication: boolean): void {
		this.innerState = hasApplication ? ResourceDialogState.READY : ResourceDialogState.EMPTY;
	}

	close(updated?: boolean): void {
		this.dialogRef.close(updated);
	}

	handlePlaceholderAction(event: { reason: 'close' | 'cancel' | 'apply' }): void {
		if (event.reason === 'apply') {
			this.router.navigate([MainMenuPath.INFRASTRUCTURE], { queryParams: this.activatedRoute.snapshot.queryParams})
			this.dialogRef.afterClosed().pipe(tap(() => this.rightService.close())).subscribe()
			this.dialogRef.close()
		} else {
			this.close();
		}
	}

	protected readonly resourceDialogState = ResourceDialogState;
}

export enum ResourceDialogState {
	LOADING= 'LOADING',
	EMPTY= 'EMPTY',
	READY= 'READY'
}
