import {Component, inject} from '@angular/core';
import {CommonModule} from "@angular/common";
import {finalize, forkJoin, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {Data, DataResponsibleForm, DataService} from "../../../../../../services/back/data.service";
import {TenantAccount} from "../../../../../../services/model/account.model";
import {FormControl, Validators} from "@angular/forms";
import {DataDetailData, DataDetailService} from "../../../../../../services/front/data-detail.service";
import {TenantAccountService} from "../../../../../../services/back/tenant-account.service";
import {TenantService} from "../../../../../../services/tenant.service";
import {SnackbarService} from "../../../../../../services/front/snackbar.service";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {filter, map} from "rxjs/operators";
import {ContentLoaderModule} from "@ngneat/content-loader";
import {DesignSystemModule} from "../../../../../design-system/design-system.module";

@Component({
  selector: 'app-data-responsible',
	imports: [
		CommonModule,
		ContentLoaderModule,
		DesignSystemModule,
		TranslateModule
	],
  templateUrl: './data-responsible.component.html',
  styleUrl: './data-responsible.component.scss'
})
export class DataResponsibleComponent {
	tenantId: string;
	data: Data;

	tenantAccounts: TenantAccount[] = [];
	tenantAccountFilteredList: TenantAccount[] = [];
	dataResponsibles: TenantAccount[] = [];

	validators: Validators[] = [
		Validators.required,
		Validators.minLength(1),
		Validators.email
	];

	dataResponsiblesControl: FormControl<TenantAccount[]> = new FormControl([], { nonNullable: true });
	searchUserControl: FormControl<string|null> = new FormControl('');

	initialized: boolean = false;
	_initializing: boolean;
	_loading: boolean;

	hovered: boolean = false;

	private dataDetailService = inject(DataDetailService);
	private tenantAccountService = inject(TenantAccountService);
	private dataService = inject(DataService);
	private tenantService = inject(TenantService);
	private snackbarService = inject(SnackbarService);
	private translateService = inject(TranslateService);

	subscription: Subscription = new Subscription();

	ngOnInit() {
		this.subscription.add(this.dataDetailService.getDataDetailDataChanges()
			.pipe(tap(data => this.setDataDetailData(data)))
			.subscribe(() => this.initialize()));

		this.subscription.add(this.dataResponsiblesControl.valueChanges
			.pipe(
				tap((value: TenantAccount[]) => {
					const toAdd = value.filter(user => !this.dataResponsibles.find(responsible => responsible.account.accountId === user.account.accountId));
					const toRemove = this.dataResponsibles.filter(responsible => !value.find(user => user.account.accountId === responsible.account.accountId));
					toAdd.forEach(user => this.addResponsible(user));
					toRemove.forEach(user => this.removeResponsible(user));
				})
			).subscribe());

		this.subscription.add(this.searchUserControl.valueChanges
			.pipe(
				tap(value => this.tenantAccountFilteredList = this.tenantAccounts.filter(user => {
					const search = value?.toLowerCase() || '';
					return user.account.firstName.toLowerCase().includes(search)
						|| user.account.lastName.toLowerCase().includes(search)
						|| user.account.email.toLowerCase().includes(search);
				}))
			).subscribe());
	}

	private setDataDetailData(data: DataDetailData): void {
		this.data = data.data;
		this.tenantId = data.tenantId;
	}

	initialize(): void {
		this.switchLoading()
			.pipe(
				switchMap(() => forkJoin([
					this.tenantAccountService.getAllTenantAccountByTenantId(this.tenantId),
					this.dataService.getDataResponsibles(this.tenantId, this.data.id)
				])),
				tap(([usersList, responsibles]) => this.setData(usersList, responsibles)),
				finalize(() => this.switchLoading())
			).subscribe(() => this.initialized = true);
	}

	setData(usersList: TenantAccount[], responsibles: TenantAccount[]): void {
		this.tenantAccountFilteredList = usersList
		this.tenantAccounts = usersList;
		this.dataResponsibles = responsibles;
		this.dataResponsiblesControl.setValue(responsibles, {emitEvent: false});
	}

	onInviteResponsible(value: { value: string, toggle: boolean }): void {
		const existingUser = this.tenantAccounts.find(user => user.account.email === value.value);
		if (!existingUser) {
			this.tenantService.inviteUsers(this.tenantId, [{ email: value.value, grantAccess: value.toggle }])
				.pipe(
					switchMap(() => this.tenantAccountService.getAllTenantAccountByTenantId(this.tenantId)),
					tap(users => {
						this.tenantAccounts = users
							.sort((a, b) => `${a.account.firstName} ${a.account.lastName}`.localeCompare(`${b.account.firstName} ${b.account.lastName}`))
							.sort((a, b) => a.pending ? 1 : -1)

						this.tenantAccountFilteredList = this.tenantAccounts;
					}),
					map(() => this.tenantAccounts.find(user => user.account.email === value.value)),
					filter(user => !!user),
					tap(user => this.addResponsible(user!)))
				.subscribe();
		} else {
			this.addResponsible(existingUser);
		}
	}

	addResponsible(account: TenantAccount): void {
		if (this.dataResponsibles.find(responsible => responsible.account.accountId === account.account.accountId)) {
			this.snackbarService.show(this.translateService.instant('page.application.detail.update.success'));
		} else {
			const form: DataResponsibleForm = {
				accountId: account.account.accountId
			};
			this.dataService.addDataResponsible(this.tenantId, this.data.id, form)
				.pipe(
					filter(success => success),
					tap(() => this.snackbarService.show(this.translateService.instant('page.application.detail.update.success')))
				).subscribe(() => {
				this.dataResponsibles = [...this.dataResponsibles, account];
				this.dataResponsiblesControl.setValue(this.dataResponsibles);
			});
		}
	}

	removeResponsible(user: TenantAccount): void {
		const form: DataResponsibleForm = {
			accountId: user.account.accountId
		};
		this.dataService.deleteDataResponsible(this.tenantId, this.data.id, form)
			.pipe(
				filter(success => success),
				tap(() => this.snackbarService.show(this.translateService.instant('page.application.detail.update.success')))
			).subscribe(() => {
			this.dataResponsibles = this.dataResponsibles.filter(responsible => responsible.account.accountId !== user.account.accountId);
			this.dataResponsiblesControl.setValue(this.dataResponsibles);
		});
	}

	getInvertedIndex(index: number): number {
		const maxIndex = this.dataResponsiblesControl.value.length - 1;
		return maxIndex - index;
	}

	switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
