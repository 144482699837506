import {inject, Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ApplicationGeneric} from "../model/new-application.model";
import {FileLink} from "../model/tenant-file.model";
import {Data} from "./data.service";
import {SecurityUtils} from "../security.utils";
import {catchError} from "rxjs/operators";
import {HttpClientUtils} from "../../utils/http-client.utils";
import {Observable} from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class FlowService {
	constructor() {
	}

	private backendUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/flows`;
	private httpClient = inject(HttpClient);

	getAllFlowByApplicationId(tenantId: string, applicationId: string): Observable<Flow[]> {
		return this.httpClient.get<Flow[]>(`${this.backendUrl(tenantId)}/applications/${applicationId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getAllUsedPortByTenantId(tenantId: string): Observable<string[]> {
		return this.httpClient.get<string[]>(`${this.backendUrl(tenantId)}/applications/ports`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	createFlow(tenantId: string, form: FlowForm): Observable<string> {
		return this.httpClient.post<string>(`${this.backendUrl(tenantId)}`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	updateFlow(tenantId: string, flowId: string, form: FlowForm): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.backendUrl(tenantId)}/${flowId}`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	deleteFlow(tenantId: string, flowId: string): Observable<boolean> {
		return this.httpClient.delete<boolean>(`${this.backendUrl(tenantId)}/${flowId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}
}

export interface Flow {
	id: string;
	comment: string;
	source: ApplicationGeneric;
	target: ApplicationGeneric;
	dataList: Data[];
	protocol?: string;
	format?: string;
	exchangeFrequency?: string;
	portType?: string;
	port?: string;
	flowEncryption?: boolean;
	documents: FileLink[];
}

export enum ProtocolType {
	HTTP = 'http',
	FTP = 'ftp',
	SFTP = 'sftp',
	MQTT = 'mqtt',
	AMQP = 'amqp',
	gRPC = 'grpc',
	SMB = 'smb',
	NFS = 'nfs',
	LDAP = 'ldap',
	CIFS = 'cifs',
	RTSP = 'rtsp',
	DICOM = 'dicom',
	HL7 = 'hl7',
	FHIR = 'fhir',
	XDS = 'xds',
	IHE = 'ihe',
	CDA = 'cda',
	NCPDP = 'ncpdp',
	CCDA = 'ccda',
	AS2 = 'as2',
	EDI = 'edi',
	SEPA = 'sepa',
	EBICS = 'ebics',
}

export enum FormatType {
	JSON = 'json',
	CSV = 'csv',
	XML = 'xml',
	YAML = 'yaml',
	PLAIN_TEXT = 'plain_text',
	HTML = 'html',
	BINARY = 'binary',
}

export enum ExchangeFrequencyType {
	REAL_TIME = 'real_time',
	DAILY = 'daily',
	WEEKLY = 'weekly',
	MONTHLY = 'monthly',
}

export interface FlowForm {
	sourceId: string;
	targetId: string;
	dataList: string[];
	comment?: string;
	protocol?: ProtocolType;
	format?: FormatType;
	exchangeFrequency?: ExchangeFrequencyType;
	portType?: string;
	port?: string;
	flowEncryption?: boolean;
	documents?: string[];
}
