import {Environment} from "./environment.model";

export const environment: Environment = {
  	production: true,
	errorAlert: false,
	applicationLogo: '/assets/new-logo.svg',
	sentryConfiguration: {
		enabled: true,
		dsn: 'https://44c3a54abf797b935bacd53c1fe230ef@o4506537915711488.ingest.sentry.io/4506552008835072',
		tracePropagationTargets: ['https://api.kabeen.io'],
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	},
	engineURL: 'https://api.kabeen.io',
	downloadKapsulURL: 'https://app.kabeen.io/download/kapsul-setup.exe',
	downloadKapsulURLDeb: 'https://app.kabeen.io/download/kapsul.deb',
	downloadKapsulURLRPM: 'https://app.kabeen.io/download/kapsul.rpm',
	supportUrl: 'https://docs.kabeen.io/',
	intercomAppId: 'a2fckqc6',
	reCaptchaSiteKey: '6LczBOsnAAAAADo4jayp2fP4iahLu-t1HUmLZuqI',
	defaultApplications:  [
		{id:'2b04e0e4-c09c-4369-be2a-69f8c59aaf88', name:'Zoom', icon:'/assets/catalog/zoom.svg', isSelected:false},
		{id:'6fba534c-41a5-451b-b003-6278bdb0fa91', name:'Jira', icon:'/assets/catalog/atlassian.png', isSelected:false},
		{id:'b3a43d81-75fe-4859-bdbb-7b394be2a118', name:'Datadog', icon:'/assets/catalog/datadog.png', isSelected:false},
		{id:'a57546d6-c021-4eb4-9ae4-e35ac3735684', name:'Discord', icon:'/assets/catalog/discord.ico', isSelected:false},
		{id:'66285b8e-5c03-4821-b81a-6d8e3929ab54', name:'Elastic Cloud', icon:'/assets/catalog/elasticcloud.ico', isSelected:false},
		{id:'8adf0daa-07bf-401e-9174-e4befab98f08', name:'Github', icon:'/assets/catalog/github.png', isSelected:false},
		{id:'b94160a6-8058-4003-abeb-1e9e16245211', name:'InfluxDB Cloud', icon:'/assets/catalog/influxdbcloud.svg', isSelected:false},
		{id:'2857c3cd-9254-47eb-a051-262670d8efa4', name:'Intercom', icon:'/assets/catalog/intercom.png', isSelected:false},
		{id:'25b1776c-b62c-4704-933e-324ab40e43da', name:'Payfit', icon:'/assets/catalog/payfit.ico', isSelected:false},
		{id:'7cafc18e-afbe-4708-95cd-7d8f8383d7ab', name:'Productboard', icon:'/assets/catalog/productboard.png', isSelected:false},
		{id:'1e9a2855-e17b-40a6-b966-91ca65d175a2', name:'', icon:'/assets/catalog/mulesoft.png', isSelected:false},
		{id:'a77d48aa-19f7-4cce-af09-778feb45dd65', name:'', icon:'/assets/catalog/stripe.ico', isSelected:false},
		{id:'7f568033-ec6e-4119-a7cc-34b8044db40a', name:'', icon:'/assets/catalog/zapier.png', isSelected:false},
		{id:'891f848b-b85c-4c97-8bd1-30e2867e53ba', name:'', icon:'/assets/catalog/readme.ico', isSelected:false}
	],
	msalConfig : {
		auth: {
			clientId: '83e43631-c0d1-4fe9-8bf1-1a45185a8442',
			authority: 'https://login.microsoftonline.com/common',
			redirectUri: 'https://app.kabeen.io/login'
		},
		cache: {
			cacheLocation: 'localStorage',
			storeAuthStateInCookie: false,
		},
	}
};
