import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {Data, DataService} from "../../../../services/back/data.service";
import {Subscription} from "rxjs";
import {DataDetailData, DataDetailService} from "../../../../services/front/data-detail.service";
import {DataUrlService} from "../../../../services/front/data-url.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {SnackbarService} from "../../../../services/front/snackbar.service";
import {MatDialog} from "@angular/material/dialog";
import {filter, tap} from "rxjs/operators";
import {DeckContentLoaderModule} from "../../../global/title-content-loader/deck-content-loader.module";
import {DesignSystemModule} from "../../../design-system/design-system.module";
import {CommonModule} from "@angular/common";
import {SnackbarModule} from "../../../global/snackbar/snackbar.module";
import {TabModule} from "../../../global/tabs/tab/tab.module";
import {TabsModule} from "../../../global/tabs/tabs.module";
import {DataOverviewTabComponent} from "./data-overview-tab/data-overview-tab.component";
import {
	RouterSettingsComponent
} from "../../infrastructure/router/router-detail/router-settings/router-settings.component";
import {DataSettingsComponent} from "./data-settings/data-settings.component";
import {DeleteDialogComponent} from "../../../design-system/delete-dialog/delete-dialog.component";

@Component({
  selector: 'app-data-detail',
	imports: [
		DeckContentLoaderModule,
		DesignSystemModule,
		CommonModule,
		SnackbarModule,
		TranslateModule,
		TabModule,
		TabsModule,
		DataOverviewTabComponent,
		DataSettingsComponent
	],
  templateUrl: './data-detail.component.html',
  styleUrl: './data-detail.component.scss'
})
export class DataDetailComponent {
	@Input() data: DataDetailInput;
	@Output() onClose = new EventEmitter<void>();

	tenantId: string;
	isEditor: boolean;

	dataDto: Data;

	_initializing: boolean;

	settings: boolean = false;

	subscriptions: Subscription = new Subscription();

	private dataDetailService = inject(DataDetailService);
	private dataUrlService = inject(DataUrlService);
	private activatedRoute = inject(ActivatedRoute);
	private translate = inject(TranslateService);
	private dataService = inject(DataService);
	private snackbarService = inject(SnackbarService);
	private dialog = inject(MatDialog);
	private router = inject(Router);

	ngOnInit() {
		this.router.navigate([], {queryParams: {dataId: this.data.dataId}, queryParamsHandling: 'merge'});

		this.dataDetailService.initialize(this.data.dataId);

		this.subscriptions.add(this.dataDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));

		this.subscriptions.add(this.dataDetailService.getDataDetailDataChanges()
			.pipe(tap(data => this.setDataDetailData(data)))
			.subscribe());

		this.subscriptions.add(this.activatedRoute.queryParams
			.subscribe(() => this.onQueryParamsChange()));
	}

	private setDataDetailData(data: DataDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.dataDto = data.data;
	}

	private onQueryParamsChange(): void {
		this.settings = this.dataUrlService.getParameter();
	}

	close(): void {
		this.onClose.emit();
	}

	openDeleteDataPopup() {
		this.dialog.open(DeleteDialogComponent, {
			width: '475px',
			position: {
				right: '162.5px'
			},
			data: {
				icon: '/assets/illustrations/delete/network.svg',
				title: this.translate.instant('page.data.delete.title'),
				subtitle: this.translate.instant('page.data.delete.subtitle'),
				warningMessage: this.translate.instant('page.data.delete.warningMessage'),
				deleteButton: this.translate.instant('page.data.delete.deleteButton')
			}
		})
			.afterClosed()
			.pipe(
				filter(result => result)
			).subscribe(() => this.deleteData());
	}

	deleteData() {
		this.dataService.delete(this.tenantId, this.dataDto.id)
			.subscribe(() => this.onClose.emit());
	}

	onOpenSettings(): void {
		this.dataUrlService.navigateInAndOutOfParameter(!this.settings);
	}

	onSaveSettings(): void {
		this.snackbarService.show(this.translate.instant('page.application.detail.update.success'));
		this.dataUrlService.navigateInAndOutOfParameter(false);
	}


	ngOnDestroy() {
		this.dataDetailService.clearDataDetailData();
		this.subscriptions.unsubscribe();
	}
}

export interface DataDetailInput {
	dataId: string;
}
