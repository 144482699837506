import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {finalize, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {ApplicationDetailData, ApplicationDetailService} from 'src/app/services/front/application-detail.service';
import {TranslateService} from '@ngx-translate/core';
import {SnackbarService} from 'src/app/services/front/snackbar.service';
import {MatDialog} from '@angular/material/dialog';
import {filter, map} from 'rxjs/operators';
import {ConfirmComponent, ConfirmModel} from '../../../../../global/dialog/confirm/confirm.component';
import {ServerService} from 'src/app/services/back/server.service';
import {Server} from "../../../../../../services/model/server.model";
import {ApplicationInstance} from "../../../../../../services/model/new-application.model";
import {
	ApplicationServerFormDialogComponent
} from "../application-server-form/dialog/application-server-form-dialog.component";
import {ApplicationServersFormData} from "../application-server-form/application-server-form.component";

@Component({
	selector: 'app-application-server-preview',
	standalone: false,
	templateUrl: './application-server-preview.component.html',
	styleUrls: ['./application-server-preview.component.scss']
})
export class ApplicationServerPreviewComponent implements OnInit, OnDestroy {

	@Output() updated = new EventEmitter<void>();

	tenantId: string;
	instanceId: string;
	servers: Server[] = [];

	_initializing: boolean;
	_loading: boolean;
	_loadingDialog: boolean;
	initialized: boolean = false;
	tenantServers: Server[] = [];

	application: ApplicationInstance;

	deleteApplicationServer = (serverId: string) => this.doDeleteApplicationServer(serverId);

	subscription: Subscription = new Subscription();

	constructor(private applicationDetailService: ApplicationDetailService,
              				private serverService: ServerService,
							private translate: TranslateService,
							private snackBar: SnackbarService,
							private dialog: MatDialog) {
	}

	ngOnInit(): void {
		this.subscription.add(this.applicationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.applicationDetailService.getApplicationDetailDataChanges()
			.pipe(tap(data => this.setApplicationDetailData(data)), filter(() => !this.initialized))
			.subscribe(() => this.initialize()));
	}

	setApplicationDetailData(data: ApplicationDetailData): void {
		this.application = data.instance;
		this.tenantId = data.tenantId;
		this.instanceId = data.instance.applicationId;
	}

	initialize(): void {
		this.switchLoading()
			.pipe(
				switchMap(() => this.serverService.getApplicationServers(this.tenantId, this.instanceId)),
				tap(servers => this.setAllApplicationServers(servers)),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.initialized = true);
	}

	setAllApplicationServers(servers: Server[]): void {
		this.servers = servers
			.sort((a, b) => a.name.localeCompare(b.name));
	}

	openApplicationServerFormDialog(): void {
		this.switchLoadingDialog()
			.pipe(
				switchMap(() => this.getTenantServers()),
				map(servers => this.buildApplicationInfrastructureFormData(servers)),
				switchMap(data => this.dialog.open(ApplicationServerFormDialogComponent, { position: { right: '162.5px' }, width: '475px', data: data }).afterClosed()),
				filter(updated => !!updated),
				tap(() => this.snackBar.show(this.translate.instant('page.application.detail.update.success'))),
				tap(() => this.updated.emit()),
				finalize(() => this.switchLoadingDialog()))
			.subscribe(() => this.initialize());
	}

	private getTenantServers(): Observable<Server[]> {
		return of(this.tenantServers).pipe(
			switchMap(tenantServers => tenantServers.length > 0
				? of(tenantServers)
				: this.serverService.getTenantServers(this.tenantId)),
			tap(tenantServers => this.tenantServers = tenantServers));
	}

	private buildApplicationInfrastructureFormData(servers: Server[]): ApplicationServersFormData {
		return {
			tenantId: this.tenantId,
			instanceId: this.instanceId,
			defaultData: this.servers,
			defaultList: {
				servers: servers
			}
		}
	}

	doDeleteApplicationServer(serverId: string): void {
		const data: ConfirmModel = {
			message: this.translate.instant('confirmModal.deleteResource'),
			closeButton: this.translate.instant('button.cancel'),
			confirmButton: this.translate.instant('button.delete')
		};
		this.dialog.open<ConfirmComponent, any, boolean>(ConfirmComponent, { position: { right: '162.5px' }, width: '475px', data: data })
			.afterClosed()
			.pipe(
				filter(result => !!result),
				switchMap(() => this.serverService.removeApplicationServer(this.tenantId, serverId, this.application.applicationId)),
				filter(deleted => !!deleted),
				tap(() => this.snackBar.show(this.translate.instant('page.application.detail.update.success'))),
				tap(() => this.updated.emit()))
			.subscribe(() => this.initialize());
	}

	switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	switchLoadingDialog(): Observable<{}> {
		this._loadingDialog = !this._loadingDialog;
		return of({});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
