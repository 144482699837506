<div class="flex-column gap-M">
	<div class="flex-align-center flex-space-between">
		<span class="font-MM fw-700">{{'page.appDetails.infrastructure'|translate}}</span>
		<app-mini-button size="add" color="accent"
						 [disable]="_initializing || _loading || _loadingDialog"
						 (onClick)="openApplicationServerFormDialog()">
			<img src="assets/icons/add.svg" class="filter-accent" alt=""/>
		</app-mini-button>
	</div>
	<div class="flex-wrap gap-L w-100" *ngIf="servers.length > 0">
		<app-application-server-card *ngFor="let server of servers"
								   [server]="server"
								   [disabled]="_initializing || _loading || _loadingDialog"
								   [delete]="deleteApplicationServer">
		</app-application-server-card>
	</div>
</div>
