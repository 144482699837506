import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {distinctUntilChanged, finalize, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {InfrastructureUtils} from '../../../../infrastructure/infrastructure.utils';
import {map} from 'rxjs/operators';
import {ServerService} from 'src/app/services/back/server.service';
import {ApplicationServerListForm, Server} from 'src/app/services/model/server.model';
import {Logo} from "../../../../../../services/application-instance.service";

@Component({
	selector: 'app-application-server-form',
	standalone: false,
	templateUrl: './application-server-form.component.html',
	styleUrls: ['./application-server-form.component.scss']
})
export class ApplicationServerFormComponent implements OnInit, OnDestroy {

	@Input() data: ApplicationServersFormData;

	@Output() cancel = new EventEmitter<void>();
	@Output() saved = new EventEmitter<boolean>();
	@Output() hasServers = new EventEmitter<boolean>();

	_initializing: boolean;
	_saving: boolean;

	formGroup: FormGroup;
	formName = Form;
	formElementName = FormElement;

	subscription: Subscription = new Subscription();

	constructor(private serverService: ServerService) {
	}

	ngOnInit(): void {
		this.createForm();
		this.subscription.add(this.switchInitializing()
			.pipe(
				switchMap(() => !this.data.defaultList
					? this.serverService.getTenantServers(this.data.tenantId)
					: of(this.data.defaultList.servers)),
				tap(servers => this.hasServers.emit(servers.length > 0)),
				tap(servers => this.setServers(servers)),
				finalize(() => this.switchInitializing()))
			.subscribe(() => this.setDefaultData()));
	}

	private createForm(): void {
		this.formGroup = new FormGroup({
			[Form.search]: new FormControl('', []),
			[Form.servers]: new FormArray([])
		});
		this.subscription.add(this.formGroup.get(Form.search)?.valueChanges
			.pipe(distinctUntilChanged())
			.subscribe(() => this.filterServerFormGroups()));
	}

	private setServers(servers: Server[]): void {
		servers
			.map(server => new FormGroup({
				[FormElement.server]: new FormControl(server, []),
				[FormElement.selected]: new FormControl(undefined, [])
			}))
			.forEach(group => this.serverFormArray.push(group));
	}

	private setDefaultData(): void {
		this.serverFormGroups.forEach(group => {
			const selected: Server|undefined = this.data.defaultData?.find(t => t.id === this.getServer(group).id);
			group.get(FormElement.selected)?.setValue(!!selected);
		});
		this.filterServerFormGroups();
	}

	save(): void {
		this.switchSaving()
			.pipe(
				map(() => this.buildServerListForm()),
				switchMap(form => this.serverService.updateApplicationServers(this.data.tenantId, this.data.instanceId, form)),
				finalize(() => this.switchSaving()))
			.subscribe(updated => this.saved.emit(updated));
	}

	private buildServerListForm(): ApplicationServerListForm {
		return {
			servers: this.serverFormGroups
				.filter(group => !!group.get(FormElement.selected)?.value)
				.map(group => (group.get(FormElement.server)?.value as Server).id)
		};
	}

	getServerIcon(group: FormGroup): Logo {
		return InfrastructureUtils.buildSystemLogo(this.getServer(group).system);
	}

	getServer(group: FormGroup): Server {
		return group.get(FormElement.server)?.value as Server;
	}

	get searchFormControl(): FormControl {
		return this.formGroup.get(Form.search) as FormControl;
	}

	get serverFormArray(): FormArray {
		return this.formGroup.get(Form.servers) as FormArray;
	}

	get serverFormGroups(): FormGroup[] {
		return this.serverFormArray.controls as FormGroup[];
	}

	private filterServerFormGroups(): FormGroup[] {
		return this.serverFormGroups.sort(this.sortServers);
	}

	private sortServers = (form1: FormGroup, form2: FormGroup) => {
		const matchG1 = this.isFormMatchSearch(form1);
		const matchG2 = this.isFormMatchSearch(form2);
		if (matchG1 && !matchG2) {
			return -1;
		} else if (!matchG1 && matchG2) {
			return 1;
		} else {
			return this.getServer(form1).name.localeCompare(this.getServer(form2).name);
		}
	}

	filteredOut(form: FormGroup) {
		return !this.isFormMatchSearch(form);
	}

	private isFormMatchSearch(form: FormGroup): boolean {
		return this.getServer(form).name.toLowerCase().includes(this.formGroup.get(Form.search)!.value?.toLowerCase());
	}

	switchSaving(): Observable<{}> {
		this._saving = !this._saving;
		this.checkFormDisabled();
		return of({});
	}

	switchInitializing(): Observable<{}> {
		this._initializing = !this._initializing;
		this.checkFormDisabled();
		return of({});
	}

	private checkFormDisabled(): void {
		if (this._initializing || this._saving) {
			this.formGroup.disable({emitEvent: false});
		} else {
			this.formGroup.enable({emitEvent: false});
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}

export interface ApplicationServersFormData {
	tenantId: string;
	instanceId: string;
	defaultData?: Server[];
	defaultList?: {
		servers: Server[]
	};
}

enum Form {
	search = 'search',
	servers = 'servers'
}

enum FormElement {
	server = 'server',
	selected = 'selected'
}
