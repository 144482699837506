import {inject, Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SecurityUtils} from "../security.utils";
import {catchError} from "rxjs/operators";
import {HttpClientUtils} from "../../utils/http-client.utils";
import {
	ApplicationGeneric,
	ApplicationUsage,
	ResponsibleRole
} from "../model/new-application.model";
import {CriticalityLevel} from "../tenant.service";
import {TenantAccount} from "../model/account.model";
import {Category} from "../model/application-category.model";

@Injectable({
	providedIn: 'root'
})
export class DataService {
	constructor() {
	}

	private backendUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/data`;
	private httpClient = inject(HttpClient);

	getTenantDataTableData(tenantId: string): Observable<DataTableData[]> {
		return this.httpClient.get<DataTableData[]>(`${this.backendUrl(tenantId)}/table-data`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	create(tenantId: string, body: DataForm): Observable<string> {
		return this.httpClient.post<boolean>(`${this.backendUrl(tenantId)}`, body, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	getData(tenantId: string, dataId: string): Observable<Data> {
		return this.httpClient.get<Data>(`${this.backendUrl(tenantId)}/${dataId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getAllTenantData(tenantId: string): Observable<Data[]> {
		return this.httpClient.get<Data[]>(`${this.backendUrl(tenantId)}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	update(tenantId: string, dataId: string, body: DataForm): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.backendUrl(tenantId)}/${dataId}`, body, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	delete(tenantId: string, dataId: string): Observable<boolean> {
		return this.httpClient.delete<boolean>(`${this.backendUrl(tenantId)}/${dataId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	getDataResponsibles(tenantId: string, dataId: string): Observable<TenantAccount[]> {
		return this.httpClient.get<TenantAccount[]>(`${this.backendUrl(tenantId)}/${dataId}/responsibles`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	addDataResponsible(tenantId: string, dataId: string, form: DataResponsibleForm): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.backendUrl(tenantId)}/${dataId}/add-responsible`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	deleteDataResponsible(tenantId: string, dataId: string, form: DataResponsibleForm): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.backendUrl(tenantId)}/${dataId}/delete-responsible`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	getDataApplications(tenantId: string, dataId: string): Observable<ApplicationUsage[]> {
		return this.httpClient.get<ApplicationUsage[]>(`${this.backendUrl(tenantId)}/${dataId}/applications`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getApplicationData(tenantId: string, applicationId: string): Observable<Data[]> {
		return this.httpClient.get<Data[]>(`${this.backendUrl(tenantId)}/applications/${applicationId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	updateDataApplications(tenantId: string, dataId: string, form: DataApplicationForm): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.backendUrl(tenantId)}/${dataId}/applications`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	updateApplicationData(tenantId: string, applicationId: string, form: DataApplicationForm): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.backendUrl(tenantId)}/applications/${applicationId}`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}
}

export interface DataTableData {
	data: Data,
	applications: ApplicationGeneric[]
}

export interface Data {
	id: string,
	name: string,
	category: Category | null
	updatedAt: Date | null,
	types: DataType[],
	privacy: DataPrivacy | null,
	criticality: CriticalityLevel | null,
	description: string | null
}

export enum DataPrivacy {
	PUBLIC = 'public',
	INTERNAL = 'internal',
	CONFIDENTIAL = 'confidential',
	SECRET = 'secret',
	TOP_SECRET = 'top_secret',
}

export enum DataType {
	DCP = 'dcp',
	PCI = 'pci',
	PHI = 'phi',
}

export interface DataForm {
	name: string,
	types: DataType[],
	privacy?: DataPrivacy,
	criticality?: CriticalityLevel,
	category?: Category,
	description?: string
}

export interface DataResponsibleForm {
	accountId: string;
	role?: ResponsibleRole;
}

export interface DataApplicationForm {
	removed: string[];
	added: string[];
}
