import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Component, Inject} from '@angular/core';
import {Dialog} from "@angular/cdk/dialog";
import {RightSliderService} from "../../../../../../../services/front/right-slider.service";
import {tap} from "rxjs/operators";
import {ApplicationFlowFormComponent, ApplicationFlowFormData} from "../application-flow-form.component";
import {TranslateModule} from "@ngx-translate/core";
import {
	DialogPlaceholderCardModule
} from "../../../../../../design-system/dialog-placeholder-card/dialog-placeholder-card.module";

@Component({
	templateUrl: './application-flow-form-dialog.component.html',
	imports: [
		TranslateModule,
		DialogPlaceholderCardModule,
		ApplicationFlowFormComponent
	],
	styleUrls: ['./application-flow-form-dialog.component.scss']
})
export class ApplicationFlowFormDialogComponent {
	protected innerState = ApplicationDialogState.LOADING;

	constructor(@Inject(MAT_DIALOG_DATA) public data: ApplicationFlowFormData,
				public dialog: Dialog,
				public rightService: RightSliderService,
				public dialogRef: MatDialogRef<ApplicationFlowFormDialogComponent>) {
		dialogRef.disableClose = true;
	}

	handlePlaceholderAction(event: { reason: 'close' | 'cancel' | 'apply' }): void {
		if (event.reason === 'apply') {
			this.dialogRef.afterClosed().pipe(tap(() => this.rightService.close())).subscribe()
			this.dialogRef.close()
		} else {
			this.close();
		}
	}

	changeState(hasApplication: boolean): void {
		this.innerState = hasApplication ? ApplicationDialogState.READY : ApplicationDialogState.EMPTY;
	}

	close(updated?: boolean): void {
		this.dialogRef.close(updated);
	}

	protected readonly applicationDialogState = ApplicationDialogState;
}

export enum ApplicationDialogState {
	LOADING= 'LOADING',
	EMPTY= 'EMPTY',
	READY= 'READY'
}
