import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription, tap} from "rxjs";
import {Data} from "../../../../../services/back/data.service";
import {DataDetailData, DataDetailService} from "../../../../../services/front/data-detail.service";
import {CommonModule} from "@angular/common";
import {DataInformationFormComponent} from "./data-information-form/data-information-form.component";
import {DeckContentLoaderModule} from "../../../../global/title-content-loader/deck-content-loader.module";
import {DataResponsibleComponent} from "./data-responsible/data-responsible.component";
import {DataDescriptionComponent} from "./data-description/data-description.component";
import {DataApplicationsComponent} from "./data-applications/data-applications.component";

@Component({
  selector: 'app-data-overview-tab',
	imports: [
		CommonModule,
		DataInformationFormComponent,
		DeckContentLoaderModule,
		DataResponsibleComponent,
		DataDescriptionComponent,
		DataApplicationsComponent
	],
  templateUrl: './data-overview-tab.component.html',
  styleUrl: './data-overview-tab.component.scss'
})
export class DataOverviewTabComponent implements OnInit, OnDestroy {
	tenantId: string;
	isEditor: boolean;
	isTenantInactivePlan: boolean;

	data: Data;

	_initializing: boolean;
	_loading: boolean;
	initialized: boolean = false;

	subscription: Subscription = new Subscription();

	private dataDetailService = inject(DataDetailService);

	ngOnInit() {
		this.subscription.add(this.dataDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.dataDetailService.getDataDetailDataChanges()
			.pipe(tap(data => this.setDataDetailData(data)))
			.subscribe(() => this.initialized = true));
	}

	private setDataDetailData(data: DataDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.data = data.data;
		this.isTenantInactivePlan = data.isInactivePlan;
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
