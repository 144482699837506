import {Component, inject} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {CurrentTenantService} from "../../../../../services/front/current-tenant.service";
import {DesignSystemModule} from "../../../../design-system/design-system.module";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {DataForm, DataPrivacy, DataService, DataType} from "../../../../../services/back/data.service";
import {distinctUntilChanged, finalize, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {filter} from "rxjs/operators";
import {CommonModule} from "@angular/common";
import {ApplicationCategoryForm, Category} from "../../../../../services/model/application-category.model";
import {CriticalityLevel} from "../../../../../services/tenant.service";
import {ApplicationCategoryService} from "../../../../../services/back/application-category.service";

@Component({
  selector: 'app-create-data',
	imports: [
		DesignSystemModule,
		FormsModule,
		TranslateModule,
		ReactiveFormsModule,
		CommonModule
	],
  templateUrl: './create-data.component.html',
  styleUrl: './create-data.component.scss'
})
export class CreateDataComponent {
	tenantId: string;

	dataPrivacyList: DataPrivacy[] = Object.values(DataPrivacy);

	criticalityList: CriticalityLevel[] = Object.values(CriticalityLevel);

	categoryList: Category[] = [];
	filteredCategoryList: Category[] = [];

	formGroup: FormGroup = new FormGroup({
		name: new FormControl<string | null>(null, [Validators.required]),
		description: new FormControl<string | null>(null),
		category: new FormControl<Category | null>(null),
		criticality: new FormControl<CriticalityLevel | null>(null),
		privacy: new FormControl<DataPrivacy | null>(null)
	})

	categorySearchFormControl: FormControl<string | null> = new FormControl<string | null>('');

	subscription: Subscription = new Subscription();

	initialized: boolean = false;

	_saving: boolean = false;

	dataService: DataService = inject(DataService);
	currentTenantService: CurrentTenantService = inject(CurrentTenantService);
	dialogRef: MatDialogRef<CreateDataComponent> = inject(MatDialogRef<CreateDataComponent>);
	applicationCategoryService: ApplicationCategoryService = inject(ApplicationCategoryService);


	ngOnInit(): void {
		this.currentTenantService.getCurrentTenantIdChanges().subscribe(tenantId => {
			this.tenantId = tenantId;
			this.initialize();
		});

		this.subscription.add(this.categorySearchFormControl.valueChanges.pipe(distinctUntilChanged())
			.subscribe(text => this.filterCategoryList(text)));
	}

	filterCategoryList(text?: string | null) {
		this.filteredCategoryList = this.categoryList
			.filter(value => !text || value.name.toLowerCase().includes(text.toLowerCase()))
			.sort((a, b) => a.name.localeCompare(b.name));
	}

	initialize(): void {
		this.subscription.add(this.applicationCategoryService.getAllApplicationCategoryByTenantId(this.tenantId)
			.pipe(
				tap(categories => {
					this.categoryList = categories;
					this.filteredCategoryList = this.categoryList;
				})
			).subscribe(() => this.initialized = true));
	}

	submit(): void {
		this.subscription.add(this.switchSaving()
			.pipe(
				filter(() => this.formGroup.valid),
				switchMap((form) => this.dataService.create(this.tenantId, this.buildForm())),
				finalize(() => this.switchSaving())
			).subscribe(result => {
				this.closeDialog(true);
			}));
	}

	createCategory(name: string): void {
		if (this.categoryList.some(category => category.name === name)) {
			this.formGroup.controls.category.setValue(this.categoryList.find(category => category.name === name));
			return;
		}
		const form: ApplicationCategoryForm = {
			name: name
		}
		this.applicationCategoryService.createApplicationCategoryForTenant(this.tenantId, form)
			.subscribe(categoryId => {
				const newData: Category = {
					categoryId: categoryId,
					name: name
				}
				this.categoryList.push(newData);
				this.filteredCategoryList = this.categoryList;
				this.formGroup.controls.category.setValue(newData);
			});
	}

	buildForm(): DataForm {
		return {
			name: this.nameFormControl.value,
			types: [],
			privacy: this.privacyFormControl.value,
			description: this.descriptionFormControl.value,
			category: this.categoryFormControl.value,
			criticality: this.criticalityFormControl.value
		}
	}

	switchSaving(): Observable<{}> {
		this._saving = !this._saving;
		return of({});
	}

	get nameFormControl(): FormControl {
		return this.formGroup.get('name') as FormControl;
	}

	get privacyFormControl(): FormControl {
		return this.formGroup.get('privacy') as FormControl;
	}

	get descriptionFormControl(): FormControl {
		return this.formGroup.get('description') as FormControl;
	}

	get categoryFormControl(): FormControl {
		return this.formGroup.get('category') as FormControl;
	}

	get criticalityFormControl(): FormControl {
		return this.formGroup.get('criticality') as FormControl;
	}

	closeDialog(event: boolean) {
		this.dialogRef.close(event);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
