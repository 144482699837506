<div *ngIf="initialized" class="flex-column gap-M pad-M">
	<div class="flex-align-center flex-space-between p-relative" style="height: 30px;">
		<div class="font-MM fw-700">{{ 'page.data.description'|translate }}</div>
		<app-text-button *ngIf="edit" (click)="changeEditState()" type="flat" color="accent" class="p-absolute" style="right: 0; height: 30px" panelClass="h-100-imp b-radius-9">Valider</app-text-button>
		<div (click)="changeEditState()" *ngIf="!edit" class="edit-button flex-center c-p">
			<img width="16" src="assets/icons/edit.svg" class="filter-primary">
		</div>
	</div>
	<app-text-area [forceFocus]="true" [rows]="undefined" [input]="descriptionControl" [placeholder]="'page.data.descriptionPlaceholder2' | translate"></app-text-area>
</div>
<div class="pad-M" *ngIf="!initialized">
	<list-content-loader></list-content-loader>
</div>
