import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import * as d3 from 'd3';
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {Router, RouterType} from "../../../../services/back/router.service";
import {
	INFRASTRUCTURE_ROUTERS_URL,
	INFRASTRUCTURE_SERVERS_URL,
} from "../../../../models/home/navigation.model";
import {Router as NgRouter} from "@angular/router";
import {NgIf} from "@angular/common";
import {CriticalityLevel} from "../../../../services/tenant.service";
import {DesignSystemModule} from "../../../design-system/design-system.module";
import {ServerDetailService} from "../../../../services/front/server-detail.service";
import {ServerUrlService} from "../../../../services/front/server-url.service";
import {InfrastructureSchema} from "../../../../services/back/infrastructure-schema.service";
import {SchemaServer} from "../../../../services/model/server.model";

interface Dimensions {
	svgWidth: number;
	svgHeight: number;
	gridSize: number;
	centerX: number;
	centerY: number;
	ratio: number;
}

@Component({
  selector: 'app-infrastructure-schema',
  standalone: true,
	imports: [
		TranslateModule,
		NgIf,
		DesignSystemModule
	],
  templateUrl: './infrastructure-schema.component.html',
  styleUrl: './infrastructure-schema.component.scss'
})
export class InfrastructureSchemaComponent implements OnChanges, OnInit {
	@Input() infrastructure: InfrastructureSchema|null = null;
	@Input() from: 'server' | 'network';

	private readonly SVG_WIDTH = 719;
	private readonly MAX_SERVERS_PER_ROW = 5;
	private dimensions: Dimensions;
	private svg: d3.Selection<SVGSVGElement, unknown, null, undefined>;
	private mainGroup: d3.Selection<SVGGElement, unknown, null, undefined>;

	@ViewChild('svgContainer', { static: true })
	private svgContainer!: ElementRef;

	constructor(private translate: TranslateService,
				private serverDetailService: ServerDetailService,
				private serverUrlService: ServerUrlService,
				private ngRouter: NgRouter) {}

	ngOnInit() {
		this.initialize();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.infrastructure && !changes.infrastructure.isFirstChange()) {
			this.initialize();
		}
	}

	initialize(): void {
		if (this.svg) {
			this.svg.remove();
		}
		if (this.infrastructure) {
			this.initializeDimensions();
			this.initializeSVG();
			this.drawNetwork();
		}
	}

	private initializeDimensions(): void {
		const gridCount = this.calculateGridCount();
		const gridSize = this.SVG_WIDTH / gridCount;
		const rows = Math.ceil(this.infrastructure!.servers.length / this.MAX_SERVERS_PER_ROW);
		const svgHeight = this.calculateSVGHeight(gridSize, rows);

		this.dimensions = {
			svgWidth: this.SVG_WIDTH,
			svgHeight,
			gridSize,
			centerX: this.SVG_WIDTH / 2,
			centerY: svgHeight / 2,
			ratio: gridSize / 47.933
		};
	}

	private calculateGridCount(): number {
		const baseCount = Math.max(15, this.infrastructure!.routers.length * 4);
		return baseCount % 2 === 0 ? baseCount + 1 : baseCount;
	}

	private calculateSVGHeight(gridSize: number, rows: number): number {
		const visibleServers = Math.min(this.infrastructure!.servers.length, 10); // Max 10 servers shown
		const visibleRows = Math.ceil(visibleServers / this.MAX_SERVERS_PER_ROW);

		return gridSize * 10 + visibleRows * 2 * gridSize + gridSize + 2 * gridSize;
	}

	private initializeSVG(): void {
		this.svg = d3
			.select(this.svgContainer.nativeElement)
			.append('svg')
			.attr('width', this.dimensions.svgWidth)
			.attr('height', this.dimensions.svgHeight)
			.style('border-radius', '10px')
			.style('background-color', '#F7F8FA')
			.style('border', '0.5px solid #DCDCE9');

		this.mainGroup = this.svg.append('g').attr('class', 'main-group');
	}

	private drawNetwork(): void {
		this.drawGridLines();
		this.drawInternetNode();
		this.drawNetworkConnections();
		this.drawNetworkArea();
		this.drawServerNodes();
	}

	private drawGridLines(): void {
		const { svgWidth, svgHeight, gridSize, ratio } = this.dimensions;

		// Draw horizontal grid lines
		this.mainGroup.selectAll('.horizontal-line')
			.data(d3.range(0, svgHeight, gridSize))
			.enter()
			.append('line')
			.attr('class', 'grid-line')
			.attr('x1', 0)
			.attr('y1', d => d)
			.attr('x2', svgWidth)
			.attr('y2', d => d)
			.attr('stroke', '#EEEFF5')
			.attr('stroke-width', ratio);

		// Draw vertical grid lines
		this.mainGroup.selectAll('.vertical-line')
			.data(d3.range(0, svgWidth, gridSize))
			.enter()
			.append('line')
			.attr('class', 'grid-line')
			.attr('x1', d => d)
			.attr('y1', 0)
			.attr('x2', d => d)
			.attr('y2', svgHeight)
			.attr('stroke', '#EEEFF5')
			.attr('stroke-width', ratio);

		// Draw a dashed horizontal line at the 5th row
		this.mainGroup.append('line')
			.attr('x1', gridSize)
			.attr('y1', gridSize * 4)
			.attr('x2', svgWidth - gridSize)
			.attr('y2', gridSize * 4)
			.attr('stroke', '#B5B5C3')
			.attr('stroke-width', ratio)
			.attr('stroke-dasharray', ratio * (1 / 4 * gridSize) + ' ' + ratio * (1 / 4 * gridSize));

		// Draw a point at each end of the dashed line
		this.mainGroup.append('circle')
			.attr('cx', gridSize)
			.attr('cy', gridSize * 4)
			.attr('r', 0.07 * gridSize)
			.attr('fill', '#B5B5C3');

		this.mainGroup.append('circle')
			.attr('cx', svgWidth - gridSize)
			.attr('cy', gridSize * 4)
			.attr('r', 0.07 * gridSize)
			.attr('fill', '#B5B5C3');

		// Draw text above the dashed line left
		this.mainGroup.append('text')
			.attr('x', gridSize)
			.attr('y', gridSize * 3.8)
			.text(this.translate.instant('page.infrastructure.overview.level0'))
			.style('font-size', `${13 * ratio}px`)
			.style('font-weight', 'bold')
			.style('font-family', 'proxima-nova')
			.style('fill', '#B5B5C3');

		// Draw a dashed horizontal line at the 8th row
		this.mainGroup.append('line')
			.attr('x1', gridSize)
			.attr('y1', gridSize * 8)
			.attr('x2', svgWidth - gridSize)
			.attr('y2', gridSize * 8)
			.attr('stroke', '#B5B5C3')
			.attr('stroke-width', ratio)
			.attr('stroke-dasharray', ratio * (1 / 4 * gridSize) + ' ' + ratio * (1 / 4 * gridSize));

		// Draw a point at each end of the dashed line
		this.mainGroup.append('circle')
			.attr('cx', gridSize)
			.attr('cy', gridSize * 8)
			.attr('r', 0.07 * gridSize)
			.attr('fill', '#B5B5C3');

		this.mainGroup.append('circle')
			.attr('cx', svgWidth - gridSize)
			.attr('cy', gridSize * 8)
			.attr('r', 0.07 * gridSize)
			.attr('fill', '#B5B5C3');

		// Draw text above the dashed line right
		this.mainGroup.append('text')
			.attr('x', gridSize)
			.attr('y', gridSize * 7.8)
			.text(this.translate.instant('page.infrastructure.overview.level1'))
			.style('font-size', `${13 * ratio}px`)
			.style('font-weight', 'bold')
			.style('font-family', 'proxima-nova')
			.style('fill', '#B5B5C3');

	}

	private drawInternetNode(): void {
		const { centerX, gridSize, ratio } = this.dimensions;
		const internetY = gridSize * 3 - 0.5 * gridSize;
		const internetRadius = 0.9 * gridSize;

		// Internet circle
		this.mainGroup.append('circle')
			.attr('class', 'internet-node')
			.attr('cx', centerX)
			.attr('cy', internetY)
			.attr('r', internetRadius)
			.attr('fill', '#EBE8F9')
			.attr('stroke', '#A890F3')
			.attr('stroke-width', ratio);

		// Internet icon
		this.mainGroup.append('image')
			.attr('x', centerX - 0.4 * gridSize)
			.attr('y', internetY - 0.6 * gridSize)
			.attr('width', 0.8 * gridSize)
			.attr('height', 0.8 * gridSize)
			.attr('xlink:href', 'assets/icons/cloud.svg');

		// Internet label
		this.mainGroup.append('text')
			.attr('x', centerX)
			.attr('y', internetY + internetRadius / 1.9)
			.attr('text-anchor', 'middle')
			.attr('class', 'internet-label')
			.text('INTERNET')
			.style('font-size', `${11 * ratio}px`)
			.style('font-family', 'proxima-nova')
			.style('font-weight', 'bold')
			.style('fill', '#A890F3');
	}

	private drawNetworkConnections(): void {
		const { centerX, gridSize, ratio } = this.dimensions;
		const routerGroup = this.mainGroup.append('g').attr('class', 'network-connections');

		const internetY = gridSize * 3 + (0.41 * gridSize);
		const routerY = gridSize * 6 - 0.5 * gridSize;
		const networkY = gridSize * 10;

		// Draw connections from internet to each router to network
		this.infrastructure!.routers.forEach((router, i) => {
			const routerX = centerX + (i - (this.infrastructure!.routers.length - 1) / 2) * 4 * gridSize;

			// Create points for the polyline: internet -> router -> network
			const points = [
				// Start from internet
				[centerX, internetY],
				// Go down to the router's level
				[centerX, routerY - 1.1 * gridSize],
				// Move horizontally to router's X position
				[routerX, routerY - 1.1 * gridSize],
				// Connect to router
				[routerX, routerY - 0.4 * gridSize],
				// Continue from router bottom
				[routerX, routerY + 0.4 * gridSize],
				// Go down to the network connection point
				[routerX, networkY - 2.5 * gridSize],
				// Move horizontally to center
				[centerX, networkY - 2.5 * gridSize],
				// Final connection to network
				[centerX, networkY]
			];

			// Create the polyline with rounded corners
			routerGroup.append('path')
				.attr('d', this.createRoundedPath(points, gridSize * 0.15))
				.attr('stroke', '#CFC4F7')
				.attr('stroke-width', 3 * ratio)
				.attr('fill', 'none')
				.attr('stroke-linejoin', 'round')
				.attr('stroke-linecap', 'round');

			this.drawRouterNode(routerGroup, router, routerX, routerY, gridSize, ratio);
		});
	}

	private createRoundedPath(points: number[][], radius: number): string {
		if (points.length < 2) return '';

		const path: string[] = [];
		path.push(`M ${points[0][0]},${points[0][1]}`);

		for (let i = 1; i < points.length - 1; i++) {
			const curr = points[i];
			const next = points[i + 1];
			const prev = points[i - 1];

			// Calculate direction vectors
			const v1 = {
				x: curr[0] - prev[0],
				y: curr[1] - prev[1]
			};
			const v2 = {
				x: next[0] - curr[0],
				y: next[1] - curr[1]
			};

			// Calculate vector lengths
			const len1 = Math.sqrt(v1.x * v1.x + v1.y * v1.y);
			const len2 = Math.sqrt(v2.x * v2.x + v2.y * v2.y);

			// Normalize vectors and scale by radius
			const radius1 = Math.min(radius, len1 / 2);
			const radius2 = Math.min(radius, len2 / 2);

			if (len1 === 0 || len2 === 0) {
				path.push(`L ${curr[0]},${curr[1]}`);
				continue;
			}

			// Calculate points for the curve
			const p1 = {
				x: curr[0] - v1.x * radius1 / len1,
				y: curr[1] - v1.y * radius1 / len1
			};
			const p2 = {
				x: curr[0] + v2.x * radius2 / len2,
				y: curr[1] + v2.y * radius2 / len2
			};

			path.push(`L ${p1.x},${p1.y}`);
			path.push(`Q ${curr[0]},${curr[1]} ${p2.x},${p2.y}`);
		}

		// Add the final point
		path.push(`L ${points[points.length - 1][0]},${points[points.length - 1][1]}`);

		return path.join(' ');
	}

	private drawRouterNode(
		group: d3.Selection<SVGGElement, unknown, null, undefined>,
		router: Router,
		routerX: number,
		routerY: number,
		gridSize: number,
		ratio: number
	): void {
		const onClick = () => {
			this.ngRouter.navigate([INFRASTRUCTURE_ROUTERS_URL], { queryParams: { routerId: router.id } });
		}

		// Draw router node
		group.append('circle')
			.attr('class', 'router-node')
			.attr('r', 0.4 * gridSize)
			.attr('cx', routerX)
			.attr('cy', routerY)
			.attr('fill', '#FFFFFF')
			.attr('stroke', '#A890F3')
			.attr('stroke-width', ratio)
			.style('cursor', 'pointer')
			.on('click', onClick);

		// Draw inner colored circle
		group.append('circle')
			.attr('class', 'router-node-inner')
			.attr('r', 0.33 * gridSize)
			.attr('cx', routerX)
			.attr('cy', routerY)
			.attr('fill', 'rgba(61, 192, 255, 0.10)')
			.style('cursor', 'pointer')
			.on('click', onClick);

		// Draw router icon
		group.append('image')
			.attr('x', router.type === RouterType.ROUTER ? routerX - 0.25 * gridSize : routerX - 0.175 * gridSize)
			.attr('y', router.type === RouterType.ROUTER ? routerY - 0.25 * gridSize : routerY - 0.175 * gridSize)
			.attr('width', router.type === RouterType.ROUTER ? 0.5 * gridSize : 0.35 * gridSize)
			.attr('height', router.type === RouterType.ROUTER ? 0.5 * gridSize : 0.35 * gridSize)
			.attr('xlink:href', router.type === RouterType.ROUTER ? 'assets/icons/router.svg' : 'assets/icons/firewall.svg')
			.attr('class', 'filter-accent')
			.style('cursor', 'pointer')
			.on('click', onClick);

		// Draw router info block
		group.append('rect')
			.attr('x', routerX - gridSize)
			.attr('y', gridSize * 6.15)
			.attr('width', 2 * gridSize)
			.attr('height', 0.7 * gridSize)
			.attr('rx', 5 * ratio)
			.attr('ry', 5 * ratio)
			.attr('fill', '#FFFFFF')
			.attr('stroke', '#DCDCE9')
			.attr('stroke-width', ratio)
			.style('cursor', 'pointer')
			.on('click', onClick);

		// Draw router name
		group.append('text')
			.attr('x', routerX)
			.attr('y', gridSize * 6.45)
			.attr('text-anchor', 'middle')
			.attr('class', 'router-name')
			.text(this.getEllipsisText(router.name, 80 * ratio, 10 * ratio))
			.style('font-size', `${10 * ratio}px`)
			.style('font-weight', 'bold')
			.style('font-family', 'proxima-nova')
			.style('fill', '#0C2633')
			.style('cursor', 'pointer')
			.on('click', onClick);

		// Draw router IP address
		group.append('text')
			.attr('x', routerX)
			.attr('y', gridSize * 6.72)
			.attr('text-anchor', 'middle')
			.attr('class', 'router-ip-address')
			.text(router.ipAddress || '-')
			.style('font-size', `${9 * ratio}px`)
			.style('font-family', 'proxima-nova')
			.style('fill', '#0C2633')
			.style('cursor', 'pointer')
			.on('click', onClick);
	}

	private drawNetworkArea(): void {
		const { centerX, gridSize, ratio } = this.dimensions;

		// Calculate width based on maximum servers per row
		const networkWidth = Math.max(
			Math.min(this.MAX_SERVERS_PER_ROW, this.infrastructure!.servers.length <= 3 ? 3 : this.infrastructure!.servers.length),
			this.infrastructure!.servers.length % this.MAX_SERVERS_PER_ROW || this.MAX_SERVERS_PER_ROW
		) * 2 * gridSize + gridSize;

		// Calculate height based on visible servers
		const visibleServers = Math.min(this.infrastructure!.servers.length, 10);
		const visibleRows = Math.ceil(visibleServers / this.MAX_SERVERS_PER_ROW);
		const networkHeight = visibleRows * 2 * gridSize + gridSize;

		const networkX = centerX - networkWidth / 2;
		const networkY = gridSize * 10;

		// Draw network container
		this.mainGroup.append('rect')
			.attr('class', 'network-area')
			.attr('x', networkX)
			.attr('y', networkY)
			.attr('width', networkWidth)
			.attr('height', networkHeight)
			.attr('rx', 16 * ratio)
			.attr('ry', 16 * ratio)
			.attr('fill', 'rgba(168, 144, 243, 0.10)')
			.attr('stroke', '#A890F3')
			.attr('stroke-width', ratio);

		// Draw a circle at the top of the network area
		this.mainGroup.append('circle')
			.attr('cx', centerX)
			.attr('cy', networkY)
			.attr('r', 0.13 * gridSize)
			.attr('fill', '#FFFFFF')
			.attr('stroke', '#A890F3')
			.attr('stroke-width', ratio);

		// Draw network icon
		this.mainGroup.append('image')
			.attr('x', networkX + 0.3 * gridSize)
			.attr('y', networkY + 0.28 * gridSize)
			.attr('width', 0.28 * gridSize)
			.attr('height', 0.28 * gridSize)
			.attr('xlink:href', 'assets/icons/network.svg')
			.attr('class', 'filter-accent-secondary');

		// Draw network label
		this.mainGroup.append('text')
			.attr('x', networkX + 0.65 * gridSize)
			.attr('y', networkY + 0.51 * gridSize)
			.attr('class', 'network-label')
			.text(this.getEllipsisText(this.infrastructure!.network.name, networkWidth - 0.5 * gridSize, 12 * ratio))
			.style('font-size', `${12 * ratio}px`)
			.style('font-family', 'proxima-nova')
			.style('fill', '#A890F3')
			.style('font-weight', 'bold');
	}

	private drawServerNodes(): void {
		const { centerX, gridSize, ratio } = this.dimensions;
		const networkX = centerX - (Math.min(this.MAX_SERVERS_PER_ROW, this.infrastructure!.servers.length) * 2 * gridSize + gridSize) / 2;
		const networkY = gridSize * 10;

		const onClick = (server: SchemaServer) => {
			if (this.from === 'server') {
				this.serverDetailService.clearServerDetailData();
				this.serverUrlService.changeCurrentServer(server.server.id);
				this.serverDetailService.changeServer(server.server.id);
			} else {
				this.ngRouter.navigate([INFRASTRUCTURE_SERVERS_URL], { queryParams: { serverId: server.server.id } });
			}
		}

		// Determine if we need to show the "+X" box
		const maxDisplayServers = 10; // Show all servers up to 10
		const totalServers = this.infrastructure!.servers.length;
		const shouldShowPlusBox = totalServers > maxDisplayServers;
		const displayServers = shouldShowPlusBox
			? this.infrastructure!.servers.slice(0, maxDisplayServers - 1) // Show 9 servers + "+X" box
			: this.infrastructure!.servers; // Show all servers (up to 10)
		const remainingServers = shouldShowPlusBox ? totalServers - (maxDisplayServers - 1) : 0;

		// Create a group for each server node
		const serverGroups = this.mainGroup.selectAll('.server-group')
			.data(displayServers)
			.enter()
			.append('g')
			.attr('class', 'server-group')
			.attr('transform', (_, i) => {
				const x = networkX + gridSize + (i % this.MAX_SERVERS_PER_ROW) * 2 * gridSize;
				const y = networkY + gridSize + Math.floor(i / this.MAX_SERVERS_PER_ROW) * 2 * gridSize;
				return `translate(${x}, ${y})`;
			})
			.style('cursor', 'pointer')
			.on('click', (_, server) => onClick(server))
			.on('mouseover', function () {
				d3.select(this).select('.server-node')
					.transition()
					.duration(200)
					.style('stroke', '#A890F3')
			})
			.on('mouseout', function (event, server: SchemaServer) {
				d3.select(this).select('.server-node')
					.transition()
					.duration(200)
					.style('stroke', server.current ? '#A890F3' : '#DADADA')
			});

		// Add rectangle background
		serverGroups.append('rect')
			.attr('class', 'server-node')
			.attr('width', gridSize)
			.attr('height', gridSize)
			.attr('rx', 11 * ratio)
			.attr('ry', 11 * ratio)
			.attr('fill', '#FFFFFF')
			.style('stroke', (server) => server.current ? '#A890F3' : '#DADADA')
			.attr('stroke-width', ratio);

		// Add inner colored rectangle
		serverGroups.append('rect')
			.attr('class', 'server-node-inner')
			.attr('x', 0.2 * gridSize)
			.attr('y', 0.2 * gridSize)
			.attr('width', 0.6 * gridSize)
			.attr('height', 0.6 * gridSize)
			.attr('rx', 8 * ratio)
			.attr('ry', 8 * ratio)
			.attr('fill', 'rgba(61, 192, 255, 0.10)');

		// Add server icon
		serverGroups.append('image')
			.attr('class', 'server-icon')
			.attr('x', 0.35 * gridSize)
			.attr('y', 0.35 * gridSize)
			.attr('width', 0.3 * gridSize)
			.attr('height', 0.3 * gridSize)
			.attr('class', 'filter-accent')
			.attr('xlink:href', (server) => server.server.type ? 'assets/servers/' + server.server.type + '.svg' : 'assets/servers/other.svg');

		// Add server name below rectangle
		serverGroups.append('text')
			.attr('class', 'server-name')
			.attr('x', gridSize / 2)
			.attr('y', gridSize + 0.3 * gridSize)
			.attr('text-anchor', 'middle')
			.text(d => this.getEllipsisText(d.server.name, 80 * ratio, 10 * ratio))
			.style('font-size', `${10 * ratio}px`)
			.style('font-weight', 'bold')
			.style('font-family', 'proxima-nova')
			.style('fill', '#0C2633');

		// Add server type below server name
		serverGroups.append('text')
			.attr('class', 'server-system')
			.attr('x', gridSize / 2)
			.attr('y', gridSize + 0.6 * gridSize)
			.attr('text-anchor', 'middle')
			.text((server) => this.getEllipsisText(server.server.type ? this.translate.instant('page.infrastructure.overview.types.' + server.server.type.toLowerCase()) : '-', 80 * ratio, 9 * ratio))
			.style('font-size', `${9 * ratio}px`)
			.style('font-family', 'proxima-nova')
			.style('fill', '#0C2633');

		// Add a circle for criticality level
		serverGroups.append('circle')
			.attr('class', 'server-criticality')
			.attr('cx', 0.9 * gridSize)
			.attr('cy', 0.9 * gridSize)
			.attr('r', 0.17 * gridSize)
			.attr('fill', '#FFFFFF')
			.attr('stroke', server => server.criticality === CriticalityLevel.HIGH ? '#FF5555' : server.criticality === CriticalityLevel.MEDIUM ? '#FF9900' : server.criticality === CriticalityLevel.LOW ? '#3DC0FF' : '#DADADA')
			.attr('stroke-width', ratio);

		// Add an icon for criticality level
		serverGroups.append('image')
			.attr('class', 'server-criticality-icon')
			.attr('x', 0.8 * gridSize)
			.attr('y', 0.8 * gridSize)
			.attr('width', 0.2 * gridSize)
			.attr('height', 0.2 * gridSize)
			.attr('xlink:href', server => server.criticality === CriticalityLevel.HIGH ? 'assets/icons/criticality-high.svg' : server.criticality === CriticalityLevel.MEDIUM ? 'assets/icons/criticality-medium.svg' : server.criticality === CriticalityLevel.LOW ? 'assets/icons/criticality-low.svg' : 'assets/icons/criticality-high-disabled.svg');

		// Add the "+X" box if there are remaining servers (more than 10 total)
		if (shouldShowPlusBox) {
			const lastX = networkX + gridSize + ((maxDisplayServers - 1) % this.MAX_SERVERS_PER_ROW) * 2 * gridSize;
			const lastY = networkY + gridSize + Math.floor((maxDisplayServers - 1) / this.MAX_SERVERS_PER_ROW) * 2 * gridSize;

			const plusGroup = this.mainGroup.append('g')
				.attr('class', 'plus-group')
				.attr('transform', `translate(${lastX}, ${lastY})`)
				.style('cursor', 'default');

			// Background rectangle
			plusGroup.append('rect')
				.attr('width', gridSize)
				.attr('height', gridSize)
				.attr('rx', 11 * ratio)
				.attr('ry', 11 * ratio)
				.attr('fill', 'rgba(164, 145, 236, 0.1)')
				.style('stroke', '#A890F3')
				.attr('stroke-width', ratio);

			// "+X" text
			plusGroup.append('text')
				.attr('x', gridSize / 2)
				.attr('y', gridSize / 2 + 1.8 * ratio)
				.attr('text-anchor', 'middle')
				.attr('dominant-baseline', 'middle')
				.text(`+${remainingServers}`)
				.style('font-size', `${13 * ratio}px`)
				.style('font-weight', 'bold')
				.style('font-family', 'proxima-nova')
				.style('fill', '#A890F3');
		}
	}


	private getEllipsisText(text: string, maxWidth: number, fontSize: number): string {
		const tempText = this.svg.append('text') // Temporary element to measure text width
			.style('font-size', fontSize) // Match the font size and style
			.style('font-family', 'proxima-nova')
			.text(text);

		let truncatedText = text;

		while (tempText.node()!.getBBox().width > maxWidth && truncatedText.length > 0) {
			truncatedText = truncatedText.slice(0, -1); // Remove one character at a time
			tempText.text(truncatedText + '...'); // Append ellipsis
		}

		tempText.remove(); // Clean up temporary element

		return truncatedText === text ? text : truncatedText + '...';
	}
}
