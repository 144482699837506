<div class="flex-align-center flex-space-between pad-M" style="border-radius: 10px;">
	<div class="font-MM fw-700 primary">{{'menu.usage'|translate}}</div>
	<app-toggle-button [toggleInput]="usageCheckControl"></app-toggle-button>
</div>
<div class="pad-M" style="padding-top: 0;">
	<form *ngIf="!_initializing" class="flex-column gap-S" [formGroup]="usageForm">
		<div class="flex-column gap-XS">
			<span>{{'page.appDetails.settings.usageFirstLine'|translate}}</span>
			<span>{{'page.appDetails.settings.usageSecondLine'|translate}}</span>
			<span>{{'page.appDetails.settings.usageThirdLine'|translate}}</span>
		</div>
		<div class="flex-align-center gap-S mb-xs">
			<img src="assets/icons/attention.svg" height="14" width="14" alt=""/>
			<div class="orange-text">
				<span>{{'page.appDetails.settings.attention1'|translate}}</span>
				<a (click)="goToSettings()" class="link" style="text-decoration: underline">{{'page.appDetails.settings.attention2'|translate}}</a>
				<span>{{'page.appDetails.settings.attention3'|translate}}</span>
			</div>
		</div>
		<div class="hr-document"></div>
		<span class="font-MM fw-700 primary mt-xs mb-s">{{'page.appDetails.settings.webUsage'|translate}}</span>
		<div class="flex w-100 gap-M mb-xs">
			<div class="flex-column w-20 gap-XS">
				<div class="grey fw-500">{{'page.appDetails.settings.usageDomain'|translate}}</div>
				<app-text-input [placeholder]="'page.appDetails.domainPlaceholder'|translate"
								[input]="usageDomainControl"
								(blur)="updateUsageOnBlur.emit(formName.usageDomain)"
								(keyup.enter)="updateUsageOnBlur.emit(formName.usageDomain)">
				</app-text-input>
			</div>
			<div class="flex-column w-80 gap-XS">
				<div class="grey fw-500">{{'page.appDetails.settings.usagePath'|translate}}</div>
				<div class="flex-center">
					<div class="flex-center h-100 slash">/</div>
					<app-text-input class="w-100" [placeholder]="'page.appDetails.pathPlaceholder'|translate"
									[input]="usagePathControl"
									(blur)="updateUsageOnBlur.emit(formName.usagePath)"
									[leftBorder]="false"
									(keyup.enter)="updateUsageOnBlur.emit(formName.usagePath)">
					</app-text-input>
				</div>
			</div>
		</div>
		<div class="hr-document"></div>
		<div class="flex-align-center flex-space-between mt-xs mb-s">
			<span class="font-MM fw-700 primary ">{{'page.appDetails.settings.agentUsage'|translate}}</span>
			<span class="accent c-p underlined-hover" (click)="addNewDesktopApplicationName()">{{'page.appDetails.settings.addMoreName'|translate}}</span>
		</div>
		<div class="flex-column gap-M">
			<div *ngFor="let desktopApplicationName of desktopApplicationNamesControl;">
				<div class="grey fw-500">{{'page.appDetails.settings.nameToTarget'|translate}}</div>
				<div class="flex-align-center gap-XS">
					<app-text-input class="w-100"
									[placeholder]="'Kabeen'"
									[input]="desktopApplicationName"
									(blur)="updateUsageOnBlur.emit(formName.desktopApplicationNames)"
									(keyup.enter)="updateUsageOnBlur.emit(formName.desktopApplicationNames)">
					</app-text-input>
					<app-icon-button color="danger" size="s" [img]="'assets/icons/trash.svg'" [tooltip]="'button.delete' | translate"
									 (click)="removeDesktopApplicationName(desktopApplicationName)">
					</app-icon-button>
				</div>
			</div>
			<div *ngIf="desktopApplicationNamesControl.length <= 0" class="w-100 flex-center flex-column gap-M" style="height: 280px;">
				<img src="assets/illustrations/empty-app.svg" width="150">
				<span class="grey">{{ 'page.appDetails.settings.noNativeName' | translate }}</span>
			</div>
		</div>
	</form>
</div>
